import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../firebase';
import { useParams } from 'react-router-dom';

const AssessmentContext = createContext();

export const AssessmentProvider = ({ children }) => {
  const [assessment, setAssessment] = useState(null);
  const [scenario, setScenario] = useState(null);
  const { assessmentId } = useParams(); // Haal assessmentId direct uit de URL params

  useEffect(() => {
    const fetchAssessmentData = async () => {
      if (assessmentId) {
        try {
          const assessmentDocumentRef = doc(firestore, 'assessments', assessmentId); // Gebruik assessmentId direct
          const assessmentSnapshot = await getDoc(assessmentDocumentRef);
          if (assessmentSnapshot.exists()) {
            const assessmentData = {
              id: assessmentId,
              ...assessmentSnapshot.data(),
            };
            console.log('Fetched assessment data:', assessmentData); // Log de opgehaalde assessment-data
            setAssessment(assessmentData);
          } else {
            console.error('No assessment found with id:', assessmentId);
            setAssessment(null);
          }
        } catch (error) {
          setAssessment(null);
          console.error('Error fetching assessment data:', error);
        }
      } else {
        console.error('No assessmentId provided in URL');
      }
    };

    fetchAssessmentData();
  }, [assessmentId]);

  useEffect(() => {
    const fetchScenarioData = async () => {

      if (assessment) {
        try {
          const scenarioDocumentRef = doc(firestore, 'scenarios', assessment.scenarioId); // Gebruik scenarioId direct
          const scenarioSnapshot = await getDoc(scenarioDocumentRef);
          if (scenarioSnapshot.exists()) {
            const scenarioData = {
              id: assessment.scenarioId,
              ...scenarioSnapshot.data(),
            };
            console.log('Fetched scenario data:', scenarioData); // Log de opgehaalde scenario-data
            setScenario(scenarioData);
          } else {
            console.error('No scenario found with id:', assessment.scenarioId);
            setScenario(null);
          }
        } catch (error) {
          setScenario(null);
          console.error('Error fetching scenario data:', error);
        }
      }
    }

    fetchScenarioData();
  }, [assessment])

  return (
    <AssessmentContext.Provider value={{ assessment, scenario }}>
      {children}
    </AssessmentContext.Provider>
  );
};

export const useAssessment = () => {
  return useContext(AssessmentContext);
};
