import React, { createContext, useState, useEffect, useContext } from 'react';
import { collection, query, getDocs, where, addDoc, Timestamp } from 'firebase/firestore';
import { firestore } from '../firebase';
import { useUser } from './UserContext';

// Create a context
const DashboardContext = createContext();

const getTotal = async (collectionName) => {
    const q = query(collection(firestore, collectionName));
    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
};

// Create a provider component
export const DataProvider = ({ children }) => {
    const user = useUser();
    const [courses, setCourses] = useState([]);
    const [snapshotExists, setSnapshotExists] = useState(false);
    const [rolesCount, setRolesCount] = useState({
        admin: 0,
        student: 0,
        teacher: 0,
        individual: 0,
        totalSchools: 0,
        totalGrades: 0,
        totalAssessments: 0,
        totalModules: 0,
        totalPendingUsers: 0,
        totalScenarios: 0
    });

    // Fetch courses based on the user role
    useEffect(() => {
        const fetchCourses = async () => {
            let coursesQuery;
            if (user.roleName === 'Admin') {
                coursesQuery = query(collection(firestore, 'classRooms'));
            } else if (user.roleName === 'Teacher') {
                coursesQuery = query(collection(firestore, 'classRooms'), where('attendees', 'array-contains', user.id));
            } else if (user.roleName === 'Student') {
                const attendingClasses = user.attendingClasses || [];
                coursesQuery = query(collection(firestore, 'classRooms'), where('__name__', 'in', attendingClasses));
            }
            const querySnapshot = await getDocs(coursesQuery);
            const coursesData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCourses(coursesData);
        };

        if (user) {
            fetchCourses().catch(console.error);
        }
    }, [user]);

    // Fetch and create snapshot if needed
    useEffect(() => {
        const checkAndCreateSnapshot = async () => {
            const today = new Date();
            const todayStr = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

            const snapshotQuery = query(collection(firestore, 'analytics'), where('date', '==', todayStr));
            const snapshotDocs = await getDocs(snapshotQuery);

            if (snapshotDocs.empty) {
                const newSnapshot = {
                    date: todayStr,
                    totalUsers: await getTotal('users'),
                    totalPendingRegistrations: await getTotal('pendingRegistrations'),
                    totalGrades: await getTotal('grades'),
                    totalSchools: await getTotal('schools'),
                    totalCourses: await getTotal('classRooms'),
                    totalAssessments: await getTotal('assessments'),
                    totalModules: await getTotal('modules'),
                    totalScenarios: await getTotal('scenarios'),
                    createdAt: Timestamp.now(),
                };
                await addDoc(collection(firestore, 'analytics'), newSnapshot);
                setSnapshotExists(true);
            } else {
                setSnapshotExists(true);
            }
        };

        const getTotal = async (collectionName) => {
            const q = query(collection(firestore, collectionName));
            const querySnapshot = await getDocs(q);
            return querySnapshot.size;
        };

        checkAndCreateSnapshot().catch(console.error);
    }, []);

    // Fetch user roles and other data totals
    useEffect(() => {
        const fetchRolesAndTotals = async () => {
            const usersQuery = query(collection(firestore, 'users'));
            const querySnapshot = await getDocs(usersQuery);
            const roles = {
                admin: 0,
                student: 0,
                teacher: 0,
                individual: 0,
                totalSchools: await getTotal('schools'),
                totalGrades: await getTotal('grades'),
                totalAssessments: await getTotal('assessments'),
                totalModules: await getTotal('modules'),
                totalPendingUsers: await getTotal('pendingRegistrations'),
                totalScenarios: await getTotal('scenarios'),
            };
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.roleName && roles.hasOwnProperty(data.roleName.toLowerCase())) {
                    roles[data.roleName.toLowerCase()]++;
                }
            });
            setRolesCount(roles);
        };

        fetchRolesAndTotals().catch(console.error);
    }, []);

    return (
        <DashboardContext.Provider value={{ courses, rolesCount, snapshotExists, getTotal }}>
            {children}
        </DashboardContext.Provider>
    );
};

// Create a custom hook to use the DataContext
export const useData = () => useContext(DashboardContext);
