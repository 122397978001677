import React from 'react';
import LibraryOverview from '../../overviews/LibraryOverview';

const VRLibrary = () => {
    return(
        <div>
         {/* <div className="bg-white p-6 rounded-lg shadow mb-6">
                <div className="grid grid-cols-2 gap-4 mb-6">
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Admin</h3>
                        <p className="text-lg font-semibold text-gray-900">
                            Test
                        </p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Students</h3>
                        <p className="text-lg font-semibold text-gray-900">100</p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Date Created</h3>
                        <p className="text-lg font-semibold text-gray-900">Test</p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Assignments</h3>
                        <p className="text-lg font-semibold text-gray-900">04</p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Grades</h3>
                        <p className="text-lg font-semibold text-gray-900">75</p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Status</h3>
                        <p className="text-lg font-semibold text-gray-900">Test</p>
                    </div>
                </div>
            </div> */}
        <LibraryOverview/>
        </div>
    );

};

export default VRLibrary;
