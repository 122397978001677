import React, { useState } from 'react';
import { useAppData } from '../../contexts/AppDataContext';
import { usePopUp } from '../../contexts/PopUpContext';
import { useNotification } from '../../contexts/NotificationContext';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Zorg ervoor dat de Firestore-instantie correct wordt geïmporteerd

// Functie om gebruikers aan een cursus toe te voegen
const addUserToCourse = async (userId, courseId) => {
  try {
    const userRef = doc(firestore, 'users', userId); // Verwijs naar het juiste gebruikersdocument
    await updateDoc(userRef, {
      attendingClasses: arrayUnion(courseId) // Voeg de cursus toe aan de attendingClasses array van de gebruiker
    });
    //console.log(`User ${userId} successfully added to course ${courseId}`);
  } catch (error) {
    console.error("Error updating user document: ", error);
  }
};

const addUsersToCourseAttendees = async (selectedUsers, courseId) => {
    try {
      //console.log("Updating course with ID: ", courseId); // Log om het courseId te controleren
      
      const courseRef = doc(firestore, 'classRooms', courseId); // Controleer of dit de juiste collectie is
      await updateDoc(courseRef, {
        attendees: arrayUnion(...selectedUsers)
      });
      //console.log(`Users successfully added to course ${courseId}`);
    } catch (error) {
      console.error("Error updating course document: ", error);
    }
  };
  

export default function CourseSelectionPopup({ selectedUsers, onClose }) {
  const { courses, school } = useAppData(); // Haal de cursussen en schoolgegevens uit AppDataContext
  const { showNotification } = useNotification();
  const { showPopUp } = usePopUp();
  const [selectedCourse, setSelectedCourse] = useState('');

  // Filter de cursus met het ID '66Fqzp90Cib8lmwCN3QZ' eruit
  const filteredCourses = courses?.filter(course => course.id !== '66Fqzp90Cib8lmwCN3QZ');

  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
  };

  const handleAddUsersToCourse = async () => {
    if (!selectedCourse) {
      showNotification('warning', 'Please select a course.');
      return;
    }

    // Voeg alle geselecteerde gebruikers toe aan de cursus en update zowel de gebruiker als de cursus
    try {
      for (const userId of selectedUsers) {
        await addUserToCourse(userId, selectedCourse); // Voeg de cursus toe aan de attendingClasses array van de gebruiker
      }
      
      // Update de cursus zelf en voeg de gebruikers toe aan de attendees array
      await addUsersToCourseAttendees(selectedUsers, selectedCourse);

      showNotification('success', 'Users added to the course successfully.');
    } catch (error) {
      showNotification('danger', 'An error occurred while adding users to the course.');
      console.error(error);
    }

    showPopUp(null); // Sluit de popup
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative p-4 w-full max-w-2xl h-auto bg-white rounded-lg shadow dark:bg-gray-800">
        <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Add Users to Course
          </h3>
          <button onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto dark:hover:bg-gray-600 dark:hover:text-white">
            <span className="sr-only">Close modal</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
        <form onSubmit={(e) => e.preventDefault()} className="overflow-y-auto max-h-96">
          <div className="mb-4">
            <label htmlFor="course" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Select Course
            </label>
            <select
              id="course"
              className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:text-white dark:border-gray-600"
              value={selectedCourse}
              onChange={handleCourseChange}
            >
              <option value="">Select a course</option>
              {filteredCourses.map((course) => (
                <option key={course.id} value={course.id}>
                  {course.className}
                </option>
              ))}
            </select>
          </div>

          <button
            onClick={handleAddUsersToCourse}
            className="mt-4 p-2 text-white bg-blue-500 rounded-md hover:bg-blue-700"
          >
            Add Users
          </button>
        </form>
      </div>
    </div>
  );
}
