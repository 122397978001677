import React, { createContext, useState, useContext, useEffect } from 'react';

const SettingsContext = createContext();

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    const toggleDarkMode = () => setIsDarkMode(!isDarkMode);

    useEffect(() => {
        if (localStorage.getItem('color-theme') === 'dark') {
            setIsDarkMode(true);
        }
    }, [])

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
        localStorage.setItem('color-theme', isDarkMode ? 'dark' : 'light');
    }, [isDarkMode]);

    return (
        <SettingsContext.Provider value={{ isDarkMode, toggleDarkMode }}>
            {children}
        </SettingsContext.Provider>
    );
};
