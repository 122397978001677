import { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "../../../contexts/UserContext";
import { useAppData } from "../../../contexts/AppDataContext";
import { useLocation, useParams } from "react-router";
import { addDocument, updateDocument, uploadFile, existsDocument } from "../../../services/FirebaseService";
import { assignmentCollection, coursesCollection, scenariosCollection } from "../../../firebase";
import { arrayUnion } from "@firebase/firestore";
import { useCourse } from "../../courses/contexts/CourseContext";
import { useNotification } from "../../../contexts/NotificationContext";
import { usePopUp } from "../../../contexts/PopUpContext";

const ScenarioContext = createContext();

export function useScenario() {
    return useContext(ScenarioContext);
}

export function ScenarioProvider({ children }) {
    const [scenario, setScenario] = useState(null);
    const [files, setFiles] = useState({});
    const [fromCourseta, setFromCourseta] = useState(false); // Nieuwe state voor de checkbox 'fromCourseta'

    const { scenarios, courses, setCourses, templates, setScenarios } = useAppData();
    const { scenarioId } = useParams();
    const location = useLocation();
    const { classroomId, assignmentName } = location.state || {};
    const { showNotification } = useNotification();
    const { showPopUp } = usePopUp();
    const user = useUser();

    useEffect(() => {
        if (scenarios && scenarioId) {
            const scenario = scenarios.find(s => s.id === scenarioId);
            if (scenario) {
                let s = { ...scenario };
                if (assignmentName) {
                    s.assignmentName = assignmentName;
                    s.classroomId = classroomId;
                    s.scenarioID = scenarioId;
                    s.dueDate = new Date();
                }
                setScenario(s);
                setFromCourseta(s.fromCourseta || false); // Stel de checkbox 'fromCourseta' in op basis van het scenario
            } else if (templates) {
                const scenario = templates.find(s => s.id === scenarioId);
                if (scenario) {
                    let s = { ...scenario };
                    s.assignmentName = assignmentName;
                    s.classroomId = classroomId;
                    s.scenarioID = scenarioId;
                    setScenario(s);
                    setFromCourseta(s.fromCourseta || false); // Stel de checkbox 'fromCourseta' in
                }
            }
        }
    }, [scenarios, scenarioId]);

    const addField = (field, path) => {
        let s = { ...scenario };
        const prev = s[path];
        s[path] = [...prev, field];
        setScenario(s);
    };

    const removeField = (field, path) => {
        // Bestaande logica (niet aangepast)
    };

    const setField = (field, path) => {
        let s = { ...scenario };
        s[path] = field;
        setScenario(s);
    };

    const setFile = (file, path) => {
        let f = { ...files };
    
        if (!file || !file.name) {
            console.error("File or file name is undefined in setFile, received:", file);
            return; // Stop als het bestand of de bestandsnaam niet beschikbaar is
        }
    
        f[path] = file; // Werk het bestand bij in de files map
    
        // Bouw het bestandspad op
        let filePath = `${scenario.assignmentName ? "teacherAssignments" : "scenarios"}/${scenario?.id ?? "undefined_scenario"}/${file.name}`;
    
        let s = { ...scenario };
        s[path] = filePath; // Update het scenario met het bestandspad
        setScenario(s); // Zet het bijgewerkte scenario
        console.log(`File path set in context for ${path}: ${filePath}`);
    };
    

    const getFile = (path) => {
        return files[path];
    };

    const save = async () => {
        try {
            if (scenario) {
                let id = 0;
                scenario.fromCourseta = fromCourseta; // Sla de waarde van de checkbox 'fromCourseta' op in het scenario object
    
                if (scenario.assignmentName) {
                    id = await addDocument(scenario, assignmentCollection);
                } else {
                    delete scenario.assignmentName;
                    delete scenario.dueDate;
                    delete scenario.classroomId;
                    delete scenario.scenarioID;
                    id = scenario.id;
                    await updateDocument(id, scenariosCollection, scenario);
                    setScenarios(scenarios.map((s) => (s.id === id ? scenario : s)));
                }
    
                if (classroomId) {
                    await updateDocument(classroomId, coursesCollection, {
                        assessments: arrayUnion(id),
                    });
                    const newCourses = courses.map((c) =>
                        c.id !== classroomId
                            ? c
                            : { ...c, assessments: [...(c.assessments ?? []), id] }
                    );
                    setCourses(newCourses);
                }
                showNotification("success", "Scenario saved!");
    
                for (const key of Object.keys(files)) {
                    const file = files[key];
                    const path = `scenarios/${scenario.id}/${file.name}`;
    
                    if (!file.name) {
                        console.error(`File name is undefined for key ${key}`);
                        continue; // Skip the file if name is undefined
                    }
    
                    console.log(`Uploading file ${file.name} to path: ${path}`);
                    await uploadFile(file, path); // Upload het bestand naar Firebase Storage
                }
            }
        } catch (e) {
            showNotification("danger", "Scenario Failed to Save!");
            console.error("Error saving scenario", e);
        }
    };
    

    const addPatient = (patient) => {
        let s = { ...scenario };
        if (!s.patients) {
            s.patients = [];
        }
        s.patients.push(patient);
        setScenario(s);
    };

    const updatePatientField = (index, field, value) => {
        let s = { ...scenario };
        if (s.patients && s.patients[index]) {
            s.patients[index] = { ...s.patients[index], [field]: value };
        } else {
            console.error("Patient index out of bounds");
        }
        setScenario(s);
    };

    return (
        <ScenarioContext.Provider
            value={{
                scenario,
                setScenario,
                addField,
                setField,
                removeField,
                setFile,
                getFile,
                save,
                fromCourseta, // Geeft de fromCourseta state door
                setFromCourseta, // Geeft de functie door om fromCourseta bij te werken
                addPatient,
                updatePatientField,
            }}
        >
            {children}
        </ScenarioContext.Provider>
    );
}
