import React, { useState, useEffect } from 'react';
import { useNotification } from '../../contexts/NotificationContext';
import { useAppData } from '../../contexts/AppDataContext';
import * as XLSX from 'xlsx'; // <-- xlsx library to parse Excel files
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth } from '../../firebase';
import { authMail } from '../../emails/Auth_mail';
import { addDocument } from '../../services/FirebaseService';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

export default function MultiInviteUser({ onClose, currentUser }) {
    const { showNotification } = useNotification();
    const { addUser, schools, courses } = useAppData();
    const [fileData, setFileData] = useState([]);
    const roles = ['Teacher', 'Student']; // No Admin allowed
    const [error, setError] = useState('');
    const [validationResults, setValidationResults] = useState([]);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [templateUrl, setTemplateUrl] = useState(null);

    useEffect(() => {
        const storage = getStorage();
        const templateRef = ref(storage, 'students_multi_invite.xlsx'); // Zorg ervoor dat dit de juiste pad is in jouw Firebase Storage

        getDownloadURL(templateRef)
            .then((url) => {
                setTemplateUrl(url); // Sla de gegenereerde URL op in de state
            })
            .catch((error) => {
                console.error('Error fetching template file from Firebase Storage:', error);
            });
    }, []); // Lege dependency array zorgt ervoor dat dit maar één keer gebeurt bij het mounten van de component

    const downloadTemplate = () => {
        if (templateUrl) {
            window.open(templateUrl, '_blank');
        }
    };
    // Function to handle the file upload
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = event.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const parsedData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

            // Example structure of parsedData: [ [firstName, lastName, email, role, schoolName, courseName], ... ]
            setFileData(parsedData);
        };
        reader.readAsBinaryString(file);
    };

    // Function to validate the users
    const validateUsers = () => {
        const rows = fileData.slice(1); // Skip the header row
        const results = rows.map(([firstName, lastName, email, roleName, schoolName, courseName]) => {
            const errors = [];

            // Check if required fields are present
            if (!firstName || !lastName || !email || !roleName || !schoolName) {
                errors.push('Missing required fields');
            }

            // Validate role
            if (!roles.includes(roleName)) {
                errors.push(`Invalid role: ${roleName}`);
            }

            // Validate school
            const school = schools.find(s => s.name === schoolName);
            if (!school) {
                errors.push(`School not found: ${schoolName}`);
            }

            // If courseName is provided, validate it
            if (courseName && school) {
                const course = courses.find(c => c.schoolID === school.id && c.className === courseName);
                if (!course) {
                    errors.push(`Course not found: ${courseName} in school: ${schoolName}`);
                }
            }

            return {
                firstName,
                lastName,
                email,
                roleName,
                schoolName,
                courseName,
                isValid: errors.length === 0,
                errors,
            };
        });

        setValidationResults(results);
        setIsSubmitEnabled(results.every(result => result.isValid));
    };

    // Re-validate the users whenever the fileData changes
    useEffect(() => {
        if (fileData.length > 1) {
            validateUsers();
        }
    }, [fileData]);

    // Function to send the email invitation
    const sendInvite = async (user) => {
        try {
            if (!user.id) {
                throw new Error('User ID is missing.');
            }

            //console.log("Sending invite for user:", user);

            const registrationLink = `http://coursetavr.com/#/register?token=${user.id}&email=${encodeURIComponent(user.email)}`;
            const actionCodeSettings = {
                url: registrationLink,
                handleCodeInApp: true,
            };

            //console.log("Generated registration link:", registrationLink);

            // Send Firebase sign-in link
            // await sendSignInLinkToEmail(auth, user.email, actionCodeSettings);

            // Send personalized email via your email service
            const htmlcontent = authMail(user.firstName, registrationLink);
            const emailData = {
                to: [{ email: user.email, name: user.firstName }],
                from: { email: 'info@coursetavr.com', name: 'CoursetaVR' },
                subject: 'Create your account',
                html: htmlcontent,
            };

            await addDocument(emailData, 'emails');  // Add the email to your email sending list

            showNotification('success', `Invitation link sent to ${user.email}`);
            //console.log("Invitation sent to:", user.email);
        } catch (error) {
            console.error('Error sending invitation link:', error);
            showNotification('danger', `Error sending invitation to ${user.email}`);
        }
    };

    // Function to process and add users
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const validUsers = validationResults.filter(user => user.isValid);

            const promises = validUsers.map(async ({ firstName, lastName, email, roleName, schoolName, courseName }) => {
                const school = schools.find(s => s.name === schoolName);
                const course = courses.find(c => c.schoolID === school.id && c.className === courseName);

                const newUser = {
                    firstName,
                    lastName,
                    email,
                    roleName,
                    roleID: roleName === 'Teacher' ? '33' : '22', // Map roles to IDs
                    schoolName: school.name,
                    schoolID: school.id,
                    attendingClasses: course ? [course.id] : [], // Add course if provided and valid
                };

                // Add user
                const userId = await addUser(newUser);

                // Update user object with the generated ID
                const updatedUser = { ...newUser, id: userId };

                // Send the invite email
                await sendInvite(updatedUser);
            });

            await Promise.all(promises);
            showNotification('success', 'All valid users have been successfully invited.');
            setFileData([]);
        } catch (error) {
            console.error('Error adding users:', error);
            showNotification('danger', error.message || 'Failed to add users.');
        }
    };

    return (
        <div
            id="createUserdefaultModal"
            tabIndex="-1"
            aria-hidden="true"
            className={`fixed inset-0 z-50 overflow-y-auto flex items-center justify-center ${onClose ? '' : 'hidden'}`}
        >
            <div className="flex items-center justify-center min-h-screen relative p-4 w-full max-w-3xl h-full md:h-auto">
                <div className="relative p-4 w-full max-w-3xl h-full md:h-auto bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                    <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Multi-Invite Users</h3>
                        <button
                            onClick={onClose}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload Excel File</label>
                            <input
                                type="file"
                                accept=".xlsx, .xls"
                                onChange={handleFileUpload}
                                className="block w-full text-sm text-gray-900 border-gray-300 rounded-lg"
                            />
                            {error && <div className="text-red-500 mt-2">{error}</div>}
                        </div>

                        <div className="mb-4">
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                Use the file below to invite multiple users. The layout ensures that the system can correctly interpret the data. Please make sure that spelling is accurate, and that both the school and courses already exist for the process to work properly.
                            </p>

                            {/* Download Template Button */}
                            <button
                                type="button"
                                onClick={downloadTemplate} // Roep de functie aan die de URL opent
                                className="inline-flex items-center text-blue-600 hover:underline"
                                disabled={!templateUrl} // Disable als de template URL nog niet geladen is
                            >
                                Download Template
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    className="w-5 h-5 ml-2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 16v1a2 2 0 002 2h12a2 2 0 002-2v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                    />
                                </svg>
                            </button>
                        </div>
                        {validationResults.length > 0 && (
                            <div className="mb-4 max-h-96 overflow-y-auto">
                                <p className="font-semibold">Validation Results:</p>
                                <ul className="space-y-4">
                                    {validationResults.map((user, idx) => (
                                        <li key={idx} className={`p-4 rounded-lg ${user.isValid ? 'bg-green-100' : 'bg-red-100'}`}>
                                            <p>
                                                {user.firstName} {user.lastName} - {user.email} - {user.roleName} - {user.schoolName}
                                                {user.courseName && <> - {user.courseName}</>}
                                                {user.isValid ? (
                                                    <span className="ml-2 text-green-600 font-semibold">Valid</span>
                                                ) : (
                                                    <span className="ml-2 text-red-600 font-semibold">Invalid</span>
                                                )}
                                            </p>
                                            {!user.isValid && (
                                                <ul className="mt-1 text-red-600">
                                                    {user.errors.map((error, errorIdx) => (
                                                        <li key={errorIdx}>- {error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                            disabled={!isSubmitEnabled}
                        >
                            Invite Users
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}