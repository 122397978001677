import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const RowSkeletonLoader = () => {
    return (
        <div className="space-y-4">
            {Array.from({ length: 5 }).map((_, index) => (
                <div key={index} className="flex items-center space-x-4 p-4 border rounded-lg">
                    <Skeleton circle={true} height={40} width={40} />
                    <Skeleton height={20} width="20%" />
                    <Skeleton height={20} width="10%" />
                    <Skeleton height={20} width="30%" />
                    <Skeleton height={20} width="20%" />
                    <Skeleton height={20} width="10%" />
                </div>
            ))}
        </div>
    );
};

export const CardSkeletonLoader = () => {
    return (
        <div className="flex flex-wrap space-x-4 space-y-4">
            {Array.from({ length: 6 }).map((_, index) => (
                <div key={index} className="w-48 h-64 p-4 border rounded-lg">
                    <Skeleton height={120} />
                    <Skeleton height={20} className="my-2" />
                    <Skeleton height={20} width="80%" />
                </div>
            ))}
        </div>
    );
};

const SkeletonLoader = ({ type }) => {
    if (type === 'card') {
        return <CardSkeletonLoader />;
    } else if (type === 'row') {
        return <RowSkeletonLoader />;
    }
    return null;
};

SkeletonLoader.propTypes = {
    type: PropTypes.oneOf(['card', 'row']).isRequired,
};

export default SkeletonLoader;
