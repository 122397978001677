import React from "react";
import { useUser } from "../../../contexts/UserContext";
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
export const ClosedQuestionCard2 = ({ data }) => {
    // isCorrect checks if the index matches the correct answer
    const isCorrect = index => index === data.correctAnswer;
    // isUserAnswer checks if the index matches the user's answer
    const isUserAnswer = index => index === data.userAnswer;
    // isAnswered checks if the user has answered (i.e., userAnswer is not null)
    const isAnswered = data.userAnswer !== null;

    return (
        <div className="mb-8 mt-8 p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">{data.question}</h2>
            <div className="grid gap-4">
                {data.answers?.map((choice, index) => (
                    <div
                        key={index}
                        className={`relative flex items-center justify-between p-4 rounded-lg
                        ${isUserAnswer(index) ? "bg-blue-100 dark:bg-blue-200 border-blue-500" : "border border-gray-300 dark:border-gray-600"}
                        ${isAnswered && !isUserAnswer(index) ? "opacity-50" : ""}
                        `}
                    >
                        <span className={`block w-full text-left font-normal dark:text-gray-200`}>
                            {choice}
                        </span>
                        {isAnswered && (
                            <span className="absolute inset-y-0 right-4 flex items-center">
                                {isCorrect(index) ? (
                                    <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                ) : isUserAnswer(index) ? (
                                    <XCircleIcon className="w-6 h-6 text-red-500" />
                                ) : null}
                            </span>
                        )}
                    </div>
                ))}
            </div>
            {isAnswered && (
                <div className="mt-4 p-4 rounded-lg border border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800">
                    <p className={`text-md font-semibold ${isCorrect(data.userAnswer) ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                        {isCorrect(data.userAnswer) ? 'Correct!' : 'Incorrect!'}
                    </p>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Rationale:</h3>
                    <p className="mt-2 text-md text-gray-600 dark:text-gray-300">
                        {data.rationale ? data.rationale : 'There was no rationale for this question.'}
                    </p>
                </div>
            )}
        </div>
    );
};

