import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { startAssignmentForUser } from '../../../services/FirebaseService'; // Je kunt deze gebruiken om de voortgang te registreren
import { useUser } from '../../../contexts/UserContext';

export default function ContinueSim({ onClose, navigate, assessment, grade }) {
    const user = useUser();

    // Log de assessment en grade om te controleren of beide goed worden doorgegeven
    useEffect(() => {
        console.log("Assessment received in ContinueSim:", assessment);
        console.log("Grade received in ContinueSim:", grade); // Log de grade om te controleren of het de juiste is
    }, [assessment, grade]);

    const handleConfirm = () => {
        if (assessment) {
            // Navigeer naar het vervolg van de simulatie en geef de grade door
            navigate(`/main/simulationDetails/${assessment.id}`, { state: { grade } });
            startAssignmentForUser(user.id, assessment.id); // Registreer het starten van de opdracht als dat nodig is
            onClose();
        } else {
            console.error("Scenario is missing, cannot continue simulation"); // Log als het scenario ontbreekt
        }
    };

    const handleCancel = () => {
        // Annuleer de actie
        onClose(); // Sluit de pop-up
    };

    return (
        <>
            {/* Main modal */}
            <div id="continueSimModal" tabIndex="-1" aria-hidden="true" className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center w-full h-modal md:h-full">
                <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                    {/* Modal content */}
                    <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                        <h3 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">You are about to continue your assessment</h3>
                        <p className="mb-4 font-light text-gray-500 dark:text-gray-400">Are you sure you want to continue? Your progress will be updated once you proceed.</p>
                        <div className="p-4 mb-4 text-sm text-left text-green-700 bg-green-100 rounded-lg sm:mb-5 dark:bg-gray-700 dark:text-green-300" role="alert">
                            <div className="items-center mb-1">
                                <svg className="inline flex-shrink-0 mr-1 w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd"></path>
                                </svg>
                                <span className="sr-only">Notice</span>
                                <span className="font-semibold leading-none">Notice</span>
                            </div>
                            <p>By continuing, you will update your current grade progress and won't be able to retry.</p>
                        </div>
                        <div className="flex justify-center items-center space-x-4">
                            <button onClick={handleCancel} type="button" className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                No, cancel
                            </button>
                            <button onClick={handleConfirm} type="submit" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                                Yes, continue
                                <svg className="rtl:rotate-180 w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
