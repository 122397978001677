import React, { createContext, useContext, useState } from 'react';

const OverviewContext = createContext();

export function useOverview() {
    return useContext(OverviewContext);
}

export function OverviewProvider({ children }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleNumItemsPerPageChange = (num) => {
        setItemsPerPage(num);
        setCurrentPage(1); // Reset naar pagina 1 bij wijziging van items per pagina
    };

    const value = {
        searchQuery,
        setSearchQuery, // Voeg deze toe
        sortConfig,
        setSortConfig, // Voeg deze toe
        currentPage,
        itemsPerPage,
        handleSearchChange,
        handleSort,
        handlePageChange,
        handleNumItemsPerPageChange,
    };

    return (
        <OverviewContext.Provider value={value}>
            {children}
        </OverviewContext.Provider>
    );
}
