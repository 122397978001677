import React, { useState } from "react";
import { usePopUp } from "../../../contexts/PopUpContext";

export default function CreateModulePopUp({ onCreate }) {
    const { showPopUp } = usePopUp();

    return (
        <div className={`fixed inset-0 z-50 overflow-y-auto flex items-center justify-center`}>
            <div className="flex items-center justify-center min-h-screen relative p-4 w-full max-w-2xl h-full md:h-auto">
                <div className="relative p-4 w-full max-w-2xl h-full md:h-auto bg-white rounded-lg shadow dark:bg-gray-800">
                    <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                        <div>
                            <h3 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                                Create a new module
                            </h3>
                            <p class="mb-4 text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                Use modules to organize your learning materials into separate learning blocks.
                            </p>
                        </div>
                        <button onClick={() => showPopUp(null)} data-collapse-toggle="newsletter-pop-up" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" id="close-modal">
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </button>
                    </div>
                    <form onSubmit={async (e) => {
                        e.preventDefault();
                        await onCreate(e.target[0].value, e.target[1].value);
                        showPopUp(null);
                    }}>
                        <div class="relative z-0 mb-7">
                            <input type="text" id="floating_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary-500 focus:outline-none focus:ring-0 focus:border-primary-600 peer" placeholder=" " required />
                            <label for="floating_name" class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary-600 peer-focus:dark:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Title (e.g. Week 1)</label>
                        </div>
                        <div class="relative z-0 mb-7">
                            <input type="text" id="floating_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary-500 focus:outline-none focus:ring-0 focus:border-primary-600 peer" placeholder=" " required />
                            <label for="floating_name" class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary-600 peer-focus:dark:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Subtitle (e.g. Cardiac)</label>
                        </div>
                        <div>
                            <button type="submit" class="py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg cursor-pointer bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Create</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}