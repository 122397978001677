import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faFilePdf, faFilePowerpoint, faFileExcel, faFileImage, faFileVideo, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { getFileUrl } from '../../../services/FirebaseService';
import Accordion from '../../../components/Accordion';

// Iconen voor bestandstypen
const fileIcons = {
    pdf: faFilePdf,
    ppt: faFilePowerpoint,
    pptx: faFilePowerpoint,
    xls: faFileExcel,
    xlsx: faFileExcel,
    mp4: faFileVideo,
    png: faFileImage,
    jpg: faFileImage,
    other: faFileAlt
};

// Leesbare categorieën
const readableCategories = {
    pdf: "PDFs",
    ppt: "PowerPoints",
    pptx: "PowerPoints",
    xls: "Excel Files",
    xlsx: "Excel Files",
    mp4: "Videos",
    png: "Photos",
    jpg: "Photos",
    other: "Other Files"
};

const Materials = ({ handleUploadDocument, course, user, showNotification, handleDeleteMaterial }) => {
    const [filePreviews, setFilePreviews] = useState({});

    useEffect(() => {
        const loadPreviews = async () => {
            const previews = {};
            for (const material of course?.learningMaterials || []) {
                const ext = material.split('.').pop().toLowerCase();
                const url = await getFileUrl(`learningMaterials/${course.id}/${material}`);

                previews[material] = url;  // We gebruiken de URL voor alle bestandsvoorbeelden
            }
            setFilePreviews(previews);
        };

        if (course?.learningMaterials) {
            loadPreviews();
        }
    }, [course]);

    const categorizeMaterials = (materials) => {
        const categorized = {};
        materials.forEach((material) => {
            const ext = material.split('.').pop().toLowerCase();
            const category = fileIcons[ext] ? ext : 'other';  // Gebruik 'other' als geen specifieke icon
            if (!categorized[category]) {
                categorized[category] = [];
            }
            categorized[category].push(material);
        });
        return categorized;
    };

    const categorizedMaterials = categorizeMaterials(course?.learningMaterials || []);

    const renderMaterialList = (materials) => {
        return materials.map((material, idx) => {
            const ext = material.split('.').pop().toLowerCase();
            const icon = fileIcons[ext] || fileIcons['other'];  // Fallback naar 'other' als het type onbekend is

            return (
                <div
                    key={idx}
                    className="flex items-center p-4 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 mb-4 w-full"
                >
                    {/* Icon voor bestandstype */}
                    <FontAwesomeIcon icon={icon} className="text-gray-500 w-8 h-8" />

                    {/* Bestand details */}
                    <div className="flex-grow ml-4">
                        <button
                            onClick={async () => {
                                const url = await getFileUrl(`learningMaterials/${course.id}/${material}`);
                                if (url) {
                                    window.open(url, '_blank');
                                } else {
                                    showNotification('danger', 'Error: Unable to open file');
                                }
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-sm font-medium text-blue-600 hover:underline"
                        >
                            {material}
                        </button>
                    </div>

                    {/* Trash icon voor Admins of niet-studenten */}
                    {(user?.roleName === "Admin" || (user?.roleName !== "Student" && course?.id !== "66Fqzp90Cib8lmwCN3QZ")) && (
                        <button
                            onClick={() => {
                                if (window.confirm(`Are you sure you want to delete ${material}?`)) {
                                    handleDeleteMaterial(material);
                                }
                            }}
                            className="text-red-500 hover:text-red-700 ml-4"
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    )}
                </div>
            );
        });
    };

    return (
        <div className="md:col-span-1">
            <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-semibold dark:text-white">Learning Materials</h3>
                    {(user?.roleName === "Admin" || (user?.roleName !== "Student" && course?.id !== "66Fqzp90Cib8lmwCN3QZ")) && (
                        <div className='flex items-center'>
                            <button onClick={handleUploadDocument} className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                                Upload Material
                            </button>
                        </div>
                    )}
                </div>

                {/* Render Accordions for different categories */}
                <ul className="mt-4">
                    {Object.keys(categorizedMaterials).length > 0 ? (
                        Object.keys(categorizedMaterials).map((category, index) => (
                            <Accordion
                                key={index}
                                title={readableCategories[category] || "Other Files"}  // Gebruik de leesbare categorie
                                content={<div className="flex flex-wrap">{renderMaterialList(categorizedMaterials[category])}</div>}
                                isOpenByDefault={false}
                                itemCount={categorizedMaterials[category].length}
                                showViewAll={categorizedMaterials[category].length > 3}
                                onViewAll={() => {
                                    // Logica om alle items te tonen als er meer dan 3 zijn
                                }}
                            />
                        ))
                    ) : (
                        <div className="text-center py-12">
                            <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg" alt="No materials" />
                            <p className="text-gray-700 dark:text-white font-medium text-lg">No learning materials.</p>
                            <p className="text-gray-500 dark:text-gray-400">You can upload new learning materials for students to see.</p>
                        </div>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default Materials;
