import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';

export default function GradeCard({ title, description, score, maxScore }) {
  let Icon;
  let color;

  if (score === maxScore) {
    // Groen als de score maximaal is
    Icon = CheckCircleIcon;
    color = 'text-green-500';
  } else if (score > 1 && score < maxScore) {
    // Oranje als de score meer dan 1 is maar niet maximaal
    Icon = ExclamationCircleIcon;
    color = 'text-yellow-200';
  } else {
    // Rood in alle andere gevallen (dus als het fout is of de score 1 of minder is)
    Icon = XCircleIcon;
    color = 'text-red-500';
  }

  return (
    <div className={`p-4 p-6 bg-gray-50 shadow-lg rounded-md flex justify-between items-center`}>
      <div className="flex items-start">
        <Icon className={`h-16 w-16 ${color} mr-4 flex-shrink-0`} aria-hidden="true" />
        <div>
          <h3 className="text-xl font-bold text-gray-800 mb-4">{title}</h3>
          <p className="text-gray-800">{description}</p>
        </div>
      </div>
      <div className="text-l font-bold text-gray-800 flex-shrink-0 w-1/4">
        {score} / {maxScore} Points Earned
      </div>
    </div>
  );
}
