import * as React from 'react';
import { useEffect, useState } from 'react';
import { ChatAlt2Icon } from '@heroicons/react/solid';
import { useGrade } from '../contexts/GradingContext';

export default function Transcripts() {
  const [transcripts, setTranscripts] = useState([]);
  const { grade } = useGrade();

  useEffect(() => {
    if (grade) {
      setTranscripts(grade.transcript || []);
    }
  }, [grade]);

  const parseTranscriptString = (transcriptString) => {
    const [speaker, time, message] = transcriptString.split('/');
    return { speaker, time, message };
  };

  return (
    <div className="relative mx-auto mb-28" style={{ maxWidth: '640px' }}>
      <div className="absolute left-1/2 transform -translate-x-1/2 border-l-2 border-gray-300" style={{ height: '100%' }} />
      {transcripts.map((transcript, index) => {
        const { speaker, time, message } = parseTranscriptString(transcript);
        const isPlayer = speaker === 'Player';

        const bubbleClasses = isPlayer
          ? 'bg-blue-500 text-white rounded-br-lg rounded-tl-lg'
          : 'bg-gray-100 text-gray-900 rounded-bl-lg rounded-tr-lg';
        const iconClasses = isPlayer
          ? 'text-blue-500'
          : 'text-gray-600';
        const bubblePositioning = isPlayer ? 'right-3/4' : 'left-3/4';
        const marginTop = index === 0 ? 'mt-0' : 'mt-40';

        return (
          <div key={index} className={`flex justify-center items-start relative ${marginTop}`}>
            <div className={`absolute ${bubblePositioning} transform ${isPlayer ? 'translate-x-1/4' : '-translate-x-1/4'} max-w-xs w-full px-4 py-2 shadow-lg rounded-lg ${bubbleClasses}`}>
              <div className="font-bold">
                {isPlayer ? 'Player' : 'Patient'}
              </div>
              <p className="whitespace-pre-line text-sm">
                {message}
              </p>
              <div className={`text-xs mt-2 ${isPlayer ? 'text-right' : 'text-left'}`}>
                {time}
              </div>
            </div>
            <div className="absolute mt-8 w-6 h-6 bg-white rounded-full shadow flex items-center justify-center">
              <ChatAlt2Icon className={`h-4 w-4 ${iconClasses}`} />
            </div>
          </div>
        );
      })}
    </div>
  );
}
