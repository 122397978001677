import React from 'react';
import GradesOverview from '../../overviews/GradesOverview';

const Grades = ({ course }) => {
  return (
    <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen">
     

        {/* Render de GradesOverview component en geef het courseId door */}
        <GradesOverview courseId={course.id} />

    </div>
  );
};

export default Grades;
