import React, { useState } from 'react';
import Courseta_Logo from '../../../assets/images/Courseta_Logo.png';

const People = ({ attendees, user, handleAttendeeClick, handleAddPeopleToClassroom, course }) => {
    // Check if the course is the Introduction Course and if the user is an Admin
    const isEditable = (user?.roleName === 'Admin' || course?.id !== '66Fqzp90Cib8lmwCN3QZ');

    // State to hold the search query
    const [searchQuery, setSearchQuery] = useState('');

    // Filter the attendees based on the search query
    const filteredAttendees = attendees?.filter(attendee =>
        attendee.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        attendee.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        attendee.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Fallback UI for the Introduction/Pilot course
    if (!isEditable) {
        return (
            <div className="text-center py-12">
                <img
                    className="w-32 h-32 mx-auto"
                    src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg"
                    alt="empty state"
                />
                <p className="text-gray-700 dark:text-white font-medium text-lg text-center">
                    This is the Pilot course, no students are displayed here.
                </p>
            </div>
        );
    }

    return (
        <div className="p-4 mt-5 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold whitespace-nowrap dark:text-white">Students</h3>
                {user?.roleName !== "Student" && (
                    <button
                        className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300"
                        onClick={handleAddPeopleToClassroom}
                    >
                        Edit
                    </button>
                )}
            </div>

            {/* Search bar */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Search students..."
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {filteredAttendees?.length > 0 ? (
                    filteredAttendees.map((attendee, index) => (
                        attendee.id !== user?.id ? (
                            <li key={index} className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4 cursor-pointer" onClick={() => handleAttendeeClick(attendee.id)}>
                                    <div className="flex-shrink-0">
                                        <img className="w-8 h-8 rounded-full" src={attendee.photo_url || Courseta_Logo} alt={`${attendee.firstName} {attendee.lastName} profile`} />
                                    </div>
                                    <div className="flex-1 min-w-0">
                                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                            {attendee.firstName} {attendee.lastName}
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                            {attendee.email}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        ) : <div key={index}></div>
                    ))
                ) : (
                    <div className="text-center py-12">
                        <img
                            className="w-32 h-32 mx-auto"
                            src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg"
                            alt="No students available"
                        />
                        <p className="text-gray-700 dark:text-white font-medium text-lg">No students to display.</p>
                        <p className="text-gray-500 dark:text-gray-400">Try searching again or add new students.</p>
                    </div>
                )}
            </ul>
        </div>
    );
};

export default People;
