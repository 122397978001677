
import { useParams } from "react-router-dom";
import React, { createContext, useContext, useState, useEffect } from 'react';
import { addAssignmentFirebase, addCourseAttendees, addDocument, addScenariosToCourse, deleteAssignmentFirebase, deleteDocument, deleteFile, fetchDataList, getAssignmentList, getCourse, getCourseAttendees, getFileUrl, getUserList, removeCourseAttendees, removeScenariosFromCourse, updateDocument, uploadFile } from "../../../services/FirebaseService";
import { useAppData } from "../../../contexts/AppDataContext";
import { useNotification } from "../../../contexts/NotificationContext";
import { coursesCollection, modulesCollection } from "../../../firebase";
import { arrayRemove, arrayUnion, getDocs } from "firebase/firestore";

const CourseContext = createContext();

export function useCourse() {
    return useContext(CourseContext);
}

export function CourseProvider({ children }) {
    const { courses, setCourses } = useAppData();
    const [course, setCourse] = useState(null);
    const [files, setFiles] = useState([]);
    const [modules, setModules] = useState([]);
    const [modulesLoading, setModulesLoading] = useState(true);

    const { classroomId } = useParams();

    const { showNotification } = useNotification();

    useEffect(() => {
        const fetchData = () => {
            setCourse(courses.find(c => c.id == classroomId));
        }

        fetchData();
    }, [courses]);

    useEffect(() => {
        const fetchData = async () => {
            if (course) {
                setModulesLoading(true);
                const mIds = course.modules ?? [];
                const moduleList = await fetchDataList(mIds, modulesCollection);
                //.map(m => { return { ...m, content: m.content?.filter(c => c.item) ?? [] } })
                setModules(moduleList);
                setModulesLoading(false);
            }
        }

        fetchData();
    }, [course])

    const addAssignment = async (assignment) => {
        const id = await addAssignmentFirebase(course.id, assignment);
        const updatedCourses = courses.map((c) => c.id != course.id ? c : { ...course, assessments: [...course.assessments, id] });
        setCourses(updatedCourses);
        //console.log(updatedCourses);
    }

    // const deleteAssignment = async (id) => {
    //     const updatedCourses = courses.map((c) => c.id != course.id ? c : { ...course, assessments: course.assessments.filter((assessment) => assessment !== id) });
    //     setCourses(updatedCourses);
    //     //console.log(updatedCourses);
    //     await deleteAssignmentFirebase(course.id, id);
    //     showNotification('success', 'Assignment deleted');
    // }
    const deleteAssignment = async (id) => {
        const updatedCourses = courses.map((c) =>
            c.id !== course.id ? c : {
                ...c,
                assessments: c.assessments.map((assessment) =>
                    assessment.id !== id ? assessment : { ...assessment, isDeleted: true }
                )
            }
        );

        setCourses(updatedCourses);
        //console.log(updatedCourses);
        await updateDocument(id, "assessments", { isDeleted: true });
        showNotification('success', 'Assignment deleted');
    }


    const uploadCourseFiles = async (files) => {
        for (const file of files) {
            await uploadFile(file, `learningMaterials/${course.id}`);
            await updateDocument(course.id, coursesCollection, {
                learningMaterials: arrayUnion(file.name)
            });
        }

        const fileNames = files.map(f => f.name);

        //Update cache
        const updatedCourses = courses.map((c) => c.id != course.id ? c : { ...course, learningMaterials: [...course.learningMaterials ?? [], ...fileNames] });
        setCourses(updatedCourses);
    }
    

    const deleteCourseFile = (fileName) => {
        //Update cache
        const updatedCourses = courses.map((c) => c.id != course.id ? c : { ...course, learningMaterials: course.learningMaterials?.filter((f) => f !== fileName) });
        setCourses(updatedCourses);

        deleteFile(fileName, `learningMaterials/${course.id}`);

        updateDocument(course.id, coursesCollection, {
            learningMaterials: arrayRemove(fileName)
        });
    }

    const addUsers = async (users) => {
        const updatedCourses = courses.map((c) => c.id != course.id ? c : { ...course, attendees: [...users, ...course.attendees] });
        //console.log(updatedCourses);
        setCourses(updatedCourses);
        await addCourseAttendees(users, course.id);
    }

    const removeUsers = async (users) => {
        const updatedCourses = courses.map((c) => c.id != course.id ? c : { ...course, attendees: course.attendees.filter((attendee) => !users.includes(attendee)) });
        setCourses(updatedCourses);
        await removeCourseAttendees(users, course.id);
    }

    const addScenarios = async (scenarios) => {
        const updatedCourses = courses.map((c) => c.id != course.id ? c : { ...course, availableScenarios: [...scenarios, ...course.availableScenarios ?? []] });
        //console.log(updatedCourses);
        setCourses(updatedCourses);
        await addScenariosToCourse(scenarios, course.id);
    }

    const removeScenarios = async (scenarios) => {
        const updatedCourses = courses.map((c) => c.id != course.id ? c : { ...course, availableScenarios: course.availableScenarios?.filter(n => !scenarios.includes(n)) });
        setCourses(updatedCourses);
        await removeScenariosFromCourse(scenarios, course.id);
    }

    const updateModuleStatus = async (moduleId, newStatus) => {
        try {
            // Update het specifieke module document in Firebase
            await updateDocument(moduleId, modulesCollection, {
                status: newStatus
            });
    
            // Update de state voor die specifieke module
            setModules((prevModules) => 
                prevModules.map((module) =>
                    module.id === moduleId ? { ...module, status: newStatus } : module
                )
            );
            //console.log(module.id + "Updated to " + newStatus);
    
            showNotification('success', `Module status updated to ${newStatus ? 'Published' : 'Unpublished'}`);
        } catch (error) {
            console.error('Error updating module status:', error);
            showNotification('danger', 'Failed to update module status');
        }
    };
    

    const addModule = async (title, subtitle) => {
        const id = await addDocument({
            title: title,
            subtitle: subtitle,
            course: course.id,
            status: false
        }, modulesCollection);

        //setModules([...modules ?? [], module]);

        //Update the course
        await updateDocument(course.id, coursesCollection, {
            modules: arrayUnion(id)
        });

        setCourses(courses.map(c => c.id !== course.id ? c : { ...c, modules: [...c.modules ?? [], id] }));
    }

    const deleteModule = async (id) => {
        await deleteDocument(id, modulesCollection);

        //Update the course
        await updateDocument(course.id, coursesCollection, {
            modules: arrayRemove(id)
        });

        setCourses(courses.map(c => c.id !== course.id ? c : { ...c, modules: c.modules?.filter(m => m.id !== id) }));
    }

    const addModuleItem = async (content, moduleId) => {
        if (modules.find(m => m.id === moduleId)?.content?.find(c => c.item === content.item)) {
            console.warn("Module already contains: " + content.type + " : " + content.item);
            return false;
        }

        await updateDocument(moduleId, modulesCollection, {
            content: arrayUnion(content)
        });

        // //console.log(modules);
        const updatedModules = modules.map(m => m.id !== moduleId ? m : { ...m, content: [...m.content ?? [], content] })
        // //console.log(updatedModules);
        setModules(updatedModules);

        return true;
    }

    const removeModuleItem = async (content, moduleId) => {
        await updateDocument(moduleId, modulesCollection, {
            content: arrayRemove(content)
        });

        const updatedModules = modules.map(m => m.id !== moduleId ? m : { ...m, content: m.content?.filter(c => c.item !== content.item) })
        setModules(updatedModules);
    }

    return (
        <CourseContext.Provider value={{ course, setCourse, addAssignment, deleteAssignment, uploadCourseFiles, deleteCourseFile, addUsers, removeUsers, addScenarios, removeScenarios, addModule, deleteModule, modules, modulesLoading, addModuleItem, removeModuleItem, updateModuleStatus }}>
            {children}
        </CourseContext.Provider>
    );
}