import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useAppData } from '../contexts/AppDataContext';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

// Register the components in Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function CourseAnalyticsGraph() {
    const { grades, courses } = useAppData();
    const [courseData, setCourseData] = useState([]);
    const currentUser = useUser(); // Get the current user
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(`/main/classroomoverview`);
    }

    useEffect(() => {
        const aggregatedData = courses.map((course) => {
            let totalAccuracy = 0;
            let totalSafety = 0;
            let totalCommunication = 0;
            let totalAnswers = 0;
            let countAccuracy = 0;
            let countSafety = 0;
            let countCommunication = 0;
            let countAnswers = 0;

            // Check if the current user is a Student, in that case only use their grades
            const relevantGrades = currentUser.roleName === 'Student'
                ? { [currentUser.id]: grades[currentUser.id] || [] }
                : grades;

            Object.keys(relevantGrades).forEach((userId) => {
                const userGrades = relevantGrades[userId];
                userGrades.forEach((grade) => {
                    if (grade.courseName === course.className) {
                        if (grade.accuracy !== undefined) {
                            totalAccuracy += grade.accuracy;
                            countAccuracy++;
                        }
                        if (grade.safety !== undefined) {
                            totalSafety += grade.safety;
                            countSafety++;
                        }
                        if (grade.communication !== undefined) {
                            totalCommunication += grade.communication;
                            countCommunication++;
                        }
                        if (grade.answers !== undefined) {
                            totalAnswers += grade.answers;
                            countAnswers++;
                        }
                    }
                });
            });

            if (countAccuracy > 0 || countSafety > 0 || countCommunication > 0 || countAnswers > 0) {
                return {
                    courseName: course.className,
                    accuracy: countAccuracy > 0 ? totalAccuracy / countAccuracy : 0,
                    safety: countSafety > 0 ? totalSafety / countSafety : 0,
                    communication: countCommunication > 0 ? totalCommunication / countCommunication : 0,
                    answers: countAnswers > 0 ? totalAnswers / countAnswers : 0,
                };
            }
            return null;
        }).filter((course) => course !== null);

        setCourseData(aggregatedData);
    }, [grades, courses, currentUser]);

    const chartData = {
        labels: courseData.map((course) => course.courseName),
        datasets: [
            {
                label: 'Accuracy',
                data: courseData.map((course) => course.accuracy),
                backgroundColor: '#00bcd4',
                borderRadius: 10, // Add rounded corners to the bars
            },
            {
                label: 'Communication',
                data: courseData.map((course) => course.communication),
                backgroundColor: '#1e88e5',
                borderRadius: 10, // Add rounded corners to the bars
            },
            {
                label: 'Safety',
                data: courseData.map((course) => course.safety),
                backgroundColor: '#29b6f6',
                borderRadius: 10, // Add rounded corners to the bars
            },
            {
                label: 'Answers',
                data: courseData.map((course) => course.answers),
                backgroundColor: '#4db6ac',
                borderRadius: 10, // Add rounded corners to the bars
            },
        ]
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false, // This ensures the chart fits nicely
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Courses'
                },
                grid: {
                    display: false // Removes grid lines from x-axis
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Percentage'
                },
                beginAtZero: true,
                max: 100, // Always show the y-axis from 0 to 100
                ticks: {
                    stepSize: 10 // Optional: Set step size for y-axis ticks
                },
                grid: {
                    borderDash: [5, 5] // Dotted grid lines for y-axis
                }
            }
        },
        plugins: {
            legend: {
                position: 'top'
            },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.dataset.label}: ${context.parsed.y.toFixed(1)}%`
                }
            }
        }
    };

    return (
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Course Analytics</h3>
                <h4 className="text-lg font-semibold text-gray-900 dark:text-white">Average Course Performance</h4>
                <button
                    className="text-blue-500 dark:text-blue-400 hover:underline"
                    onClick={handleNavigate}
                >
                    See Courses →
                </button>
            </div>
            {courseData.length > 0 ? (
                <div style={{ height: '400px' }}>
                    <Bar data={chartData} options={chartOptions} />
                </div>
            ) : (
                <p className="text-gray-600 dark:text-gray-400">No grades available for the courses.</p>
            )}
        </div>
    );
    
}
