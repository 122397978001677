import React, { useState } from "react";
import { usePopUp } from "../../../contexts/PopUpContext";

export default function AddAssignmentToModule({ module, course, addItem, handleCreateAssignment }) {
    const { showPopUp } = usePopUp();

    const [chosenAssignment, setChosenAssignment] = useState(null);

    if (!module) {
        showPopUp(null);
    }

    return (
        <div className={`fixed inset-0 z-50 overflow-y-auto flex items-center justify-center bg-black bg-opacity-50`}>
            <div className="relative p-4 w-full max-w-2xl h-auto bg-white rounded-lg shadow dark:bg-gray-800">
                <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                    <div>
                        <h3 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                            {module.title}: {module.subtitle}
                        </h3>
                        <p className="mb-4 text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Add existing assignments or create a new one.
                        </p>
                    </div>
                    <button 
                        onClick={() => showPopUp(null)} 
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                        </svg>
                    </button>
                </div>
                <form onSubmit={() => {
                    addItem({ item: chosenAssignment?.id, type: "assignment" });
                    showPopUp(null);
                }}>
                    <fieldset className="mb-4">
                        <legend className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-2">Existing Assignments</legend>
                        {/* Scrollbare container met vaste hoogte */}
                        <div className="max-h-64 overflow-y-auto border border-gray-200 p-2 rounded-md">
                            {course.assessments?.map((assignment, index) => (
                                <div key={index} className="flex items-center mb-2">
                                    <input
                                        id={`assignment-${index}`}
                                        type="radio"
                                        name="assignment"
                                        value={assignment.id}
                                        checked={chosenAssignment?.id === assignment.id}
                                        onChange={() => { setChosenAssignment(assignment); }}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label htmlFor={`assignment-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        {assignment.assignmentName}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <button
                            onClick={handleCreateAssignment}
                            className="flex items-center px-4 py-1.5 border border-gray-400 mt-4 hover:text-white text-gray-600 dark:text-gray-300 rounded text-sm hover:bg-blue-600 dark:hover:bg-blue-700 mx-auto"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" className="w-6 h-6 mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                    d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Create New Assignment
                        </button>
                    </fieldset>
                    <button
                        type="submit"
                        className="p-3 mt-5 w-full justify-center text-white bg-blue-500 rounded-md hover:bg-blue-700"
                    >
                        Save Changes
                    </button>
                </form>
            </div>
        </div>
    );
}
