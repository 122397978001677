import React, { useState } from "react";

const Knowledge = () => {
    const [knowledgeArray, setKnowledgeArray] = useState([""]);

    // Voeg een nieuw lege entry toe aan de knowledgeArray
    const addKnowledgeEntry = () => {
        setKnowledgeArray([...knowledgeArray, ""]);
    };

    // Update de specifieke entry in de knowledgeArray
    const handleKnowledgeChange = (index, value) => {
        const updatedArray = knowledgeArray.map((entry, i) => (i === index ? value : entry));
        setKnowledgeArray(updatedArray);
    };

    // Verwijder een specifieke entry uit de knowledgeArray
    const removeKnowledgeEntry = (index) => {
        const updatedArray = knowledgeArray.filter((_, i) => i !== index);
        setKnowledgeArray(updatedArray);
    };

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6 flex justify-center">
            <div className="max-w-4xl w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-4">Knowledge & Cognition</h3>

                <div className="bg-yellow-100 dark:bg-yellow-900 text-yellow-700 dark:text-yellow-300 p-4 rounded mb-6">
                    <strong>Info:</strong> Knowledge enables your characters to draw upon additional information when relevant to the conversation. For example, you can define world-building information or add additional information about your character's background.
                </div>

                <div className="space-y-4">
                    {knowledgeArray.map((entry, index) => (
                        <div key={index} className="flex items-center space-x-4">
                            <input
                                type="text"
                                value={entry}
                                onChange={(e) => handleKnowledgeChange(index, e.target.value)}
                                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                                placeholder={`Knowledge entry ${index + 1}`}
                            />
                            <button
                                onClick={() => removeKnowledgeEntry(index)}
                                className="text-red-500 hover:text-red-700 focus:outline-none"
                            >
                                &times;
                            </button>
                        </div>
                    ))}
                </div>

                <button
                    onClick={addKnowledgeEntry}
                    className="mt-4 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300"
                >
                    Add Knowledge Entry
                </button>
            </div>
        </div>
    );
};

export default Knowledge;
