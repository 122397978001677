import React from 'react';
import { useScenario } from '../contexts/ScenarioContext';

const transformYoutubeLink = (url) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?youtu\.be\/(.+)/g;
  const matches = regex.exec(url);
  const youtubeId = matches && matches[1];
  return youtubeId ? `https://www.youtube.com/embed/${youtubeId}` : url;
};

const PrebriefingVideo = () => {
  const { scenario, setField } = useScenario();

  const handleUrlChange = (e) => {
    const transformedUrl = transformYoutubeLink(e.target.value);
    setField(transformedUrl, 'prebriefingVideo');
  };

  return (
    <div className="container mx-auto my-8 p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Prebriefing Video Link</h2>
      <div className="flex items-center mb-2">
        <input
          type="text"
          value={scenario?.prebriefingVideo || ""}
          onChange={handleUrlChange}
          placeholder="Paste the YouTube video link here"
          className="p-2 border rounded-l flex-grow"
        />
      </div>
    </div>
  );
};

export default PrebriefingVideo;
