import React, { useEffect, useState } from 'react';
import { useUser } from '../contexts/UserContext';
import { useAppData } from '../contexts/AppDataContext';
import SkeletonLoader from '../components/SkeletonLoader';
import { useNavigate } from 'react-router-dom';
import defaultImage from '../assets/images/22.png';
import { HiChevronRight } from 'react-icons/hi';
import { usePopUp } from '../contexts/PopUpContext';
import GradingDetails from '../pages/grades/GradingDetails';
import { GradingProvider } from '../pages/grades/contexts/GradingContext';
import { useNotification } from '../contexts/NotificationContext';

export default function LatestSubmissions({ limit, courseId, seeAllPath = '/main/gradesoverview' }) {
  const currentUser = useUser();
  const { grades, getUserData, assignments, isLoading } = useAppData();
  const [filteredGrades, setFilteredGrades] = useState([]);
  const navigate = useNavigate();
  const { showPopUp } = usePopUp();
  const { showNotification } = useNotification();

  useEffect(() => {
    let allGrades = [];

    if (currentUser.roleName === 'Admin') {
      // Admin can see all grades
      allGrades = Object.values(grades).flat();
    } else if (currentUser.roleName === 'Teacher') {
      // Teacher's grades are already filtered based on their courses
      allGrades = Object.values(grades).flat();
    } else if (currentUser.roleName === 'Student') {
      // Students can only see their own grades
      allGrades = grades[currentUser.id] || [];
    }

    const sortedGrades = allGrades
      .sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
      .slice(0, limit);

    setFilteredGrades(sortedGrades);
  }, [grades, assignments, currentUser, courseId, limit]);

  const isGradeComplete = (grade) => {
    if (grade.submissionDate) {
      return true;
    }
    const checkArrayComplete = (array) => {
      return Array.isArray(array) && array.length > 0 && !array.includes('unanswered');
    };

    const isDebriefingComplete = checkArrayComplete(grade.debriefing);
    const isDebriefing2Complete = checkArrayComplete(grade.debriefing_2);
    const isReflectionComplete = checkArrayComplete(grade.reflection);

    return isDebriefingComplete && isDebriefing2Complete && isReflectionComplete;
  };

  const calculateCompletionCircle = (result) => {
    const roundedResult = Math.round(result);
    const percentage = Math.min(Math.max(roundedResult, 0), 100);
    const circumference = 2 * Math.PI * 25;
    const dashArray = `${(percentage / 100) * circumference} ${circumference}`;

    return (
      <svg width="60" height="60">
        <circle
          cx="30"
          cy="30"
          r="25"
          strokeWidth="5"
          stroke="#e5e7eb"
          fill="none"
        />
        <circle
          cx="30"
          cy="30"
          r="25"
          strokeWidth="5"
          stroke={roundedResult >= 70 ? 'green' : roundedResult >= 50 ? 'blue' : 'red'}
          fill="none"
          strokeDasharray={dashArray}
          transform="rotate(-90 30 30)"
        />
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="14"
          fontWeight="bold"
          fill={roundedResult >= 70 ? 'green' : roundedResult >= 50 ? 'blue' : 'red'}
        >
          {roundedResult}%
        </text>
      </svg>
    );
  };

  const handleGradeClick = (grade) => {
    if (!isGradeComplete(grade)) {
      showNotification('warning', 'You have to finish this assignment before you can see the grade!');
    }
    else {
      showPopUp(
        <GradingProvider gradeId={grade.id}>
          <GradingDetails gradeId={grade.id} onClose={() => showPopUp(null)} />
        </GradingProvider>
      );
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Latest Submissions</h3>
        <button
          className="text-blue-500 dark:text-blue-400 hover:underline"
          onClick={() => typeof seeAllPath === 'function' ? seeAllPath() : navigate(seeAllPath)}
        >
          See Grades →
        </button>
      </div>
      {isLoading ? (
        <SkeletonLoader type="card" />
      ) : filteredGrades.length > 0 ? (
        <ul>
          {filteredGrades.map((grade) => {
            const assignment = assignments.find((assignment) => assignment.id === grade.assessmentID);
            const user = getUserData(grade.userID) || { firstName: 'Deleted', lastName: 'User' };
            const gradeComplete = isGradeComplete(grade);

            return (
              <li
                key={grade.id}
                className="flex items-center justify-between py-3 border-b border-gray-200 dark:border-gray-700 cursor-pointer"
                onClick={() => handleGradeClick(grade)}
              >
                <div className="flex items-center">
                  <img
                    src={user.photo_url || defaultImage}
                    alt={user.firstName}
                    className="h-10 w-10 rounded-full object-cover mr-3"
                  />
                  <div>
                    <p className="text-gray-900 dark:text-gray-100 font-semibold">{`${user.firstName} ${user.lastName}`}</p>
                    <p className="text-sm text-gray-600 dark:text-gray-400">{assignment?.assignmentName || 'Unknown Assignment'}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  {calculateCompletionCircle(grade.averageResult)}
                  <span
                    className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${gradeComplete
                      ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                      : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300'
                      }`}
                  >
                    {gradeComplete ? 'Completed' : 'Incomplete'}
                  </span>
                  <HiChevronRight className="text-gray-500 dark:text-gray-300" />
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="text-gray-600 dark:text-gray-400">No submissions found.</p>
      )}
    </div>
  );
}
