import React, { useState, useEffect } from 'react';
import { firestore } from '../../firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import Accordion from '../../components/Accordion';
import { useNotification } from '../../contexts/NotificationContext';
import { Breadcrumb, Button } from 'flowbite-react';
import { HiHome } from "react-icons/hi";
import { useNavigate } from 'react-router';
import { TrashIcon } from '@heroicons/react/outline';
import { PlusIcon } from '@heroicons/react/solid';

export default function QuestionnaireCreator() {
  const [questionnaire, setQuestionnaire] = useState(null);
  const { showNotification } = useNotification();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadQuestionnaire = async () => {
      const questionnaireRef = doc(firestore, 'questionaires', id);
      const questionnaireSnap = await getDoc(questionnaireRef);
      if (questionnaireSnap.exists()) {
        setQuestionnaire(questionnaireSnap.data());
      }
    };

    loadQuestionnaire();
  }, [id]);

  const saveQuestionnaire = async () => {
    try {
      const questionnaireRef = doc(firestore, "questionaires", id);
      await updateDoc(questionnaireRef, questionnaire);
      showNotification('success', 'Questionnaire saved!');
    } catch (err) {
      showNotification('danger', 'Questionnaire not saved!');
    }
  };

  const handleQuestionChange = (questionIndex, field, value) => {
    const updatedQuestions = [...questionnaire.questions];
    const updatedQuestion = { ...updatedQuestions[questionIndex], [field]: value };
    updatedQuestions[questionIndex] = updatedQuestion;
    setQuestionnaire({ ...questionnaire, questions: updatedQuestions });
  };

  const handleAnswerChange = (questionIndex, answerIndex, field, value) => {
    const updatedQuestions = [...questionnaire.questions];
    const updatedAnswers = [...updatedQuestions[questionIndex].answers];
    const updatedAnswer = { ...updatedAnswers[answerIndex], [field]: value };
    updatedAnswers[answerIndex] = updatedAnswer;
    updatedQuestions[questionIndex] = { ...updatedQuestions[questionIndex], answers: updatedAnswers };
    setQuestionnaire({ ...questionnaire, questions: updatedQuestions });
  };

  const addNewQuestion = () => {
    const newQuestion = {
      questionTitle: '',
      description: '',
      imageUrl: '',
      videoUrl: '',  // New field for video URL
      answers: [{ answer: '', isCorrect: false }]
    };
    setQuestionnaire(prevState => ({
      ...prevState,
      questions: [...prevState.questions, newQuestion]
    }));
  };

  const addNewAnswer = questionIndex => {
    const newAnswer = { answer: '', isCorrect: false };
    const updatedQuestions = [...questionnaire.questions];
    updatedQuestions[questionIndex].answers.push(newAnswer);
    setQuestionnaire({ ...questionnaire, questions: updatedQuestions });
  };

  const deleteQuestion = (questionIndex) => {
    setQuestionnaire((prevState) => {
      const updatedQuestions = prevState.questions.filter((_, index) => index !== questionIndex);
      return { ...prevState, questions: updatedQuestions };
    });
  };

  const deleteAnswer = (questionIndex, answerIndex) => {
    setQuestionnaire((prevState) => {
      const updatedQuestions = [...prevState.questions];
      updatedQuestions[questionIndex].answers = updatedQuestions[questionIndex].answers.filter((_, index) => index !== answerIndex);
      return { ...prevState, questions: updatedQuestions };
    });
  };

  if (!questionnaire) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dark:bg-gray-800 dark:text-white">
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href='#' onClick={(e) => {
                e.preventDefault();
                navigate("/main/dashboard")
              }}>
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Questionaires</Breadcrumb.Item>
              <Breadcrumb.Item>{questionnaire?.Title}</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              {questionnaire?.Title}
            </h1>
          </div>
          <button
            className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={saveQuestionnaire}
          >
            Save
          </button>
        </div>
      </div>

      <br />

      <div className='container mx-auto p-4 space-y-4'>
        {questionnaire?.questions.map((question, questionIndex) => (
          <div key={questionIndex} className="relative p-4 border rounded shadow dark:border-gray-600">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-semibold">{`Question ${questionIndex + 1}`}</h2>
              <button
                className="p-1 rounded bg-red-500 text-white hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700"
                onClick={() => deleteQuestion(questionIndex)}
              >
                <TrashIcon className="h-5 w-5" />
              </button>
            </div>
            <input
              className="w-full p-2 mb-2 border rounded dark:bg-gray-700 dark:border-gray-600"
              type="text"
              value={question.questionTitle}
              placeholder="Enter question title"
              onChange={(e) => handleQuestionChange(questionIndex, 'questionTitle', e.target.value)}
            />
            <textarea
              className="w-full p-2 mb-2 border rounded dark:bg-gray-700 dark:border-gray-600"
              value={question.description}
              placeholder="Enter question description"
              onChange={(e) => handleQuestionChange(questionIndex, 'description', e.target.value)}
            />
            <input
              className="w-full p-2 mb-2 border rounded dark:bg-gray-700 dark:border-gray-600"
              type="text"
              value={question.videoUrl || ''}
              placeholder="Enter video URL"
              onChange={(e) => handleQuestionChange(questionIndex, 'videoUrl', e.target.value)}
            />
            <div className="mt-4 space-y-2">
              {question.answers.map((answer, answerIndex) => (
                <div key={answerIndex} className="flex items-center space-x-2">
                  <input
                    className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600"
                    type="text"
                    value={answer.answer}
                    placeholder="Enter answer option"
                    onChange={(e) => handleAnswerChange(questionIndex, answerIndex, 'answer', e.target.value)}
                  />
                  <input
                    type="checkbox"
                    checked={answer.isCorrect}
                    className="form-checkbox h-5 w-5 text-blue-600 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                    onChange={(e) => handleAnswerChange(questionIndex, answerIndex, 'isCorrect', e.target.checked)}
                  />
                  <button
                    className="p-2 rounded bg-red-500 text-white hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700"
                    onClick={() => deleteAnswer(questionIndex, answerIndex)}
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              ))}
              <button onClick={() => addNewAnswer(questionIndex)}
                className="flex items-center px-6 py-1.5 border border-gray-400 mt-4 hover:text-white text-gray-600 dark:text-gray-300 rounded text-sm hover:bg-blue-600 dark:hover:bg-blue-700 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                  stroke="currentColor" className="w-6 h-6  mr-2">
                  <path strokeLinecap="round" strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Add Answer
              </button>
            </div>
          </div>
        ))}
        <div className="flex justify-center mt-8 p-4">
          <button onClick={addNewQuestion}
            className="flex items-center px-6 py-1.5 border border-gray-400 mt-4 hover:text-white text-gray-600 dark:text-gray-300 rounded text-sm hover:bg-blue-600 dark:hover:bg-blue-700 mx-auto">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
              stroke="currentColor" className="w-6 h-6  mr-2">
              <path strokeLinecap="round" strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Add New Question
          </button>
        </div>
      </div>
    </div>
  );
}
