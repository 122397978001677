import { auth, firestore } from '../firebase';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'


export async function getUserData(userID) {
  const userRef = doc(firestore, 'users', userID);
  try {
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      return userSnap.data();
    } else {
      throw new Error('User not found.');
    }
  } catch (error) {
    console.error('Error getting user data:', error);
    throw error; // Re-throw the error om door de aanroeper afgehandeld te worden
  }
}

export function useAuthStatus() {
  const [currentUser, setCurrentUser] = useState();
  const [fetchingData, setFetchingData] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
      setFetchingData(false);
    });

    // Deze functie wordt aangeroepen wanneer de component unmount
    return unsubscribe;
  }, []);

  return { currentUser, fetchingData };
}
// Helper function to get the document for a given userID
async function getUserDocument(userID) {
  const userRef = doc(firestore, 'users', userID);
  const userSnap = await getDoc(userRef);
  if (!userSnap.exists()) {
    throw new Error('User not found.');
  }
  return userSnap.data();
}

export async function getEmail(userID) {
  const userData = await getUserDocument(userID);
  return userData.email;
}

export async function getFirstName(userID) {
  const userData = await getUserDocument(userID);
  return userData.firstName;
}

export async function getLastName(userID) {
  const userData = await getUserDocument(userID);
  return userData.lastName;
}

export async function getPhotoURL(userID) {
  const userData = await getUserDocument(userID);
  return userData.photo_url; // Make sure the field name matches with your database
}

export async function getRoleID(userID) {
  const userData = await getUserDocument(userID);
  return userData.roleID;
}

export async function getRoleName(userID) {
  const userData = await getUserDocument(userID);
  return userData.roleName;
}

export async function getSchoolID(userID) {
  const userData = await getUserDocument(userID);
  return userData.schoolID;
}

export async function getSchoolName(userID) {
  const userData = await getUserDocument(userID);
  return userData.schoolName;
}