import React, { useEffect, useState } from 'react';
import { usePopUp } from '../../../contexts/PopUpContext';
import { useAppData } from '../../../contexts/AppDataContext';
import { useCourse } from '../contexts/CourseContext';
import { useUser } from '../../../contexts/UserContext';
import { useNotification } from '../../../contexts/NotificationContext';
import { Accordion, Table } from 'flowbite-react';
import { HiClipboard, HiDocumentText } from "react-icons/hi";  
import { BsTrash } from "react-icons/bs"; 
import CreateModulePopUp from '../components/CreateModule';
import AddAssignmentToModule from '../components/AddAssignmentToModule';
import AddDocumentToModule from '../components/AddDocumentToModule';
import { getFileUrl } from '../../../services/FirebaseService';

const ModulesOverview = ({ handleCreateAssignment, handleUploadDocument }) => {
  const { showPopUp } = usePopUp();
  const { showNotification } = useNotification();
  const { course, addModule, deleteModule, modules, addModuleItem, removeModuleItem, modulesLoading, updateModuleStatus } = useCourse();
  const [courseAssignments, setCourseAssignments] = useState([]);
  const { assignments } = useAppData();
  const user = useUser();

  const [selectedStatus, setSelectedStatus] = useState("Both");
  const [openModules, setOpenModules] = useState({}); // Track which module is open

  useEffect(() => {
    if (course) {
      setCourseAssignments(assignments?.filter(a => course?.assessments?.includes(a.id)));
    }
  }, [course, assignments]);

  // Initialize all modules to be open when the page loads
  useEffect(() => {
    if (modules) {
      const initialOpenState = modules.reduce((acc, module) => {
        acc[module.id] = true; // Set all modules to open
        return acc;
      }, {});
      setOpenModules(initialOpenState);
    }
  }, [modules]);

  const getTitle = (content) => {
    if (content.type === "assignment") {
      return assignments.find(a => a.id === content.item)?.assignmentName;
    }
    if (content.type === "document") {
      return content.item;
    }
  };

  const getIcon = (content) => {
    switch (content.type) {
      case "assignment":
        return <HiClipboard />;
      case "document":
        return <HiDocumentText />;
      default:
        return null;
    }
  };

  const handleAddItem = async (content, moduleId) => {
    if (await addModuleItem(content, moduleId)) {
      showNotification('success', `Added ${content.type} to module`);
    } else {
      showNotification('danger', `${content.type.charAt(0).toUpperCase() + content.type.slice(1)} is already in module!`);
    }
  };

  const handleRemoveModuleItem = async (content, moduleId) => {
    if (window.confirm(`Are you sure you want to remove ${content.type} from ${moduleId}?`)) {
      await removeModuleItem(content, moduleId);
      showNotification('success', `Removed ${content.type} from module`);
    }
  };

  const handleStatusChange = async (moduleId, status) => {
    const newStatus = status === 'published';
    await updateModuleStatus(moduleId, newStatus);  // Update individuele module
  };

  // Toggle open state for each module
  const toggleModuleOpen = (moduleId) => {
    setOpenModules((prevOpenModules) => ({
      ...prevOpenModules,
      [moduleId]: !prevOpenModules[moduleId],
    }));
  };

  // Prevent the dropdown from toggling the Accordion
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const filteredModules = modules?.filter((module) => {
    // Student: Only show published modules
    if (user?.roleName === 'Student') {
      return module.status === true;
    }

    // Non-Student: Show based on selected status (Both, Published, Unpublished)
    if (selectedStatus === 'Both') return true;
    return selectedStatus === 'Published' ? module.status === true : module.status === false;
  });

  const isEditable = (user?.roleName === 'Admin' || (user?.roleName !== 'Student' && course?.id !== '66Fqzp90Cib8lmwCN3QZ'));

  return (
    <div>
      {/* Top Section with "Course Modules", "Collapse All", "Published", "Unpublished", and "Add New Module" */}
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold">Course Modules</h3>
        {isEditable && (
          <div className="flex space-x-4">
            <select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              className="border-gray-300 rounded-md text-sm text-gray-500 px-2"
            >
              <option value="Both">Both</option>
              <option value="Published">Published</option>
              <option value="Unpublished">Unpublished</option>
            </select>
            <button 
              onClick={() => showPopUp(<CreateModulePopUp onCreate={(title, subtitle) => addModule(title, subtitle)} />)}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
              + Add New Module
            </button>
          </div>
        )}
      </div>

      {modulesLoading && <div className="text-center m-10"><div role="status">{/* Loading spinner */}</div></div>}

      {!modulesLoading && (
        <ul className="divide-y divide-gray-200">
          {filteredModules?.length > 0 ? (
            filteredModules.map((module, index) => (
              <Accordion key={index} className="mb-5 border rounded-lg">
                <Accordion.Panel>
                  <Accordion.Title 
                    className="flex justify-between items-center"
                    onClick={() => toggleModuleOpen(module.id)} // Toggle this specific module
                  >
                    <div className="flex items-center space-x-2">
                      <span className="font-semibold text-gray-800">{module.title}</span>
                      <span className="text-gray-500">: {module.subtitle}</span>
                    </div>
                    {isEditable && (
                    <div className="flex space-x-4">
                      <select
                        value={module.status ? 'published' : 'unpublished'}
                        onClick={stopPropagation} // Prevent the dropdown from toggling the accordion
                        onChange={(e) => handleStatusChange(module.id, e.target.value)}
                        className="border-gray-300 rounded-md text-sm text-gray-500"
                      >
                        <option value="published">Published</option>
                        <option value="unpublished">Unpublished</option>
                      </select>

                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the button from toggling the accordion
                          showPopUp(
                            <AddAssignmentToModule
                              course={{ ...course, assessments: courseAssignments }}
                              module={module}
                              addItem={(content) => handleAddItem(content, module.id)}
                              handleCreateAssignment={handleCreateAssignment}
                            />
                          );
                        }}
                        className="text-blue-600 hover:underline text-sm"
                      >
                        + Add Assignment
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the button from toggling the accordion
                          showPopUp(
                            <AddDocumentToModule
                              course={course}
                              module={module}
                              addItem={(content) => handleAddItem(content, module.id)}
                              handleUploadDocument={handleUploadDocument}
                            />
                          );
                        }}
                        className="text-blue-600 hover:underline text-sm"
                      >
                        + Add Document
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the button from toggling the accordion
                          deleteModule(module.id);
                        }}
                        className="text-red-600 hover:underline text-sm"
                      >
                        Delete Module
                      </button>
                    </div>
                    )}
                  </Accordion.Title>
                  {/* Conditionally render Accordion.Content based on openModules state */}
                  {openModules[module.id] && (
                    <Accordion.Content>
                      <Table className="min-w-full bg-white border rounded-lg">
                        <Table.Body className="divide-y">
                          {module.content?.map((content) => {
                            const handleDocumentClick = async (fileName) => {
                              const url = await getFileUrl(`learningMaterials/${course.id}/${fileName}`);
                              if (url) {
                                window.open(url, '_blank');
                              } else {
                                showNotification('danger', 'Error: Unable to open document');
                              }
                            };

                            return (
                              <Table.Row key={content.item} className="bg-white">
                                <Table.Cell className="whitespace-nowrap flex items-center justify-between text-gray-900">
                                  <div className="flex items-center">
                                    <div className="mx-3">{getIcon(content)}</div>
                                    {content.type === 'document' ? (
                                      <button
                                        onClick={() => handleDocumentClick(content.item)}
                                        className="text-blue-600 hover:underline"
                                      >
                                        {getTitle(content)}
                                      </button>
                                    ) : (
                                      <p>{getTitle(content)}</p>
                                    )}
                                  </div>
                                  {isEditable && (
                                  <div className="flex space-x-4">
                                    <button
                                      onClick={() => handleRemoveModuleItem(content, module.id)}
                                      className="text-gray-500 hover:text-red-600 xl"
                                    >
                                      <BsTrash />
                                    </button>
                                  </div>
                              )}
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                    </Accordion.Content>
                  )}
                </Accordion.Panel>
              </Accordion>
            ))
          ) : (
            <div className="text-center py-12">
              <img
                className="w-32 h-32 mx-auto"
                src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg"
                alt="empty modules"
              />
              <p className="text-gray-700 font-medium text-lg">No modules to display.</p>
              <p className="text-gray-500">You can add new modules to the course.</p>
            </div>
          )}
        </ul>
      )}
    </div>
  );
};

export default ModulesOverview;
