import React from 'react';
import { getBezierPath, getMarkerEnd } from 'reactflow';

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  arrowHeadType,
  markerEndId,
}) => {
  if (
    [sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition].some(
      (val) => typeof val === 'undefined'
    )
  ) {
    console.error('Invalid edge parameters:', {
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
    });
    return null;
  }

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        style={{ stroke: '#3b82f6', strokeWidth: 2, strokeDasharray: '5,5' }}
      />
    </>
  );
};

export default CustomEdge;
