// pages/details/CreateSchool.js
import React, { useState, useEffect } from 'react';
import { firestore } from '../../firebase';
import { useNotification } from '../../contexts/NotificationContext';
import { collection, getDocs, doc, setDoc, query, where, getDoc } from '@firebase/firestore';

export default function CreateSchool({ onClose }) {
    const { showNotification } = useNotification();
    const [schoolName, setSchoolName] = useState('');
    const [capacity, setCapacity] = useState('');
    const [licenseExpirationDate, setLicenseExpirationDate] = useState('');
    const [schoolID, setSchoolID] = useState('');
    const [availableScenarios, setAvailableScenarios] = useState([]);
    const [scenarios, setScenarios] = useState([]);

    useEffect(() => {
        const fetchScenarios = async () => {
            const scenariosSnapshot = await getDocs(collection(firestore, 'scenarios'));
            setScenarios(scenariosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        };

        fetchScenarios();
    }, []);

    function formatDate(date) {
        const d = new Date(date);
        let day = d.getDate();
        let month = d.getMonth() + 1; // Maanden zijn van 0-11 in JavaScript
        const year = d.getFullYear();

        if (day < 10) {
            day = '0' + day;
        }

        if (month < 10) {
            month = '0' + month;
        }

        return `${day}/${month}/${year}`;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newSchool = {
            name: schoolName,
            capacity: parseInt(capacity, 10),
            licenseExpirationDate: new Date(licenseExpirationDate),
            availableScenarios,
            numOfMembers: 0,
            creationDate: formatDate(Date.now()),
        };

        try {
            const schoolIDExists = await checkIfSchoolIDExists(schoolID);
            const schoolNameExists = await checkIfSchoolNameExists(schoolName);

            if (schoolIDExists) {
                showNotification('danger', 'School ID already exists.');
                return;
            }

            if (schoolNameExists) {
                showNotification('danger', 'School name already exists.');
                return;
            }

            await setDoc(doc(firestore, "schools", schoolID), newSchool);
            showNotification('success', 'School created successfully!');
            onClose(); // Close the modal
        } catch (error) {
            console.error("Error creating school: ", error);
            showNotification('danger', 'Failed to create school.');
        }
    };

    const checkIfSchoolIDExists = async (id) => {
        const docRef = doc(firestore, "schools", id);
        const docSnap = await getDoc(docRef);
        return docSnap.exists();
    };

    const checkIfSchoolNameExists = async (name) => {
        const q = query(collection(firestore, "schools"), where("name", "==", name));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
    };

    const handleScenarioChange = (scenarioId) => {
        setAvailableScenarios(prevScenarios =>
            prevScenarios.includes(scenarioId)
                ? prevScenarios.filter(id => id !== scenarioId)
                : [...prevScenarios, scenarioId]
        );
    };

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 dark:bg-gray-700 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full dark:bg-gray-800">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 dark:bg-gray-800">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white" id="modal-title">
                                    Create New School
                                </h3>
                                <div className="mt-2">
                                    <form onSubmit={handleSubmit} className="space-y-6">
                                        <div>
                                            <label htmlFor="schoolName" className="block text-sm font-medium text-gray-700 dark:text-gray-200">School Name</label>
                                            <input type="text" id="schoolName" value={schoolName} onChange={(e) => setSchoolName(e.target.value)} required className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-blue-500 block w-full rounded-md sm:text-sm focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-300" />
                                        </div>
                                        <div>
                                            <label htmlFor="capacity" className="block text-sm font-medium text-gray-700 dark:text-gray-200">Capacity</label>
                                            <input type="number" id="capacity" value={capacity} onChange={(e) => setCapacity(e.target.value)} required className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-blue-500 block w-full rounded-md sm:text-sm focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-300" />
                                        </div>
                                        <div>
                                            <label htmlFor="licenseExpirationDate" className="block text-sm font-medium text-gray-700 dark:text-gray-200">License Expiration Date</label>
                                            <input type="date" id="licenseExpirationDate" value={licenseExpirationDate} onChange={(e) => setLicenseExpirationDate(e.target.value)} required className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-blue-500 block w-full rounded-md sm:text-sm focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-300" />
                                        </div>
                                        <div>
                                            <label htmlFor="schoolID" className="block text-sm font-medium text-gray-700 dark:text-gray-200">School ID</label>
                                            <input type="text" id="schoolID" value={schoolID} onChange={(e) => setSchoolID(e.target.value)} required className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-blue-500 block w-full rounded-md sm:text-sm focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-300" />
                                        </div>
                                        <fieldset>
                                            <legend className="text-base font-medium text-gray-900 dark:text-gray-200">Available Scenarios</legend>
                                            <div className="mt-4 grid grid-cols-1 gap-y-4 max-h-48 overflow-y-auto pr-2">
                                                {scenarios.map(scenario => (
                                                    <div key={scenario.id} className="flex items-center">
                                                        <input id={`scenario-${scenario.id}`} name="availableScenarios" type="checkbox" checked={availableScenarios.includes(scenario.id)} onChange={() => handleScenarioChange(scenario.id)} className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded dark:bg-gray-600 dark:border-gray-500" />
                                                        <label htmlFor={`scenario-${scenario.id}`} className="ml-3 text-sm text-gray-600 dark:text-gray-200">{scenario.name}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </fieldset>
                                        <button type="submit" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800">
                                            Create School
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse dark:bg-gray-700">
                        <button type="button" className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm dark:bg-gray-600 dark:text-gray-200 dark:border-gray-500 dark:hover:bg-gray-500" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
