import React, { useState } from 'react';
import { Dropdown } from 'flowbite-react';

export const getEnumKeyByValue = (enumObj, value) => {
    return Object.keys(enumObj).find(key => enumObj[key] === value);
};

const EnumDropdown = ({ enumObj, label }) => {
    const [selectedKey, setSelectedKey] = useState(null);

    const handleSelect = (value) => {
        const key = getEnumKeyByValue(enumObj, value);
        setSelectedKey(key);
        //console.log(value);
    };

    return (
        <div className="container mx-auto my-8 p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
            <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                {label}
            </label>
            <Dropdown label={selectedKey || "Select an option"} inline={true} className="">
                {Object.keys(enumObj).map((key) => (
                    <Dropdown.Item key={key} onClick={() => handleSelect(enumObj[key])} className="text-gray-700 dark:text-gray-300">
                        {key}
                    </Dropdown.Item>
                ))}
            </Dropdown>
        </div>
    );
};

export default EnumDropdown;
