import React, { useEffect, useState } from 'react';
import { useUser } from '../../../contexts/UserContext';
import { useCourse } from '../contexts/CourseContext';
import { useAppData } from '../../../contexts/AppDataContext';
import { useNotification } from '../../../contexts/NotificationContext';
import { usePopUp } from '../../../contexts/PopUpContext';
import { useNavigate } from 'react-router-dom';
import { Flowbite, Dropdown, DropdownItem } from 'flowbite-react';
import StartSimPopUp from '../../assesments/components/StartSimPopUp';
import { saveTemplateFirebase, deleteAssignmentFirebase } from '../../../services/FirebaseService';

const customTheme = {
    dropdown: {
        "arrowIcon": "ml-2 h-4 w-4",
        "content": "py-1 focus:outline-none",
        "floating": {
            "animation": "transition-opacity",
            "arrow": {
                "base": "absolute z-10 h-2 w-2 rotate-45",
                "style": {
                    "dark": "bg-gray-800 dark:bg-gray-700",
                    "light": "bg-white",
                    "auto": "bg-white dark:bg-gray-700"
                },
                "placement": "-4px"
            },
            "base": "z-10 w-fit rounded divide-y divide-gray-100 shadow focus:outline-none",
            "content": "py-1 text-sm text-gray-900 dark:text-white",
            "divider": "my-1 h-px bg-gray-100 dark:bg-gray-700",
            "header": "block py-2 px-4 text-sm text-gray-900 dark:text-white",
            "hidden": "invisible opacity-0",
            "item": {
                "container": "",
                "base": "flex items-center justify-start py-2 px-4 text-sm text-gray-900 cursor-pointer w-full hover:bg-gray-50 focus:bg-gray-50 dark:text-white dark:hover:bg-gray-600 focus:outline-none dark:hover:text-white dark:focus:bg-gray-600 dark:focus:text-white",
                "icon": "mr-2 h-4 w-4"
            },
            "style": {
                "dark": "bg-gray-800 text-white dark:bg-gray-700",
                "light": "bg-white text-gray-900",
                "auto": "bg-white text-gray-900 dark:bg-gray-700 dark:text-white"
            },
            "target": "w-fit"
        },
        "inlineWrapper": "flex items-center"
    }
};

const AssignmentsTable = ({ handleCreateAssignment }) => {
    const user = useUser();
    const [courseAssignments, setCourseAssignments] = useState([]);
    const { course } = useCourse();
    const { assignments } = useAppData();

    // Check if the current course is the introduction course and the user's role is not Admin
    const isEditable = (user?.roleName === 'Admin' || (user?.roleName !== 'Student' && course?.id !== '66Fqzp90Cib8lmwCN3QZ'));

    useEffect(() => {
        if (course) {
            setCourseAssignments(assignments?.filter(a =>
                course?.assessments?.includes(a.id) && !a.isDeleted));
        }
    }, [course, assignments]);

    return (
        <section className="bg-gray-50 w-full dark:bg-gray-900 my-10">
            <div className="w-full">
                <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                    <h3 className="p-4 text-xl font-semibold whitespace-nowrap dark:text-white">Assignments</h3>
                    <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                        <div className="w-full md:w-1/2">
                            <form className="flex items-center">
                                <label htmlFor="simple-search" className="sr-only">Search</label>
                                <div className="relative w-full">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                    <input type="text" id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" required="" />
                                </div>
                            </form>
                        </div>
                        <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                            {isEditable && (
                                <button onClick={handleCreateAssignment} type="button" className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                                    <svg className="h-3.5 w-3.5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path clipRule="evenodd" fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" />
                                    </svg>
                                    Add assignment
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-4 py-3">Assignment name</th>
                                    <th scope="col" className="px-4 py-3">Due</th>
                                    <th scope="col" className="px-4 py-3">{user?.roleName === "Teacher" ? "Submissions" : "Status"}</th>
                                    <th scope="col" className="px-4 py-3">
                                        <span className="sr-only">Actions</span>
                                    </th>
                                </tr>
                            </thead>
                            {courseAssignments.length > 0 ? (
                                <tbody>
                                    {courseAssignments.map((assignment) => (
                                        <AssignmentCard key={assignment.id} assignment={assignment} />
                                    ))}
                                </tbody>
                            ) : (
                                <tbody className="divide-y items-center justify-center divide-gray-200 dark:divide-gray-700">
                                    <tr>
                                        <td className="w-full text-center mx-auto py-12" colSpan="5">
                                            <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg" alt="image empty states" />
                                            <p className="text-gray-700 dark:text-white font-medium text-lg text-center">No assignments to display.</p>
                                            <p className="text-gray-500 dark:text-gray-400 text-center">You can create new assignments for this course.</p>
                                            {isEditable && (
                                                <button onClick={handleCreateAssignment} className="flex items-center px-4 py-1.5 border border-gray-400 mt-4 hover:text-white text-gray-600 dark:text-gray-300 rounded text-sm hover:bg-blue-600 dark:hover:bg-blue-700 mx-auto">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                    Create New Assignment
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                    <nav className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4" aria-label="Table navigation">
                        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                            Showing
                            <span className="font-semibold text-gray-900 dark:text-white"> {courseAssignments.length} </span>
                            of
                            <span className="font-semibold text-gray-900 dark:text-white"> {courseAssignments.length}</span>
                        </span>
                    </nav>
                </div>
            </div>
        </section>
    );
};

const AssignmentCard = ({ assignment }) => {
    const navigate = useNavigate();
    const { showNotification } = useNotification();
    const { setTemplates, grades, assignments, setAssignments } = useAppData();
    const user = useUser();
    const { course } = useCourse();
    const { showPopUp } = usePopUp();
    const [numGrades, setNumGrades] = useState(0);
    const isEditable = (user?.roleName === 'Admin' || (user?.roleName !== 'Student' && course?.id !== '66Fqzp90Cib8lmwCN3QZ'));

    useEffect(() => {
        if (grades) {
            const count = Object.values(grades).reduce((acc, userGrades) => {
                if (userGrades?.length > 0) {
                    const test = userGrades.reduce((sum, grade) => {
                        sum += (grade.assessmentID === assignment.id ? 1 : 0);
                        return sum;
                    }, 0);
                    return acc + test;
                }
                return acc;
            }, 0);
            setNumGrades(count);
        }
    }, [grades, assignment.id]);

    const saveTemplate = async () => {
        const templateId = await saveTemplateFirebase(assignment, user?.id);
        setTemplates(prev => [...prev, { ...assignment, id: templateId }]);
        showNotification('success', 'Assignment saved as template');
    };

    const onClose = () => showPopUp(null);

    const startAssignment = () => {
        showPopUp(<StartSimPopUp onClose={onClose} navigate={navigate} assessment={assignment} />);
    };

    const showSubmission = () => {
        navigate("/main/gradingdetails/" + grades[user?.id].find(g => g.assessmentID === assignment.id).id);
    };

    const handleDeleteAssignment = async () => {
        const confirmation = window.confirm(`Are you sure you want to delete "${assignment.assignmentName}"?`);
        if (confirmation) {
            try {
                await deleteAssignmentFirebase(course.id, assignment.id);
                setAssignments(prev => prev.filter(a => a.id !== assignment.id));
                showNotification('success', 'Assignment deleted successfully');
            } catch (error) {
                console.error('Error deleting assignment:', error);
                showNotification('error', 'Failed to delete the assignment');
            }
        }
    };

    const date = assignment.dueDate?.toDate();
    const formattedDateTime = date?.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
        + ' ' + date?.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

    return (
        <tr className="border-b dark:border-gray-700">
            <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{assignment.assignmentName}</th>
            <td className="px-4 py-3">{formattedDateTime ?? "Due unknown"}</td>
            <td className="px-4 py-3">
                {user?.roleName !== "Student" ? numGrades : (
                    numGrades > 0 ? (
                        <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Submitted</span>
                    ) : (
                        <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">Not submitted</span>
                    )
                )}
            </td>
            <td className="px-4 py-3 flex items-center justify-end">
                <Flowbite theme={customTheme}>
                    <Dropdown label="Actions" inline>
                        {isEditable ? (
                            <>
                                <DropdownItem onClick={() => navigate(`/main/assessmentDetails/${assignment.id}`)}>
                                    View submissions
                                </DropdownItem>
                                {/* <DropdownItem onClick={saveTemplate}>Save as template</DropdownItem> */}
                                <DropdownItem onClick={startAssignment}>Start assignment</DropdownItem>
                                <DropdownItem onClick={handleDeleteAssignment}>Delete</DropdownItem>
                            </>
                        ) : (
                            numGrades === 0 ? (
                                <DropdownItem onClick={startAssignment}>Start assignment</DropdownItem>
                            ) : (
                                <DropdownItem onClick={showSubmission}>View submission</DropdownItem>
                            )
                        )}
                    </Dropdown>
                </Flowbite>
            </td>
        </tr>
    );
};

export default AssignmentsTable;
