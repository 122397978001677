export const getEnumKeyByValue = (enumObj, value) => {
    return Object.keys(enumObj).find(key => enumObj[key] === value);
};

export const prettyPrintEnum = (value) => {
    const result = value.replace(/([A-Z])/g, ' $1').trim();
    return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
}

export const InteractableType = {
    Stethoscope: 0,
    WaterCup: 1,
    MARScanner: 2,
    Penlight: 3,
    Bloodcuff: 4,
    ECGLeads: 5,
    PulseOximeter: 6,
    Glucsoemeter: 7,
    OralThermometer: 8,
    LabCollection: 9,
    Clipboard: 10,
}

export const NodeTypes = {
    EventNode: 0,
    EffectNode: 1,
    CheckNode: 2,
    DelayNode: 3
};

export const EventTypes = {
    SimulationStarted: 0,
    WhenMedicationAdministered: 1,
    WhenIvAdministered: 2,
    WhenOxygenAdminstered: 3,
    WhenBloodPressureTaken: 4,
    WhenTemperatureTaken: 5,
    WhenGlucoseTaken: 6,
    WhenDoctorCalled: 7,
    WhenLabCollected: 8,
    WhenPatientRepositioned: 9,
    WhenUserTalked: 10,
    WhenSimulationSubmitted: 11,
    WhenRestraintsApplied: 12,
    WhenRestraintsRemoved: 13,
    WhenAssessmentPerformed: 14,
    WhenUserWashedHands: 15,
    WhenGlovesApplied: 16
};

export const AssessmentType = {
    AuscultatingLungs: 0,
    AuscultatingHeartSounds: 1,
    AuscultatingBowelSounds: 2,
    PalpatingPulses: 3,
    PalpatingAbdomen: 4,
    HandsNihssClipboard: 5,
    UsesPenlight: 6,
    MeasuresFundalHeight: 7,
    MeasuresFetalHeartTones: 8,
    MassagesUterineFundus: 9,
    PalpatesLowerExtremities: 10,
    OpensLabResults: 11,
    OpensEmrNotes: 12,
    OpensOrders: 13
}

export const PatientPositionTypes = {
    Fowlers: 0,
    SemiFowlers: 1,
    Supine: 2,
    HighFowlers: 3
}

export const EffectTypes = {
    ChangeVital: 0,
    NewLabResult: 1,
    ChangePhysiology: 2,
    ChangeBehavior: 3,
    SetCustomField: 4,
    InworldActions: 5,
    NewOrder: 6,
    NewPhoneCall: 7,
    Grade: 8,
    NewCondition: 9,
}

export const CheckTypes = {
    IfVitalIs: 0,
    IfIvBeingGiven: 1,
    IfMedicationWasGiven: 2,
    IfOxygenBeingGiven: 3,
    IfPatientIsPositioned: 4,
    IfLabWasCollected: 5,
    IfIvPushedInTime: 6,
    IfCustomFieldIs: 7,
    IfIvWasChangedBy: 8,
    IfUserSaid: 9,
    IfBehaviorIs: 10,
    IfDoctorCalled: 11,
    IfAssessmentPerformed: 12
}

export const InworldActions = {
    Say: 0,
    Instruction: 1,
    CustomTrigger: 2
}

export const InworldTarget = {
    Patient: 0,
    Doctor: 1,
    Family: 2
}

export const PhysiologySystems = {
    Neurological: 0,
    Pulmonary: 1,
    Cardiac: 2,
    Gastrointestinal: 3,
    Integumentary: 4,
    Musculoskeletal: 5,
    Maternity: 6
}

export const NeurologicalFindings = {
    PupilShape: 0,
    PupilReaction: 1,
    EyeGaze: 2,
    EyeSclera: 3,
    FacialSymmetry: 4,
    MotorFunction: 5,
    Ataxia: 6,
    SensoryFunction: 7,
    Speech: 8
}

export const MaternityFindings = {
    FundalHeight: 0,
    FetalHeartRate: 1,
    VaginalHemorrhage: 2
}

export const Speech = {
    Normal: 0,
    Slurred: 1,
    Incomprehensible: 2
}

export const EyeSclera = {
    Normal: 0,
    Jaundice: 1,
    Redness: 2
}

export const EyeGaze = {
    Normal: 0,
    LeftGazeNeglect: 1,
    RightGazeNeglect: 2,
    CompleteGazeDeviation: 3
}

export const PupilReaction = {
    Brisk: 0,
    Sluggish: 1,
    NonReactive: 2
}

export const PupilShape = {
    Normal: 0,
    Dilated: 1,
    Irregular: 2,
    Pinpoint: 3
}

export const DirectionType = {
    Left: 0,
    Right: 1
}

export const SensoryFunction = {
    Normal: 0,
    Decreased: 1
}

export const Ataxia = {
    Normal: 0,
    Ataxia: 1
}

export const PatientExtremity = {
    LeftArm: 0,
    RightArm: 1,
    LeftLeg: 2,
    RightLeg: 3,
}

export const MotorFunction = {
    NoDrift: 0,
    Drift: 1,
    SomeEffortAgainstGravity: 2,
    NoMovement: 3
}

export const FacialSymmetry = {
    Normal: 0,
    RightFacialDroop: 1,
    LeftFacialDroop: 2,
    TongueDeviationLeft: 3,
    TongueDeviationRight: 4,
    CompleteFacialParalysis: 5
}

export const SensoryLocations = {
    LeftFacial: 0,
    RightFacial: 1,
    LeftArm: 2,
    RightArm: 3,
    LeftFoot: 4,
    RightFoot: 5
}

export const PulmonaryFindings = {
    ChestShape: 0,
    RespiratoryPattern: 1,
    ChestExpansion: 2,
    LungAuscultation: 3,
    Drooling: 4
}

export const CardiacFindings = {
    HeartAuscultation: 0,
    HeartRhythm: 1,
    Pulses: 2,
    Edema: 3,
}

export const GastrointestinalFindings = {
    BowelSounds: 0,
    AbdominalPalpation: 1,
    AbdominalContour: 2,
}

export const IntegumentaryFindings = {
    GeneralizedRashes: 0,
    Wounds: 1,
    DeepVeinThrombosis: 2
}

export const DeepVeinThrombosis = {
    LeftLeg: 0,
    RightLeg: 1
}

export const MusculoskeletalFindings = {
    HandGrip: 0,
    FootPlantarFlexion: 1,
    FootDorsalFlexion: 2,
    Clubbing: 3,
}

export const StrengthTypes = {
    Strong: 0,
    Weak: 1,
    Absent: 2
}

export const ClubbingTypes = {
    No: 0,
    Yes: 1
}

export const AbdominalContourTypes = {
    Flat: 0,
    Distended: 1,
    Obese: 2,
    Scaphoid: 3,
    Pregnant: 4
}

export const AbdominalPalpation = {
    NonTender: 0,
    Tender: 1,
    Soft: 2,
    Hard: 3,
    LiverDistension: 4,
    NonDistendedLiver: 5
}

export const BowelSounds = {
    Normoactive: 0,
    Hyperactive: 1,
    Hypoactive: 2,
    Absent: 3
}

export const BowelSoundFields = {
    RightUpperQuadrant: 0,
    LeftUpperQuadrant: 1,
    RightLowerQuadrant: 2,
    LeftLowerQuadrant: 3
}

export const RashTypes = {
    NoRash: 0,
    Sweaty: 1,
    Pale: 2,
    Hives: 3,
    Bruising: 4
}

export const WoundTypes = {
    NoWound: 0,
    Stage1: 1,
    Stage2: 2,
    Stage3: 3,
    Stage4: 4
}

export const WoundSites = {
    Head: 0,
    Shoulder: 1,
    Sacrum: 2,
    Buttock: 3,
    Heel: 4
}

export const LabTests = {
    Cbc: 0,
    Cmp: 1,
    Bnp: 2,
    BloodCultures: 3,
    Lactate: 4,
    LipidPanel: 5,
    Urinalysis: 6,
    Lfts: 7,
    Troponin: 8,
    Potassium: 9,
    Phosphorus: 10,
    Magnesium: 11,
    Calcium: 12,
    Sodium: 13,
    RenalPanel: 14,
    Esr: 15,
    Crp: 16,
    PtInr: 17,
    Aptt: 18,
    SerumOsmolality: 19,
    AnionGap: 20,
    Procalcitonin: 21,
    ThyroidFunctionPanel: 22,
    BloodGlucosePoc: 23,
    ArterialBloodGas: 24,
};

export const MedicationTypes = {
    Epinephrine: 0,
    Amiodarone: 1,
    Dopamine: 2,
    NitroGlycerin: 3,
    Heparin: 4,
    Streptokinase: 5,
    Saline: 6,
    Lisinopril: 7,
    Losartan: 8,
    Acetaminophen: 9,
    Labetalol: 10,
    Metoprolol: 11,
    Hydralazine: 12,
    Carvedilol: 13,
    Quetiapine: 14,
    Midazolam: 15,
    Digoxin: 16,
    Atropine: 17,
    Furosemide: 18,
    Tramadol: 19,
    Flumazenil: 20,
    Naloxone: 21,
    Amlodipine: 22,
    Midodrine: 23,
    FluVaccine: 24,
    RegularInsulin: 25,
    LactatedRingers: 26,
    DextroseInWater_5Percent: 27,
    DextroseInWater_10Percent: 28,
    Saline_3Percent: 29,
    Norepinephrine: 30,
    Nicardipine: 31,
    Dexmedetomidine: 32,
    Meropenem: 33,
    Piperacillin: 34,
    Vancomycin: 35,
    PotassiumChloride: 36,
    MagnesiumSulfate: 37,
    Dobutamine: 38,
    Vasopressin: 39,
    Phenylephrine: 40,
    Diltiazem: 41,
    Propofol: 42,
    Micafungin: 43,
    Pantoprazole: 44,
    Albumin: 45,
    Nitroprusside: 46,
    Lorazepam: 47,
    Morphine: 48,
    Vecuronium: 49,
    Fentanyl: 50,
    Nitroglycerine: 51,
    Captopril: 52,
    Ibuprofen: 53,
    Atorvastatin: 54,
    Metformin: 55,
    Methylprednisolone: 56,
    Loratadine: 57,
    Aspirin: 58,
    Warfarin: 59,
    Metronidazole: 60,
    Diphenhydramine: 61,
    Saline_045Percent: 62,
    Amiodarone_Bolus: 63,
    Amiodarone_Maintenance: 64,
    Ciprofloxacin: 65,
	InsulinLevemir: 66,
	DocusateSodium: 67,
	Ticagrelor: 68,
	Aripiprazole: 69,
	Haloperidol: 70,
	Lithium: 71,
	Sertraline: 72,
	Olanzapine: 73,
	Lactulose: 74,
	Oxytocin: 75,
	Methylergonovine: 76,
	Butorphanol: 77,
	Betamethasone: 78,
	Indomethacin: 79,
	Penicillin: 80,
	Alendronate: 81,
	Ceftriaxone: 82,
	Azithromycin: 83,
	Ivacaftor: 84,
	Prednisone: 85,
    Amantadine: 86,
    Amoxicillin: 87
};

export const MedicationRoute = {
    Oral: 0,
    IvPush: 1,
    Subcutaneous: 2,
    Intramuscular: 3
}

export const MedicationRouteInclIVBag = {
    Oral: 0,
    IvPush: 1,
    Subcutaneous: 2,
    Intramuscular: 3,
    IvBag: 4
}

export const MedicationUnit = {
    mg: 0,
    mgMl: 1,
    units: 2,
    dose: 3,
    ml: 4,
    mcgMl: 5,
    gMl: 6,
    meqMl: 7,
    unitsMl: 8,
    grams: 9
}

export const TitrationUnit = {
    mlH: 0,
    mcgMin: 1,
    mgHr: 2,
    mcgKgHr: 3,
    mcgKgMin: 4,
    unitsMin: 5,
    unitsHr: 6,
    mcgHr: 7
}

export const BehaviourTypes = {
    Normal: 0,
    Coughing: 1,
    ShortnessOfBreath: 2,
    Epiglottitis: 3,
    Panic: 4,
    Stridor: 5,
    ChestPain: 6,
    Drowsy: 7,
    LightSedation: 8,
    ModerateSedation: 9,
    DeepSedation: 10,
    CannotBeAroused: 11,
    Restless: 12,
    Agitated: 13,
    VeryAgitated: 14,
    Combative: 15,
    AbdominalPain: 16,
    LaborPain: 17,
    Delirium: 18
}

export const VitalTypes = {
    HeartRate: 0,
    SystolicPressure: 1,
    DiastolicPressure: 2,
    RespitoryRate: 3,
    OxygenSaturation: 4,
    Temperature: 5,
    BloodGlucose: 6,
    UrineOutput: 7
}

export const LungSoundFields = {
    L1: 0,
    L2: 1,
    L3: 2,
    L4: 3,
    L5: 4,
    L6: 5,
    R1: 6,
    R2: 7,
    R3: 8,
    R4: 9,
    R5: 10,
    R6: 11
}

export const LungSounds = {
    Clear: 0,
    Wheezing: 1,
    Rhonchi: 2,
    CourseCrackles: 3,
    FineCrackles: 4,
    PleuralRub: 5,
    Diminished: 6,
    Stridor: 7,
    Absent: 8
};

export const RespiratoryPattern = {
    Normal: 0,
    KussmaulRespirations: 1,
    CheyneStokes: 2
}

export const ChestShape = {
    Normal: 0,
    BarrelChest: 1,
    Scoliosis: 2,
    PectusCarinatum: 3,
    PectusExcavatum: 4,
    Kyphosis: 5
}

export const ChestExpansion = {
    Normal: 0,
    LeftDecreased: 1,
    RightDecreased: 2
}

export const Edema = {
    NoEdema: 0,
    PlusOne: 1,
    PlusTwo: 2,
    PlusThree: 3,
    PlusFour: 4
};

export const HeartSoundFields = {
    Aortic: 0,
    Pulmonic: 1,
    ErbsPoint: 2,
    Tricuspid: 3,
    Mitral: 4
}

export const HeartSounds = {
    S1S2: 0,
    S3: 1,
    S4: 2,
    HolosystolicMurmur: 3,
    MitralValveProlapse: 4,
    Absent: 5,
};

export const HeartRhythms = {
    NormalSinusRhythm: 0,
    SinusArrhythmia: 1,
    PrematureAtrialComplex: 2,
    AtrialFibrillation: 3,
    AtrialFlutter: 4,
    SupraventricularTachycardias: 5,
    JunctionalRhythm: 6,
    PrematureVentricularComplex: 7,
    VentricularTachycardia: 8,
    VentricularFibrillation: 9,
    IdioventricularRhythm: 10,
    Asystole: 11,
    FirstDegreeAvBlock: 12,
    SecondDegreeAvBlockType1: 13,
    SecondDegreeAvBlockType2: 14,
    ThirdDegreeAvBlock: 15,
    StElevation: 16
};

export const Pulses = {
    Strong: 0,
    Weak: 1,
    Absent: 2
};

export const PulseFields = {
    ApicalImpulse: 0,
    CarotidArteryLeft: 1,
    BrachialArteryLeft: 2,
    RadialArteryLeft: 3,
    FemoralArteryLeft: 4,
    PoplitealArteryLeft: 5,
    PosteriorTibialArteryLeft: 6,
    DorsalisPedisArteryLeft: 7,
    CarotidArteryRight: 8,
    BrachialArteryRight: 9,
    RadialArteryRight: 10,
    FemoralArteryRight: 11,
    PoplitealArteryRight: 12,
    PosteriorTibialArteryRight: 13,
    DorsalisPedisArteryRight: 14
}

export const OrderTypes = {
    Nursing: 0,
    Medications: 1,
    Diet: 2,
    Lab: 3,
    Imaging: 4,
    Activity: 5
}

export const GradeCategory = {
    Safety: 0,
    Communication: 1,
    Accuracy: 2
}

export const OxygenType = {
    NasalCannula: 0,
    SimpleFaceMask: 1,
    NonRebreather: 2,
}