import React, { createContext, useState, useContext } from 'react';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]); // Currently visible notifications
    const [processingNotification, setProcessingNotification] = useState(false); // Show processing notification

    const MAX_VISIBLE_NOTIFICATIONS = 4; // Limit to 4 visible notifications

    const showNotification = (type, message) => {
        const id = new Date().getTime(); // Unique ID for each notification
        const newNotification = { id, type, message };

        setNotifications((prev) => {
            if (prev.length >= MAX_VISIBLE_NOTIFICATIONS) {
                // If there are already 4 notifications, discard and show processing notification
                if (!processingNotification) {
                    setProcessingNotification(true); // Show "All notifications are being processed"
                }
                return prev; // Do not add the new notification
            }
            return [...prev, newNotification]; // Add the notification if below limit
        });
    };

    const hideNotification = (id) => {
        setNotifications((prev) => {
            const updatedNotifications = prev.filter((notif) => notif.id !== id);
            
            // When all notifications are gone, hide the processing notification
            if (updatedNotifications.length === 0) {
                setProcessingNotification(false);
            }
            return updatedNotifications;
        });
    };

    return (
        <NotificationContext.Provider value={{ showNotification, hideNotification, notifications, processingNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};
