import React, { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import PDFViewer from '../../../components/PDFViewer';
import { useAssessment } from '../contexts/AssessmentContext';
import { updateDoc, getDoc, doc, collection } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useGrade } from '../../grades/contexts/GradingContext';
import { useAppData } from '../../../contexts/AppDataContext';
import { useNotification } from '../../../contexts/NotificationContext';
import { firestore } from '../../../firebase';
import { useUser } from '../../../contexts/UserContext';
import { fetchDataList, fetchDocumentIds } from '../../../services/FirebaseService';
import { gradesCollection, assignmentCollection } from '../../../firebase';

const Reflection = ({ onContinue, data, updateData }) => {
  const [answers, setAnswers] = useState(data || []);
  const { scenario } = useAssessment();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { grade } = useGrade();
  const { grades, setGrades, assignments, setAssignments, courses } = useAppData();
  const user = useUser();

  useEffect(() => {
    if (scenario && answers.length === 0) {
      const emptyAnswers = Array(scenario.reflection?.length).fill("");
      setAnswers(emptyAnswers);
    }
  }, [scenario]);

  useEffect(() => {
    updateData(answers);
  }, [answers, updateData]);

  const refreshData = async () => {
    const updatedGrades = await fetchDataList(await fetchDocumentIds(gradesCollection), gradesCollection);
    setGrades(updatedGrades);

    const assignmentsArr = (await Promise.all(
      courses.map(async (course) => await fetchDataList(course.assessments, assignmentCollection))
    )).flat();
    setAssignments(assignmentsArr);
  };

  const handleConfirm = async () => {
    if (answers.includes("") || answers.some(answer => answer.trim() === "")) {
      showNotification('warning', 'Please fill in all the answers before finishing.');
      return;
    }

    const userDocRef = doc(firestore, 'users', user.id);
    const userDoc = await getDoc(userDocRef);
    const gradesArray = userDoc.data()?.grades || [];

    if (gradesArray.length > 0) {
      const lastGradeId = gradesArray[gradesArray.length - 1];
      const gradeRef = doc(firestore, 'grades', lastGradeId);

      await updateDoc(gradeRef, {
        reflection: answers,
        submissionDate: new Date().toISOString(),
      });

      await updateDoc(userDocRef, {
        startedAssignment: "",
      });

      let oldGrades = { ...grades };
      oldGrades[user.id] = [...oldGrades[user.id] ?? [], { ...grade, id: lastGradeId }];
      setGrades(oldGrades);

      await refreshData();

      showNotification('success', 'Assignment completed!');
      navigate("/main/dashboard");
    }
  };

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
  };

  return (
    <section className={'bg-white dark:bg-gray-900'}>
      <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 sm:py-16 lg:px-6">
        <div className="mt-4 md:mt-0">
          <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Reflection
          </h2>
          <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
            Time to reflect on the simulation and your experience.
          </p>
        </div>
        {scenario?.reflection?.map((item, index) => (
          <div className="max-w-2xl mx-auto px-4" key={index}>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">{item}</h2>
            </div>
            <form className="mb-6">
              <div className="py-2 px-4 mb-4 bg-white rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                <label htmlFor="comment" className="sr-only">Your comment</label>
                <textarea
                  id="comment"
                  rows="6"
                  onChange={(e) => handleAnswerChange(index, e.target.value)}
                  value={answers[index]}
                  className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                  placeholder="Your answer..."
                  required
                />
              </div>
            </form>
          </div>
        ))}
        <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
          <button
            onClick={handleConfirm}
            className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
          >
            Finish
          </button>
        </div>
      </div>
    </section>
  );
};

export default Reflection;
