import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiHome } from 'react-icons/hi';
import { useData } from '../../contexts/DashboardContext';
import SkeletonLoader from '../../components/SkeletonLoader';
import LatestSubmissions from '../../data/LatestSubmissions';
import CourseAnalyticsGraph from '../../data/CourseAnalyticsGraph';
import UpcomingAssignments from '../../data/UpcomingAssignments';
import ProgressionGraph from '../../data/ProgressionGraph';
import SalesCalculator from './components/SalesCalculator';
import { useUser } from '../../contexts/UserContext';
import AssessmentCard from '../assesments/components/AssesmentCard';
import { useAppData } from '../../contexts/AppDataContext';

export default function Dashboard() {
  const { courses, rolesCount, snapshotExists, isLoading } = useData();
  const { assignments, grades, scenarios, users } = useAppData();
  const currentUser = useUser();
  const [inProgressAssignments, setInProgressAssignments] = useState([]);

  useEffect(() => {
    if (assignments && grades && scenarios && courses && users && currentUser) {
      const userGrades = grades[currentUser.id] || [];
  
      const inProgress = assignments
        .filter(assignment => {
          const completedGrade = userGrades.find(grade => grade.assessmentID === assignment.id);
          return completedGrade && !completedGrade.submissionDate;
        })
        .map(assignment => {
          const matchingScenario = scenarios.find(scenario => scenario.id === assignment.scenarioId);
          const matchingCourse = courses.find(course => course.id === assignment.courseId);
          const matchingTeacher = users.find(user => user.id === assignment.creatorId);
  
          return {
            ...assignment,
            scenarioName: matchingScenario ? matchingScenario.name : 'Unknown Scenario',
            scenarioPicture: matchingScenario ? matchingScenario.scenarioPicture : 'Default Image',
            courseName: matchingCourse ? matchingCourse.className : 'Unknown Course',
            teacherName: matchingTeacher ? matchingTeacher.lastName : 'Unknown Teacher',
          };
        });
  
      setInProgressAssignments(inProgress);
    }
  }, [assignments, grades, scenarios, courses, users, currentUser]);

  if (isLoading || !snapshotExists) {
    return <SkeletonLoader type="card" />;
  }

  if (!currentUser || !currentUser.roleName) {
    return <div>No role information available</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
      <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6">
        <div className="flex items-center mb-8">
          <HiHome className="text-3xl text-blue-600 dark:text-blue-400" />
          <h1 className="ml-4 text-3xl font-bold text-gray-900 dark:text-white">Dashboard</h1>
        </div>

        {/* Admin-specific content */}
        {currentUser.roleName === 'Admin' && (
          <>
          {inProgressAssignments.length > 0 && (
              <div className="mt-8">
                <h2 className="text-2xl font-bold text-red-600 dark:text-red-400 mb-4">You have assignments in progress!</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {inProgressAssignments.map((assignment) => (
                    <AssessmentCard
                      key={assignment.id}
                      assessment={assignment}
                      courseName={assignment.courseName || 'Unknown Course'}
                      teacherName={assignment.teacherName || 'Unknown Teacher'}
                      scenarioName={assignment.scenarioName || 'Unknown Scenario'}
                      completedGrade={grades[currentUser.id]?.find(grade => grade.assessmentID === assignment.id)} // Geef grade door
                    />
                  ))}
                </div>
              </div>
            )}
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
              {/* Make ProgressionGraph full width */}
              <div className="col-span-1 md:col-span-2 xl:col-span-3">
                <ProgressionGraph />
              </div>
              <div className="col-span-1 md:col-span-2 xl:col-span-3">
            <CourseAnalyticsGraph />
          </div>
              <TotalItems title="Total Users" total={rolesCount.admin + rolesCount.student + rolesCount.teacher} />
              <TotalItems title="Total Pending Registrations" total={rolesCount.totalPendingUsers || 0} />
              <TotalItems title="Total Courses" total={courses.length} />
              <TotalItems title="Total Schools" total={rolesCount.totalSchools || 0} />
              <TotalItems title="Total Grades" total={rolesCount.totalGrades || 0} />
              <TotalItems title="Total Assessments" total={rolesCount.totalAssessments || 0} />
              <TotalItems title="Total Modules" total={rolesCount.totalModules || 0} />
              <TotalItems title="Total Scenarios" total={rolesCount.totalScenarios || 0} />
            </div>

            {/* Flexbox container for UpcomingAssignments and LatestSubmissions */}
            <div className="flex flex-wrap mt-6">
              <div className="w-full md:w-1/2 p-2">
                <UpcomingAssignments limit={20} />
              </div>
              <div className="w-full md:w-1/2 p-2">
                <LatestSubmissions limit={35} />
              </div>
            </div>
          </>
        )}

       

        {/* Teacher and Student-specific content */}
        {['Teacher', 'Student'].includes(currentUser.roleName) && (
          <>
            {inProgressAssignments.length > 0 && (
              <div className="mt-8">
                <h2 className="text-2xl font-bold text-red-600 dark:text-red-400 mb-4">You have assignments in progress!</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {inProgressAssignments.map((assignment) => (
                    <AssessmentCard
                      key={assignment.id}
                      assessment={assignment}
                      courseName={assignment.courseName || 'Unknown Course'}
                      teacherName={assignment.teacherName || 'Unknown Teacher'}
                      scenarioName={assignment.scenarioName || 'Unknown Scenario'}
                      completedGrade={grades[currentUser.id]?.find(grade => grade.assessmentID === assignment.id)} // Geef grade door
                    />
                  ))}
                </div>
              </div>
            )}
             {/* Shared content for all roles (Admin, Teacher, Student) */}
        <div className="mt-8">
          <div className="col-span-1 md:col-span-2 xl:col-span-3">
            <CourseAnalyticsGraph />
          </div>
        </div>
            <div className="flex flex-wrap mt-6">
              <div className="w-full md:w-1/2 p-2">
                <UpcomingAssignments limit={15} />
              </div>
              <div className="w-full md:w-1/2 p-2">
                <LatestSubmissions limit={15} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

// Helper component for counting total items
const TotalItems = ({ title, total }) => (
  <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
    <div className="w-full">
      <h3 className="text-base font-normal text-gray-500 dark:text-gray-400">{title}</h3>
      <span className="text-2xl font-bold leading-none text-gray-900 sm:text-3xl dark:text-white">{total}</span>
    </div>
  </div>
);
