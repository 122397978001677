import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useScenario } from './contexts/ScenarioContext';
import { useUser } from '../../contexts/UserContext';
import QuestionnaireSelection from './components/QuestionnaireSelection';
import ScenarioPicture from './components/ScenarioPicture';
import Prebriefing from './components/Prebriefing';
import PrebriefingVideo from './components/PrebriefingVideo';
import Debriefing from './components/Debriefing';
import Debriefing2 from './components/Debriefing2';
import Reflection from './components/Reflection';
import DueDate from './components/DueDate';
import TabNavigation from '../../components/TabNavigation';
import SideSteps from '../../components/SideSteos';
import EnumDropdown from '../../components/EnumDropdown';
import EnumCheckboxes from '../../components/EnumCheckBoxes';
import { InteractableType } from '../scripting/library/ScriptingEnumLibrary';
import { useAppData } from '../../contexts/AppDataContext';
import { useAuthStatus, getUserData } from '../../services/GetUserData';
import ScenarioInfo from './components/ScenarioInfo';


export default function ScenarioDetails() {
  const { scenario, save, addPatient, updatePatientField, fromCourseta, setFromCourseta } = useScenario();
  const user = useUser();
  const navigate = useNavigate();
  const { conditions, gradeScript } = useAppData(); // Fetch gradeScript along with conditions
  const [activeStep, setActiveStep] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  const stepsInfo = ['Info', 'Prebriefing', 'Questionnaire', 'Debriefing', 'Reflection'];
  const simulationInfo = ['Environment', 'Patient', 'Grades']; // Added 'Grades' step here
  const [currentUserData, setCurrentUserData] = useState(null);
  const { currentUser, fetchingData } = useAuthStatus();

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        try {
          const data = await getUserData(currentUser.uid);
          //console.log('Fetched user data:', data); // For debugging
          setCurrentUserData(data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();
  }, [currentUser]);

  useEffect(() => {
    // Initialize patients' grades filter if not already set
    if (scenario?.patients) {
      scenario.patients.forEach((patient, index) => {
        if (!patient.filter) {
          updatePatientField(index, 'filter', []); // Initialize the filter field for each patient
        }
      });
    }
  }, [scenario]);

  const handleToggleCondition = (index, conditionId) => {
    const c = (scenario?.patients[index].conditions ?? []);
    updatePatientField(index, 'conditions', c.includes(conditionId) ? c.filter(con => con !== conditionId) : c.concat([conditionId]));
  };

  const handleToggleGrade = (patientIndex, gradeScriptId, checked) => {
    const patient = scenario?.patients[patientIndex];

    if (!patient) return;

    const currentFilter = patient.filter ?? [];

    // If checked (true), remove from filter; if unchecked (false), add to filter
    const newFilter = checked
      ? currentFilter.filter((id) => id !== gradeScriptId) // Remove from filter if checked (true)
      : [...currentFilter, gradeScriptId]; // Add to filter if unchecked (false)

    // Update the scenario's patient filter field
    updatePatientField(patientIndex, 'filter', newFilter);
  };

  const handlePatientChange = (index, field, value) => {
    updatePatientField(index, field, value);
  };

  const renderCurriculimContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div>
            <ScenarioInfo />
            <ScenarioPicture />
            <DueDate />
          </div>
        );
      case 1:
        return (
          <div>
            <Prebriefing />
            <PrebriefingVideo />
          </div>
        );
      case 2:
        return (
          <div>
            <QuestionnaireSelection />
          </div>
        );
      case 3:
        return (
          <div>
            <Debriefing />
            <Debriefing2 />
          </div>
        );
      case 4:
        return <Reflection />;
      default:
        return null;
    }
  };

  const renderSimulationContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div>
            <EnumCheckboxes enumObj={InteractableType} label="Interactable Type" path="interactables" />
          </div>
        );
      case 1:
        return (
          <div>
            {scenario?.patients?.map((patient, index) => (
              <div key={index} className="mb-4">
                <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                  Patient {index + 1} Name
                </label>
                <input
                  type="text"
                  value={patient.name || ''}
                  onChange={(e) => handlePatientChange(index, 'name', e.target.value)}
                  className="w-full px-3 py-2 border rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800"
                />
                <EnumDropdown
                  enumObj={InteractableType}
                  label="Eye Gaze"
                  selected={patient.eye_gaze}
                  onSelect={(value) => handlePatientChange(index, 'eye_gaze', value)}
                />
                <table className="w-full">
                  <tbody>
                    {conditions?.map((condition) => (
                      <tr key={condition.id}>
                        <td className="py-2 px-4 border-b">{condition.name}</td>
                        <td className="py-2 px-4 border-b">
                          <label htmlFor={`toggle-switch-${condition.id}`} className="relative inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              id={`toggle-switch-${condition.id}`}
                              checked={patient.conditions?.includes(condition.id) || false}
                              onChange={() => handleToggleCondition(index, condition.id)}
                              className="sr-only peer"
                            />
                            <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:bg-blue-600 transition duration-300 ease-in-out"></div>
                            <div className="w-4 h-4 bg-white border border-gray-300 rounded-full dark:border-gray-600 absolute left-1 top-1 peer-checked:translate-x-full transition-transform duration-300 ease-in-out"></div>
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
            <button
              onClick={() => addPatient({ name: '', eye_gaze: null, conditions: [], filter: [] })} // Initialize filter as an empty array
              className="px-4 py-2 bg-green-600 hover:bg-green-700 text-white text-lg font-medium rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 dark:bg-green-700 dark:hover:bg-green-800"
            >
              Add New Patient
            </button>
          </div>
        );
      case 2: // Grades step
        return (
          <div>
            {scenario?.patients?.map((patient, patientIndex) => (
              <div key={patientIndex} className="mb-4">
                <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                  Patient {patientIndex + 1} Grades
                </label>
                <table className="w-full">
                  <tbody>
                    {gradeScript?.map((grade) => (
                      <tr key={grade.id}>
                        <td className="py-2 px-4 border-b">{grade.name}</td>
                        <td className="py-2 px-4 border-b">
                          <label htmlFor={`toggle-switch-${grade.id}`} className="relative inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              id={`toggle-switch-${grade.id}`}
                              checked={!patient.filter?.includes(grade.id) ?? true} // Checked true unless in filter
                              onChange={(e) => handleToggleGrade(patientIndex, grade.id, e.target.checked)}
                              className="sr-only peer"
                            />
                            <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:bg-blue-600 transition duration-300 ease-in-out"></div>
                            <div className="w-4 h-4 bg-white border border-gray-300 rounded-full dark:border-gray-600 absolute left-1 top-1 peer-checked:translate-x-full transition-transform duration-300 ease-in-out"></div>
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  const tabs = ['Curriculum'];
  if (user?.roleName === 'Admin') {
    tabs.push('Simulation', 'EMR', 'Rubric', 'Scenario', 'Overview');
  }

  const components = [
    <div className="flex">
      <div className="w-1/4 p-4">
        <SideSteps steps={stepsInfo} activeStep={activeStep} setActiveStep={setActiveStep} />
      </div>
      <div className="w-3/4 p-4">{renderCurriculimContent()}</div>
    </div>,
  ];

  if (user?.roleName === 'Admin') {
    components.push(
      <div className="flex">
        <div className="w-1/4 p-4">
          <SideSteps steps={simulationInfo} activeStep={activeStep} setActiveStep={setActiveStep} />
        </div>
        <div className="w-3/4 p-4">{renderSimulationContent()}</div>
      </div>,
    );
  }

  const handleTabChange = (index) => {
    setActiveTab(index);
    setActiveStep(0);
  };

  if (!scenario) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen p-4">
      <div className="container mx-auto p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
        {user.roleName === 'Admin' && (
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="fromCourseta"
              checked={fromCourseta}
              onChange={(e) => setFromCourseta(e.target.checked)} // Update the state when the checkbox is clicked
              className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="fromCourseta"
              className="text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              fromCourseta
            </label>
          </div>

        )}
        {/* Tab navigation */}
        <TabNavigation
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={handleTabChange}
          components={components}
          title={scenario ? scenario.name : 'Error'}
        />
      </div>
      <div className="fixed bottom-4 right-4 flex space-x-4">
        <button
          onClick={save}
          className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-lg font-medium rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-blue-700 dark:hover:bg-blue-800"
        >
          Save
        </button>
        {currentUserData && currentUserData.roleName === 'Admin' && (
          <button
            onClick={() => navigate(`/main/scenarioDetails/${scenario.id}/visualScripting`)}
            className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-lg font-medium rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-blue-700 dark:hover:bg-blue-800"
          >
            Open Visual Scripting tool
          </button>
        )}
      </div>
    </div>
  );
}  