import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { useNavigate } from 'react-router';
import { usePopUp } from '../../contexts/PopUpContext';
import CreateSchool from '../creators/CreateSchool';
import { HiHome, HiRefresh } from "react-icons/hi";
import { useNotification } from '../../contexts/NotificationContext';
import { useAppData } from '../../contexts/AppDataContext';
import SkeletonLoader from '../../components/SkeletonLoader';

export default function SchoolsOverview() {
  const [schools, setSchools] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { showPopUp } = usePopUp();
  const { showNotification } = useNotification();
  const { isLoading } = useAppData();

  useEffect(() => {
    const fetchSchools = async () => {
      const schoolsCollectionRef = collection(firestore, 'schools');
      const schoolsSnapshot = await getDocs(schoolsCollectionRef);
      const schoolsList = schoolsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSchools(schoolsList);
    };

    fetchSchools();
  }, []);

  const handleAddSchoolClick = () => {
    showPopUp(<CreateSchool onClose={() => showPopUp(null)} />);
  };

  const refreshSchoolAttendees = async () => {
    const usersCollectionRef = collection(firestore, 'users');
    const usersSnapshot = await getDocs(usersCollectionRef);

    const schoolAttendeesMap = {};

    usersSnapshot.forEach((userDoc) => {
      const userData = userDoc.data();
      if (userData.schoolID && !schoolAttendeesMap[userData.schoolID]) {
        schoolAttendeesMap[userData.schoolID] = [];
      }
      if (userData.schoolID) {
        schoolAttendeesMap[userData.schoolID].push(userDoc.id);
      }
    });

    for (const [schoolID, attendees] of Object.entries(schoolAttendeesMap)) {
      const schoolDocRef = doc(firestore, 'schools', schoolID);
      await updateDoc(schoolDocRef, {
        attendees: attendees,
        numOfMembers: attendees.length,
      });
    }

    const schoolsCollectionRef = collection(firestore, 'schools');
    const schoolsSnapshot = await getDocs(schoolsCollectionRef);
    const schoolsList = schoolsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setSchools(schoolsList);
    showNotification('success', 'All schools and data are updated');
  };

  const filteredSchools = schools.filter(school =>
    school.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
        <div className="flex items-center space-x-2">
          <HiHome className="text-4xl text-gray-700 dark:text-gray-300" />
          <h1 className="pl-5 text-2xl font-semibold text-gray-900 dark:text-white">Schools</h1>
        </div>
        <div className="flex space-x-4">
          <button
            type="button"
            onClick={handleAddSchoolClick}
            className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          >
            <HiHome className="mr-2 -ml-1" />
            Add new School
          </button>
          <input
            type="text"
            placeholder="Search Schools"
            className="block p-2 text-sm border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            type="button"
            onClick={refreshSchoolAttendees}
            className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          >
            <HiRefresh className="mr-2 -ml-1" />
            Refresh School Data
          </button>
        </div>
      </div>

      {/* Schools Listing */}
      {isLoading ? (
        <SkeletonLoader type='card' />
      ) : (
        <div className="flex flex-col space-y-4">
          {filteredSchools.map((school) => (
            <div key={school.id} className="flex items-center justify-between p-5 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
              <h3 className="text-lg font-bold leading-tight text-gray-900 dark:text-white flex-1">
                {school.name}
              </h3>
              <div className="flex flex-1 items-center justify-between">
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Members: {school.numOfMembers}
                </span>
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Capacity: {school.capacity}
                </span>
                <div className="w-32 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mx-4">
                  <div className="bg-primary-600 h-2.5 rounded-full" style={{ width: `${(school.numOfMembers / school.capacity) * 100}%` }}></div>
                </div>
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => navigate(`/main/schoolDetails/${school.id}`)}
                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  See more
                  <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m3 4L9 9" />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
