import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // Calendar styling
import AssessmentCard from './components/AssesmentCard';
import { useUser } from '../../contexts/UserContext';
import { useAppData } from '../../contexts/AppDataContext';
import { HiOutlineTrash, HiOutlineBookOpen } from 'react-icons/hi';
import { deleteAssignmentFirebase } from '../../services/FirebaseService';
import { useOverview } from '../../contexts/OverviewContext';
import Pagination from '../../components/Pagination';
import Accordion from '../../components/Accordion';
import { Table } from 'flowbite-react';
import { useNotification } from '../../contexts/NotificationContext';

export default function AssessmentsOverview() {
  const { assignments, grades, courses, scenarios, users } = useAppData(); // Voeg 'users' toe om docenten te zoeken
  const user = useUser();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const [activeTab, setActiveTab] = useState('total');
  const [dueAssignments, setDueAssignments] = useState([]);
  const [overdueAssignments, setOverdueAssignments] = useState([]);
  const [inProgressAssignments, setInProgressAssignments] = useState([]);

  // Calendar localizer
  const localizer = momentLocalizer(moment);

  // Colors for due and overdue assignments
  const overdueColor = "text-red-500";
  const dueColor = "text-green-500";

  // Use OverviewContext for search and pagination (admin only)
  const {
    searchQuery,
    currentPage,
    itemsPerPage,
    handleSearchChange,
    handlePageChange,
    handleNumItemsPerPageChange,
    setSearchQuery,
    setSortConfig,
    setCurrentPage
  } = useOverview();

  // Admin logic for search, sorting, pagination
  useEffect(() => {
    if (user.roleName === 'Admin') {
      setSearchQuery('');
      setSortConfig({ key: 'assignmentName', direction: 'ascending' });
      return () => {
        setSearchQuery('');
        setSortConfig({ key: 'assignmentName', direction: 'ascending' });
      };
    }
  }, [setSearchQuery, setSortConfig, setCurrentPage, user.roleName]);

  useEffect(() => {
    if (grades && assignments.length > 0 && scenarios.length > 0 && courses.length > 0) {
      const userGrades = grades[user.id];
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      // Voeg scenario-, course- en teacher-informatie toe aan alle assignments
      const assignmentsWithInfo = assignments.map((assignment) => {
        const matchingScenario = scenarios.find(scenario => scenario.id === assignment.scenarioId);
        const course = courses.find(course => course.id === assignment.courseId);
        const teacher = users.find(usr => usr.id === assignment.creatorId);

        return {
          ...assignment,
          scenarioName: matchingScenario ? matchingScenario.name : null,
          scenarioPicture: matchingScenario ? matchingScenario.scenarioPicture : null,
          courseName: course ? course.className : 'Unknown Course',
          teacherName: teacher ? teacher.lastName : 'Unknown Teacher',
        };
      });

      // Filter assignments op basis van grades en due dates
      const classifiedAssignments = assignmentsWithInfo.reduce((acc, assignment) => {
        const assignmentDueDate = assignment.dueDate?.toDate ? assignment.dueDate.toDate() : new Date(assignment.dueDate);
        if (isNaN(assignmentDueDate.getTime())) {
          return acc;
        }

        const dueDateWithoutTime = new Date(assignmentDueDate.getFullYear(), assignmentDueDate.getMonth(), assignmentDueDate.getDate());
        const completedGrade = userGrades?.find(grade => grade.assessmentID === assignment.id);

        if (!completedGrade) {
          if (dueDateWithoutTime < today) {
            acc.overdue.push(assignment); // Voeg toe aan overdue assignments
          } else {
            acc.due.push(assignment); // Voeg toe aan due assignments
          }
        } else if (!completedGrade.submissionDate) {
          acc.inProgress.push(assignment); // Voeg toe aan in-progress assignments
        }

        return acc;
      }, { due: [], overdue: [], inProgress: [] });

      // Update de state met de gefilterde assignments inclusief scenario-informatie
      setDueAssignments(classifiedAssignments.due);
      setOverdueAssignments(classifiedAssignments.overdue);
      setInProgressAssignments(classifiedAssignments.inProgress);
    }
  }, [assignments, grades, scenarios, courses, users, user.id]);

  const handleDeleteAssignment = async (courseId, assignmentId) => {
    const confirmation = window.confirm("Are you sure you want to delete this assignment?");
    if (confirmation) {
      try {
        await deleteAssignmentFirebase(courseId, assignmentId);
        setDueAssignments(prev => prev.filter(assignment => assignment.id !== assignmentId));
        showNotification('success', 'Assignment deleted successfully');
      } catch (error) {
        console.error('Error deleting assignment:', error);
      }
    }
  };

  const getClassNameById = (courseId) => {
    const course = courses.find(course => course.id === courseId);
    return course ? course.className : "Unknown Class";
  };

  const totalPages = Math.ceil(assignments.length / itemsPerPage);
  const indexOfLastAssignment = currentPage * itemsPerPage;
  const indexOfFirstAssignment = indexOfLastAssignment - itemsPerPage;
  const currentAssignments = assignments.slice(indexOfFirstAssignment, indexOfLastAssignment);

  const calendarEvents = [...dueAssignments, ...overdueAssignments].map(assignment => {
    const startDate = assignment.dueDate && typeof assignment.dueDate.toDate === 'function'
      ? assignment.dueDate.toDate()
      : new Date(assignment.dueDate || new Date());

    const classroomName = getClassNameById(assignment.courseId);

    return {
      title: `${assignment.assignmentName} - ${classroomName}`,
      start: startDate,
      end: startDate,
      allDay: true,
      resource: assignment
    };
  });

  const calendarLegend = (
    <div className="flex justify-between mb-6">
      <div className="flex items-center space-x-2">
        <span className="w-4 h-4 bg-blue-500 inline-block rounded"></span>
        <span className="text-gray-600 dark:text-gray-300">Assignment</span>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
        <div className="flex items-center mb-6">
          <HiOutlineBookOpen className="text-4xl text-gray-600 dark:text-white mr-3" />
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">
            Assignment
          </h1>
        </div>
      </div>

      <div className="flex mb-4 space-x-8 border-b-2 border-gray-200">
        <button
          className={`px-4 pb-2 text-lg font-medium ${activeTab === 'total' ? 'text-blue-500 border-b-4 border-blue-500' : 'text-gray-600'} dark:text-white`}
          onClick={() => setActiveTab('total')}
        >
          All Assignments
        </button>
        <button
          className={`px-4 pb-2 text-lg font-medium ${activeTab === 'calendar' ? 'text-blue-500 border-b-4 border-blue-500' : 'text-gray-600'} dark:text-white`}
          onClick={() => setActiveTab('calendar')}
        >
          Calendar
        </button>
      </div>

      {activeTab === 'total' ? (
        <>
          {user.roleName === 'Admin' ? (
            <>
              <div className="flex justify-between items-center mb-4">
                <form className="flex items-center w-full" onSubmit={(e) => e.preventDefault()}>
                  <input
                    type="search"
                    id="search-assignments"
                    className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:text-white dark:border-gray-600 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="Search assignments"
                    onChange={handleSearchChange}
                  />
                </form>
              </div>

              <div className="container mx-auto py-10">
                <Table hoverable={true}>
                  <Table.Head>
                    <Table.HeadCell>Assignment Name</Table.HeadCell>
                    <Table.HeadCell>Class Name</Table.HeadCell>
                    <Table.HeadCell>Actions</Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {currentAssignments.map((assignment) => (
                      <Table.Row key={assignment.id}>
                        <Table.Cell>{assignment.assignmentName}</Table.Cell>
                        <Table.Cell>{getClassNameById(assignment.courseId)}</Table.Cell>
                        <Table.Cell>
                          <HiOutlineTrash
                            className="text-red-500 cursor-pointer"
                            onClick={() => handleDeleteAssignment(assignment.courseId, assignment.id)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>

                <div className="mt-4 text-right text-gray-600 dark:text-gray-300">
                  <span>Total assignments: {assignments.length}</span>
                </div>

                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  numItemsPerPage={itemsPerPage}
                  onNumItemsPerPageChange={handleNumItemsPerPageChange}
                />
              </div>
            </>
          ) : (
            <>
              <div className="space-y-6">
                {inProgressAssignments.length > 0 && (
                  <Accordion
                    title={`In Progress Assignments (${inProgressAssignments.length})`}
                    content={
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {inProgressAssignments.map((assignment) => {
                          const completedGrade = grades[user.id]?.find(grade => grade.assessmentID === assignment.id);
                          return (
                            <AssessmentCard
                              key={assignment.id}
                              assessment={assignment}
                              scenarioPicture={assignment.scenarioPicture || 'Loading Image...'}
                              courseName={assignment.courseName || 'Unknown Course'}
                              teacherName={assignment.teacherName || 'Unknown Teacher'}
                              completedGrade={completedGrade}
                              scenarioName={assignment.scenarioName}
                            />
                          );
                        })}
                      </div>
                    }
                    isOpenByDefault={true}
                  />
                )}

                <Accordion
                  title={
                    <>
                      Due Assignment (
                      <span className={dueColor}>
                        {dueAssignments.length < 10 ? '0' + dueAssignments.length : dueAssignments.length}
                      </span>)
                    </>
                  }
                  content={
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                      {dueAssignments.map((assignment) => (
                        <AssessmentCard
                          key={assignment.id}
                          assessment={assignment}
                          scenarioPicture={assignment.scenarioPicture}
                          courseName={assignment.courseName}
                          teacherName={assignment.teacherName}
                          scenarioName={assignment.scenarioName} />
                      ))}
                    </div>
                  }
                  isOpenByDefault={true}
                  itemCount={dueAssignments.length}
                  showViewAll={false}
                />

                <Accordion
                  title={
                    <>
                      Overdue Assignment (
                      <span className={overdueColor}>
                        {overdueAssignments.length < 10 ? '0' + overdueAssignments.length : overdueAssignments.length}
                      </span>)
                    </>
                  }
                  content={
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                      {overdueAssignments.map((assignment) => (
                        <AssessmentCard key={assignment.id} assessment={assignment} scenarioPicture={assignment.scenarioPicture} courseName={assignment.courseName} teacherName={assignment.teacherName} />
                      ))}
                    </div>
                  }
                  isOpenByDefault={false}
                  itemCount={overdueAssignments.length}
                  showViewAll={false}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {calendarLegend}

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <Calendar
              localizer={localizer}
              events={calendarEvents}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 600 }}
              eventPropGetter={(event) => ({
                style: {
                  backgroundColor: '#1E90FF',
                  color: 'white',
                  borderRadius: '8px',
                  padding: '5px',
                  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)'
                },
              })}
              views={['month', 'week', 'day']}
              defaultView="month"
            />
          </div>
        </>
      )}
    </div>
  );
}
