/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useContext, useEffect } from 'react';
import logo from '../../assets/images/Courseta_Logo.png';
import { useAuth } from '../../contexts/AuthContext';
import { useNotification } from '../../contexts/NotificationContext';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';

const SignIn = () => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();
    const navigate = useNavigate();

    const user = useUser();

    useEffect(() => {
        if (user) {
            navigate('/main');
        }
    }, [user]);

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setError('');
            setLoading(true);
            const userCredential = await login(emailRef.current.value, passwordRef.current.value);
            showNotification('success', 'Login was successful!');
        } catch (err) {
            setError('Failed to sign in. Please check your email and password.');
        }

        setLoading(false);
    }

    return (
        <section className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
            <div className="flex w-full h-screen max-w-none mx-auto bg-white dark:bg-gray-800">
                <div className="w-1/2 h-full flex items-center justify-center bg-blue-600 rounded-tr-3xl rounded-br-3xl">
                    <img className="w-80 h-90" src={logo} alt="Courseta Logo" />
                </div>
                <div className="w-1/2 h-full flex items-center justify-center p-8">
                    <div className="w-full max-w-md space-y-4 md:space-y-6">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Welcome Back!
                        </h1>
                        {error && (
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <strong className="font-bold">Error! </strong>
                                <span className="block sm:inline">{error}</span>
                            </div>
                        )}
                        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                                <input ref={emailRef} type="email" id="email" autoComplete="username" required className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="name@youremail.com" />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                <input ref={passwordRef} type="password" id="password" autoComplete="current-password" required className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input id="remember" aria-describedby="remember" name="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="remember" className="font-light text-gray-500 dark:text-gray-400">Keep me logged in</label>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" disabled={loading} className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                {loading ? 'Signing in...' : 'Sign in'}
                            </button>
                        </form>
                        <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                            Forgot your password?
                            <a href="/forgotpassword" onClick={(e) => { e.preventDefault(); navigate("/forgotpassword"); }} className="font-medium text-blue-600 hover:underline dark:text-blue-500">Click here!</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SignIn;
