import React, { useEffect } from 'react';
import { useScenario } from '../contexts/ScenarioContext';
import { PlusIcon } from '@heroicons/react/solid';


const Debriefing2 = () => {
  const { scenario, setField } = useScenario();

  const handleDelete = (index) => {
    const updated = scenario?.debriefing_2?.filter((_, i) => i !== index);
    setField(updated, 'debriefing_2');
  };

  const handleAdd = () => {
    const newQuestion = {
      question: '',
      answers: ['', '', '', ''],
      correctAnswer: 0,
      rationale: ''
    };
    setField([...scenario?.debriefing_2 ?? [], newQuestion], 'debriefing_2');
  };

  const handleEdit = (e, type, questionIndex, answerIndex) => {
    let updated = [...scenario.debriefing_2];
    if (type === 'question') {
      updated[questionIndex].question = e.target.value;
    } else if (type === 'answer') {
      updated[questionIndex].answers[answerIndex] = e.target.value;
    } else if (type === 'correctAnswer') {
      updated[questionIndex].correctAnswer = Number(e.target.value);
    } else if (type === 'rationale') {
      updated[questionIndex].rationale = e.target.value;
    }
    setField(updated, 'debriefing_2');
  };

  useEffect(() => {}, [scenario?.debriefing_2]);

  return (
    <div className="container mx-auto my-8 p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Clinical Judgment Debriefing</h2>
      {scenario?.debriefing_2?.map((question, questionIndex) => (
        <div key={questionIndex} className="mb-6 bg-white dark:bg-gray-700 p-4 rounded-md shadow-sm">
          <input
            type="text"
            id={`question_${questionIndex}`}
            placeholder="Enter question"
            value={question.question}
            onChange={(e) => handleEdit(e, 'question', questionIndex)}
            className="p-2 border rounded w-full"
          />
          <textarea
            id={`rationale_${questionIndex}`}
            placeholder="Enter rationale"
            value={question.rationale || ''}
            onChange={(e) => handleEdit(e, 'rationale', questionIndex)}
            className="p-2 border rounded w-full"
          />
          {question.answers.map((answer, answerIndex) => (
            <div key={answerIndex} className="flex items-center mt-2">
              <input
                type="radio"
                id={`correctAnswer_${questionIndex}_${answerIndex}`}
                value={Number(answerIndex)}
                name={`correctAnswer_${questionIndex}`}
                checked={question.correctAnswer === answerIndex}
                onChange={(e) => handleEdit(e, 'correctAnswer', questionIndex)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 rounded mr-4"
              />
              <input
                type="text"
                id={`answer_${questionIndex}_${answerIndex}`}
                placeholder={`Answer ${answerIndex + 1}`}
                value={answer}
                onChange={(e) => handleEdit(e, 'answer', questionIndex, answerIndex)}
                className="p-2 border rounded flex-grow"
              />
            </div>
          ))}
          <button
            onClick={() => handleDelete(questionIndex)}
            className="w-full py-2 bg-red-500 text-white rounded mt-4 hover:bg-red-600"
          >
            Delete Question
          </button>
        </div>
      ))}
      <div className="flex justify-center">
        <button onClick={handleAdd} className="flex items-center px-6 py-1.5 border border-gray-400 mt-4 hover:text-white text-gray-600 dark:text-gray-300 rounded text-sm hover:bg-blue-600 dark:hover:bg-blue-700 mx-auto">
          <PlusIcon className="w-6 h-6 mr-2" />
          Add Question
        </button>
      </div>
    </div>
  );
};

export default Debriefing2;
