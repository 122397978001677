import './App.css';
import { useState, useEffect } from 'react';
import { NotificationProvider } from './contexts/NotificationContext';
import Notification from './components/Notification';
import AppRouter from './routing/AppRouter';
import { UserProvider } from './contexts/UserContext'; // Import the UserProvider
import { PopUpProvider } from './contexts/PopUpContext';
import PopUp from './components/PopUp';
import { SettingsProvider } from './contexts/SettingsContext';
import { AppDataProvider } from './contexts/AppDataContext';
import { OverviewProvider } from './contexts/OverviewContext';
import logo from './assets/images/Courseta_Logo.png';

function App() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  const handleResize = () => {
    setIsDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!isDesktop) {
    // Fallback scherm als het geen desktop is
    return (
      <div style={styles.fallbackContainer}>
        <div style={styles.fallbackContent}>
          <img src={logo} alt="App Logo" style={styles.logo} />
          <h1 style={styles.title}>Desktop Required</h1>
          <p style={styles.message}>
  This application is only available on desktop. Please switch to a desktop device to continue.
</p>

        </div>
      </div>
    );
  }

  // Render je applicatie zoals gewoonlijk als het een desktop is
  return (
    <NotificationProvider>
      <SettingsProvider>
        <UserProvider>
          <AppDataProvider>
            <OverviewProvider>
              <Notification />
              <PopUpProvider>
                <AppRouter />
                <PopUp />
              </PopUpProvider>
            </OverviewProvider>
          </AppDataProvider>
        </UserProvider>
      </SettingsProvider>
    </NotificationProvider>
  );
}

const styles = {
  fallbackContainer: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f8f9fa',
  },
  fallbackContent: {
    textAlign: 'center',
    backgroundColor: '#ffffff',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  },
  logo: {
    width: '150px',
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#343a40',
  },
  message: {
    fontSize: '16px',
    color: '#6c757d',
    marginTop: '10px',
  },
};

export default App;
