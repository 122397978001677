import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTrashAlt, faChalkboardTeacher, faClock, faList, faBook, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { usePopUp } from '../../../contexts/PopUpContext';
import StartSimPopUp from './StartSimPopUp';
import ContinueSim from './ContinueSimPopUp';
import { getFileUrl } from '../../../services/FirebaseService';
import { useNotification } from '../../../contexts/NotificationContext';
import { useAppData } from '../../../contexts/AppDataContext';
import { useUser } from '../../../contexts/UserContext';

const AssessmentCard = ({ assessment, canManage, onDelete, courseName, teacherName, scenarioName, completedGrade }) => {
  const navigate = useNavigate();
  const { showPopUp } = usePopUp();
  const { showNotification } = useNotification(); // Gebruik notificatie
  const onClose = () => showPopUp(null);
  const [imageUrl, setImageUrl] = useState(null);
  const { grades } = useAppData();
  const user = useUser();

  // Fetch the image from Firebase using the scenarioPicture URL
  useEffect(() => {
    const fetchImage = async () => {
      if (assessment.scenarioPicture) {
        if (assessment.scenarioPicture.startsWith('https://')) {
          setImageUrl(assessment.scenarioPicture); // Direct URL
        } else {
          const url = await getFileUrl(assessment.scenarioPicture); // Fetch the image URL from Firebase
          setImageUrl(url);
        }
      }
    };
    fetchImage();
  }, [assessment.scenarioPicture]);

  const handleDeleteClick = () => {
    if (window.confirm(`Are you sure you want to delete "${assessment.assignmentName}"?`)) {
      onDelete(assessment.id);
    }
  };


  const handleStartSimulationClick = () => {
    // Zoek de juiste grade voor deze assignment
    const completedGrade = grades[user.id]?.find(grade => grade.assessmentID === assessment.id);

    // if (isOverdue) {
    //   showNotification('danger', 'This assignment is overdue and can no longer be started.');
    // } else 
    if (isInProgress) {
      // Geef de grade door aan de ContinueSim popup
      showPopUp(<ContinueSim onClose={onClose} navigate={navigate} assessment={assessment} grade={completedGrade} />);
    } else {
      // Voor een nieuwe simulatie hoeven we geen grade door te geven
      showPopUp(<StartSimPopUp onClose={onClose} navigate={navigate} assessment={assessment} />);
    }
  };



  const date = assessment.dueDate?.toDate();
  const formattedDateTime = date?.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
    + ' ' + date?.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

  //const isOverdue = date && date < new Date(); // Controleer of de dueDate in het verleden ligt

  // Logica voor "In Progress" -> Wel een grade, maar geen submissionDate
  const isInProgress = completedGrade && !completedGrade.submissionDate;

  return (
    <div className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between h-full">
      {/* Scenario Image */}
      {imageUrl ? (
        <div className="w-full h-full">
          <img className="object-cover w-full h-full" src={imageUrl} alt={assessment.assignmentName} />
        </div>
      ) : (
        <div className="w-full h-56 bg-gray-300 flex items-center justify-center">
          <span>Loading Image...</span>
        </div>
      )}

      {/* Card content */}
      <div className="w-full">
        <div>
          {/* Make sure this div takes full width */}
          <div className="w-full justify-between items-center mb-2 border border-gray-300 rounded-lg shadow-sm p-4 bg-white dark:bg-gray-800">
            <h4 className="text-lg font-bold text-gray-800 dark:text-white w-full">
              {assessment.assignmentName}
            </h4>
            {canManage && (
              <FontAwesomeIcon
                icon={faTrashAlt}
                className="text-red-500 cursor-pointer hover:text-red-600 text-lg md:text-xl"
                title="Delete Assessment"
                onClick={handleDeleteClick}
              />
            )}
          </div>

          {/* Show Scenario Name with red label and icon */}
          <p className="text-sm font-medium mb-2">
            <span className="px-2 py-1 bg-red-100 text-red-600 rounded-md text-xs flex items-center">
              <FontAwesomeIcon icon={faList} className="mr-2" />
              Scenario: {scenarioName}
            </span>
          </p>

          {/* Show Course Name with new styling and icon */}
          <p className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            <span className="px-2 py-1 bg-blue-100 text-blue-600 rounded-md text-xs flex items-center">
              <FontAwesomeIcon icon={faBook} className="mr-2" />
              Course: {courseName}
            </span>
          </p>

          {/* Show Teacher Name with a colored label */}
          <p className="flex items-center text-sm font-semibold mb-2">
            <span className="inline-block bg-green-200 text-green-700 px-2 py-1 rounded-lg text-xs flex items-center">
              <FontAwesomeIcon icon={faChalkboardTeacher} className="mr-1" />
              Teacher: {teacherName}
            </span>
          </p>

          {/* Show Due Date with border */}
          <p className="text-gray-600 dark:text-gray-400 flex items-center border border-gray-300 dark:border-gray-600 rounded-md p-2 mb-4">
            <FontAwesomeIcon icon={faClock} className="mr-2" />
            Due: {formattedDateTime}
          </p>
        </div>

        {/* Start or Finish Button */}
        <button
          onClick={handleStartSimulationClick}
          type="button"
          className={`w-full flex items-center justify-center border border-gray-300 dark:border-gray-600 ${isInProgress
              ? 'bg-green-500 text-white hover:bg-green-600' // Groene knop als in progress
              // : isOverdue
              // ? 'bg-red-500 text-white hover:bg-red-600' // Rode knop als overdue
              : 'bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600' // Default button
            } px-4 py-2 rounded-lg shadow-sm font-medium mt-4`}
        >
          {isInProgress ? 'Finish the Grade'
            : 'Start Now'}
          <FontAwesomeIcon icon={isInProgress ? faCheckCircle : faArrowRight} className="ml-2 text-lg" />
        </button>
      </div>
    </div>
  );
};

export default AssessmentCard;
