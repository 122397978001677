import React from 'react';
import AssignmentsTable from '../components/AssignmentsTable';


const Assignments = ({ handleCreateAssignment }) => {
    return (
        <AssignmentsTable handleCreateAssignment={handleCreateAssignment} />
    );
};

export default Assignments;
