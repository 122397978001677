import React, { useEffect, useRef, useState } from 'react';
import { useNotification } from '../../contexts/NotificationContext';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { addDocument } from '../../services/FirebaseService';
import { doc, getFirestore, getDoc } from 'firebase/firestore';
import { auth, firestore, functions, httpsCallable } from '../../firebase';
import { authMail } from '../../emails/Auth_mail';
import Accordion from '../../components/Accordion';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useUser } from '../../contexts/UserContext';

// Placeholder profile picture SVG
const placeholderProfilePicture =
    "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='currentColor' class='bi bi-person-circle' viewBox='0 0 16 16'><path d='M13.468 12.37C12.853 11.19 11.34 10 8 10s-4.853 1.19-5.468 2.37C1.778 13.123 0 14.884 0 16.5V17h16v-.5c0-1.616-1.778-3.377-2.532-4.13z'/><path fill-rule='evenodd' d='M8 9a3 3 0 100-6 3 3 0 000 6z'/><path fill-rule='evenodd' d='M8 1a7 7 0 100 14A7 7 0 008 1zM1 8a7 7 0 1114 0A7 7 0 011 8z'/></svg>";

const getClassRoomById = async (classId) => {
    const classRoomRef = doc(firestore, 'classRooms', classId);
    const classRoomDoc = await getDoc(classRoomRef);
    if (classRoomDoc.exists()) {
        return { id: classRoomDoc.id, ...classRoomDoc.data() };
    } else {
        console.error('Classroom not found:', classId);
        return null;
    }
};

const getGradeById = async (gradeId) => {
    const gradeRef = doc(firestore, 'grades', gradeId);
    const gradeDoc = await getDoc(gradeRef);
    if (gradeDoc.exists()) {
        return { id: gradeDoc.id, ...gradeDoc.data() };
    } else {
        console.error('Grade not found:', gradeId);
        return null;
    }
};



export default function UserInfo({ onClose, user, currentUserRole }) {
    const { showNotification } = useNotification();
    const modalRef = useRef();
    const [inviteUrl, setInviteUrl] = useState('');
    const [classRooms, setClassRooms] = useState([]);
    const [userGrades, setUserGrades] = useState([]);
    const currentUser = useUser();

    // Fetch attending classes for the selected user
    useEffect(() => {
        if (user?.attendingClasses) {
            Promise.all(user.attendingClasses.map(classId => getClassRoomById(classId)))
                .then(classes => setClassRooms(classes))
                .catch(error => {
                    console.error('Error fetching class names:', error);
                    showNotification('danger', 'Failed to fetch class names');
                });
        }
    }, [user?.attendingClasses, showNotification]);

    // Fetch grades for the selected user
    useEffect(() => {
        if (user?.grades) {
            Promise.all(user.grades.map(gradeId => getGradeById(gradeId)))
                .then(gradesData => setUserGrades(gradesData))
                .catch(error => {
                    console.error('Error fetching grades:', error);
                    showNotification('danger', 'Failed to fetch grades');
                });
        }
    }, [user?.grades, showNotification]);

    // Function to close the modal when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const getGeneratedLink = async () => {
        try {
            const userDocRef = doc(getFirestore(), 'pendingRegistrations', user.id);
            const userDocSnap = await getDoc(userDocRef);

            if (!userDocSnap.exists()) {
                console.error('No pending registration found for the user.');
                showNotification('danger', 'No pending invitation found.');
                return;
            }

            const registrationLink = `http://coursetavr.com/#/register?token=${user.id}`;
            const actionCodeSettings = {
                url: registrationLink,
                handleCodeInApp: true,
            };

            // await sendSignInLinkToEmail(auth, user.email, actionCodeSettings);
            showNotification('success', 'Invitation link has been sent!');

            return registrationLink;
        } catch (error) {
            console.error('Error sending invitation link:', error);
            showNotification('danger', 'Error sending the invitation link.');
            return null;
        }
    };

    const sendPasswordReset = async () => {
        try {
            await sendPasswordResetEmail(auth, user.email);
            showNotification('success', 'Password reset email sent successfully!');
        } catch (error) {
            console.error('Error sending password reset email:', error);
            showNotification('danger', 'Failed to send password reset email.');
        }
    };


    const getInviteUrl = async () => {
        const generatedLink = await getGeneratedLink();
        setInviteUrl(generatedLink);
        return generatedLink;
    };

    const sendInvite = async () => {
        try {
            const userDocRef = doc(getFirestore(), 'pendingRegistrations', user.id);
            const userDocSnap = await getDoc(userDocRef);

            const url = await getInviteUrl();
            if (url) {
                showNotification('success', 'Invitation link has been generated!');
            }

            if (!userDocSnap.exists()) {
                console.error('No pending registration found for the user.');
                showNotification('danger', 'No pending invitation found.');
                return;
            }

            const actionCodeSettings = {
                url: `http://coursetavr.com/#/register?token=${user.id}`,
                handleCodeInApp: true,
            };

            // await sendSignInLinkToEmail(auth, user.email, actionCodeSettings);

            showNotification('success', 'Invitation link has been sent!');
        } catch (error) {
            console.error('Error sending invitation link:', error);
            showNotification('danger', 'Error sending the invitation link.');
        }
    };

    const handleSendEmail = async (event) => {
        event.preventDefault();

        try {
            const userDocRef = doc(getFirestore(), 'pendingRegistrations', user.id);
            const userDocSnap = await getDoc(userDocRef);

            if (!userDocSnap.exists()) {
                console.error('No pending registration found for the user.');
                showNotification('danger', 'No pending invitation found.');
                return;
            }

            const url = await getInviteUrl();
            if (!url) {
                showNotification('danger', 'Failed to generate the invitation link.');
                return;
            }

            const htmlcontent = authMail(user.firstName, url);

            const emailData = {
                to: [{ email: user.email, name: user.firstName }],
                from: { email: 'info@coursetavr.com', name: 'CoursetaVR' },
                subject: 'Create your account',
                html: htmlcontent,
            };

            const documentId = await addDocument(emailData, 'emails');
            //console.log('Email scheduled with document ID:', documentId);
            showNotification('success', 'Email sent successfully!');
        } catch (error) {
            console.error('Error sending email:', error);
            showNotification('danger', 'Failed to send email.');
        }
    };

    const giveAdminRole = async () => {
        try {
            const setAdminClaim = httpsCallable(functions, 'setAdminClaim');
            await setAdminClaim({ uid: user.id });
            showNotification('success', 'Admin role granted successfully.');
        } catch (error) {
            console.error('Error granting admin role:', error);
            showNotification('danger', 'Failed to grant admin role.');
        }
    };

    if (!user) return null;

    return (
        <div
            id="readUserModal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center bg-gray-900 bg-opacity-50"
            ref={modalRef}
        >
            <div
                className="relative p-6 w-full max-w-3xl h-full md:h-auto"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
                    {/* Modal header */}
                    <div className="flex justify-between items-start p-5 rounded-t">
                        <div className="flex items-center">
                            <img
                                className="w-24 h-24 rounded-full object-cover mr-4"
                                src={user?.photo_url || placeholderProfilePicture}
                                alt="Profile"
                            />
                            <div>
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    {user.firstName + " " + user.lastName}
                                </h3>
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    {user.roleName}
                                </p>
                            </div>
                        </div>
                        <button
                            type="button"
                            onClick={onClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </div>

                    <div className="px-6 py-4 space-y-4">
                        {/* Admin-specific fields */}
                        {currentUserRole === 'Admin' && (
                            <div className="space-y-2">
                                <p className="text-sm font-medium text-gray-900 dark:text-white">Database ID: {user.id}</p>
                                <button
                                    onClick={giveAdminRole}
                                    className="flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
                                >
                                    Give Admin Role
                                </button>
                            </div>

                        )}

                        <div className="space-y-2">
                            <p className="text-sm font-medium text-gray-900 dark:text-white">Email: {user.email}</p>
                            <p className="text-sm font-medium text-gray-900 dark:text-white">School: {user.schoolName}</p>
                        </div>

                        {/* Courses Accordion */}
                        <Accordion
                            title="Attending Courses"
                            isOpenByDefault={false}
                            content={
                                classRooms.length > 0 ? (
                                    <ul className="space-y-2">
                                        {classRooms?.map((classRoom) => (
                                            <li key={classRoom?.id} className="text-gray-900 dark:text-white">
                                                {classRoom?.className}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-gray-500 dark:text-gray-400">No classes enrolled</p>
                                )
                            }
                        />

                        {/* Grades Accordion */}
                        {/* <Accordion
                            title="Recent Grades"
                            isOpenByDefault={false}
                            content={
                                userGrades.length > 0 ? (
                                    <ul className="space-y-2">
                                        {userGrades?.map((grade) => (
                                            <li key={grade?.id} className="text-gray-900 dark:text-white">
                                                Assignment: {grade?.assessmentName}, Grade: {grade?.averageResult ?? 'N/A'}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-gray-500 dark:text-gray-400">No grades available</p>
                                )
                            }
                        /> */}
                        {currentUser.roleName !== 'Student' && (
                            <div className="space-y-2">

                                <button
                                    onClick={sendPasswordReset}
                                    className="flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
                                >
                                    Send reset password link
                                </button>
                            </div>
                        )}
                    </div>

                    {/* Footer */}
                    <div className="px-6 py-4 flex justify-between items-center border-t border-gray-200 dark:border-gray-700">
                        <div className="flex space-x-3">
                            {currentUser.roleName !== 'Student' && (
                                <button
                                    onClick={handleSendEmail}
                                    type="button"
                                    className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 rounded-lg"
                                >
                                    Send Invite Link
                                </button>
                            )}
                            {inviteUrl && (
                                <div className="p-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800">
                                    {inviteUrl}
                                </div>
                            )}
                        </div>
                        <a
                            href={`mailto:${user.email}`}
                            className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 rounded-lg"
                        >
                            <svg aria-hidden="true" className="w-5 h-5 mr-1 -ml-1" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd"></path>
                            </svg>
                            Send Email
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
