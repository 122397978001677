import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import CharacterHeader from './components/CharacterHeader';
import CharacterTabs from './components/CharacterTabs';
import PersonalityTab from './components/PersonalityTab';
import SafetyConfig from './components/SafetyConfig';
import { VOICE_OPTIONS, COLLOQUIALISM_OPTIONS } from './EnumLib';
import CustomDialogueStyle from './components/CustomDialoqueStyle';
import { useNotification } from '../contexts/NotificationContext';
import Knowledge from './components/Knowledge';

const CharacterDetail = () => {
  const { characterId } = useParams();
  const [characterData, setCharacterData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [activeTab, setActiveTab] = useState('basic');
  const [activeSubTab, setActiveSubTab] = useState('personality');
  const [adjectives, setAdjectives] = useState([]);
  const [colloquialism, setColloquialism] = useState('');
  const [exampleDialog, setExampleDialog] = useState('');
  const [personalityAdjectives, setPersonalityAdjectives] = useState([]);
  const [initialMood, setInitialMood] = useState({});
  const [personality, setPersonality] = useState({});
  const [flaws, setFlaws] = useState('');
  const [motivation, setMotivation] = useState('');
  const [description, setDescription] = useState('');
  const [voiceAttributes, setVoiceAttributes] = useState({
    voice: '',
    pitch: 0,
    speakingRate: 1,
  });
  const [safetyConfig, setSafetyConfig] = useState({});
  const navigate = useNavigate();
  const functions = getFunctions();
  const { showNotification } = useNotification();

  useEffect(() => {
    const fetchCharacterDetails = async () => {
      try {
        const getCharacterDetails = httpsCallable(functions, 'getCharacterDetails');
        const result = await getCharacterDetails({ characterId });
        setCharacterData(result.data);
        console.log(result);

        // Beschrijving, motivatie en fouten instellen
        setDescription(result.data.defaultCharacterDescription?.description || '');
        setMotivation(result.data.defaultCharacterDescription?.motivation || '');
        setFlaws(result.data.defaultCharacterDescription?.flaws || '');

        // Set custom dialog styles (adjectives and colloquialism)
        const customDialogStyles = result.data?.defaultCharacterDescription?.customDialogStyles?.[0] || {};
        setAdjectives(customDialogStyles.adjectives || []);
        setColloquialism(customDialogStyles.colloquialism || '');
        setExampleDialog(result.data.defaultCharacterDescription?.exampleDialog || '');

        // Set personality adjectives (character traits)
        setPersonalityAdjectives(result.data.defaultCharacterDescription?.personalityAdjectives || []);

        // Set voice attributes from defaultCharacterAssets
        const voiceData = result.data?.defaultCharacterAssets?.voice || {};
        setVoiceAttributes({
          voice: voiceData.baseName || '',
          pitch: voiceData.pitch || 0,
          speakingRate: voiceData.speakingRate || 1,
        });

        // Set Initial Mood (Personality)
        setInitialMood(result.data?.initialMood || {});
        setPersonality(result.data?.personality || {});
        setSafetyConfig(result.data?.safetyConfig || {}); // Now it's an object
        console.log(safetyConfig);
        setLoading(false);

      } catch (err) {

        setError(err.message);
        setLoading(false);
      }
    };
    fetchCharacterDetails();
  }, [characterId]);

  const handleSave = async () => {
    setUpdating(true);
    try {
      const updateCharacter = httpsCallable(functions, 'updateCharacter');

      const updateData = {
        characterId,
        defaultCharacterDescription: {
          ...characterData.defaultCharacterDescription,
          personalityAdjectives,  // Zorg dat deze correct wordt meegestuurd
          flaws,
          motivation,
          description,
          customDialogStyles: [
            {
              adjectives, // Updated adjectives
              colloquialism,
              displayName: '',
              isActive: true,
            },
          ],
          exampleDialog,
        },
        defaultCharacterAssets: {
          ...characterData.defaultCharacterAssets,
          voice: {
            baseName: voiceAttributes.voice,
            pitch: parseFloat(voiceAttributes.pitch),
            speakingRate: parseFloat(voiceAttributes.speakingRate),
          },
        },
        initialMood,
        personality,
        safetyConfig: { ...safetyConfig },  // Updated safety configuration as an object
      };

      console.log('Updated Data:', updateData);  // Log voor debugging

      await updateCharacter(updateData);
      setUpdating(false);
      showNotification('success', `Character updated successfully!`);
      // alert('Character updated successfully!');
    } catch (err) {
      setError(err.message);
      setUpdating(false);
      showNotification('danger', err);
      console.error('Error updating character:', err);
    }
  };

  const handleDeleteCharacter = async () => {
    // Delete logic...
    showNotification('danger', 'This is not implemented yet!')
  };

  const handleVoiceAttributeChange = (e) => {
    const { name, value } = e.target;
    setVoiceAttributes((prevAttributes) => ({
      ...prevAttributes,
      [name]: value,
    }));
  };

  const handleColloquialismChange = (e) => setColloquialism(e.target.value);
  const handleExampleDialogChange = (e) => setExampleDialog(e.target.value);

  const handleAddAdjective = (e) => {
    if (e.key === 'Enter' && e.target.value && adjectives.length < 3) {
      setAdjectives([...adjectives, e.target.value]);
      e.target.value = ''; // Clear input
    }
  };

  const handleRemoveAdjective = (adjectiveToRemove) => {
    setAdjectives(adjectives.filter((adjective) => adjective !== adjectiveToRemove));
  };

  const handleAddPersonalityAdjective = (e) => {
    if (e.key === 'Enter' && e.target.value && personalityAdjectives.length < 3) {
      setPersonalityAdjectives([...personalityAdjectives, e.target.value]);
      e.target.value = ''; // Clear input
    }
  };

  const handleRemovePersonalityAdjective = (adjectiveToRemove) => {
    setPersonalityAdjectives(personalityAdjectives.filter((adjective) => adjective !== adjectiveToRemove));
  };

  const handleMoodChange = (mood, value) => {
    setInitialMood({
      ...initialMood,
      [mood]: value,
    });
  };

  const handlePersonalityChange = (trait, value) => {
    setPersonality({
      ...personality,
      [trait]: value,
    });
  };

  const handleSafetyChange = (topicKey, value) => {
    const safetyLevel = value === 1 ? "SAFETY_LEVEL_STRICT_CONTROL" :
      value === 2 ? "SAFETY_LEVEL_MILD_CONTROL" :
        "SAFETY_LEVEL_NO_CONTROL";  // Voor Loose

    setSafetyConfig({
      ...safetyConfig,
      [topicKey]: safetyLevel,  // Update het specifieke topic met de nieuwe waarde
    });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!characterData) return <div>No character data available</div>;

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
      <CharacterHeader
        characterData={characterData}
        defaultCharacterImage="https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png"
        handleSave={handleSave}
        updating={updating}
        handleDeleteCharacter={handleDeleteCharacter}
        deleting={deleting}
      />
      <CharacterTabs activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab === 'basic' && (
        <div className="mt-6">
          {/* Description Field */}
          <div className="mt-6">
            <h3 className="text-xl font-bold text-gray-900 dark:text-white">Core Description</h3>
            <textarea
              className="w-full h-64 p-3 mt-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Provide a description of the character"
            />
          </div>

          {/* Motivation Field */}
          <div className="mt-6">
            <h3 className="text-xl font-bold text-gray-900 dark:text-white">Motivation</h3>
            <textarea
              className="w-full h-64 p-3 mt-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white"
              value={motivation}
              onChange={(e) => setMotivation(e.target.value)}
              placeholder="Describe the character's motivation"
            />
          </div>

          {/* Flaws Field */}
          <div className="mt-6">
            <h3 className="text-xl font-bold text-gray-900 dark:text-white">Flaws</h3>
            <textarea
              className="w-full p-3 mt-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white"
              value={flaws}
              onChange={(e) => setFlaws(e.target.value)}
              placeholder="Describe the character's flaws"
            />
          </div>

          {/* Custom Dialogue Style Section */}
          <CustomDialogueStyle
            adjectives={adjectives}
            handleAddAdjective={handleAddAdjective}
            handleRemoveAdjective={handleRemoveAdjective}
            colloquialism={colloquialism}
            COLLOQUIALISM_OPTIONS={COLLOQUIALISM_OPTIONS}
            handleColloquialismChange={handleColloquialismChange}
            handleRemoveColloquialism={() => setColloquialism('')}
            exampleDialog={exampleDialog}
            handleExampleDialogChange={handleExampleDialogChange}
            generateExampleSentence={() => `${characterData.defaultCharacterDescription?.givenName} tends to give ${adjectives.join(', ')} responses.`}
          />

          {/* Voice Section */}
          <div className="mt-6">
            <h4 className="text-xl font-bold text-gray-900 dark:text-white">Voice</h4>

            <div className="mt-6">
              <h3 className="text-xl font-bold text-gray-900 dark:text-white">2. Choose voice</h3>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Voice</label>
                  <select
                    name="voice"
                    value={voiceAttributes.voice}
                    onChange={handleVoiceAttributeChange}
                    className="w-full p-3 mt-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white"
                  >
                    {VOICE_OPTIONS.map((option) => (
                      <option key={option.baseName} value={option.baseName}>
                        {option.baseName} - {option.description}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 pb-40">
            <h3 className="text-xl font-bold text-gray-900 dark:text-white">3. Customize voice</h3>

            <div className="flex space-x-8 items-center mt-4">
              <div className="w-1/2">
                <div className="flex justify-between">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Pitch</label>
                  <span className="text-lg font-semibold text-gray-900 dark:text-white ml-2">{voiceAttributes.pitch}</span>
                </div>
                <div className="flex items-center mt-2">
                  <span className="text-gray-500 dark:text-gray-400">-5</span>
                  <input
                    type="range"
                    name="pitch"
                    min="-5"
                    max="5"
                    value={voiceAttributes.pitch}
                    onChange={handleVoiceAttributeChange}
                    className="ml-4 mr-4 w-full"
                    id="pitchSlider"
                  />
                  <span className="ml-2 text-gray-500 dark:text-gray-400">5</span>
                </div>
              </div>

              <div className="w-1/2">
                <div className="flex justify-between">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Talking Speed</label>
                  <span className="text-lg font-semibold text-gray-900 dark:text-white ml-2">{voiceAttributes.speakingRate}</span>
                </div>
                <div className="flex items-center mt-2">
                  <span className="text-gray-500 dark:text-gray-400">0.5</span>
                  <input
                    type="range"
                    name="speakingRate"
                    min="0.5"
                    max="1.5"
                    step="0.1"
                    value={voiceAttributes.speakingRate}
                    onChange={handleVoiceAttributeChange}
                    className="ml-4 mr-4 w-full"
                    id="speedSlider"
                  />
                  <span className="ml-2 text-gray-500 dark:text-gray-400">1.5</span>
                </div>
              </div>
            </div>
          </div>
          <br></br>
        </div>
      )}

      {activeTab === 'advanced' && (
        <div className="mt-6">
          {/* Subtabs navigatie */}
          <div className="flex space-x-4 mb-4 border-b border-gray-300 dark:border-gray-700">
            <button
              onClick={() => setActiveSubTab('personality')}
              className={`pb-2 px-4 ${activeSubTab === 'personality' ? 'border-b-4 border-blue-600 text-blue-600' : 'text-gray-500 dark:text-gray-400'}`}
            >
              Personality
            </button>
            <button
              onClick={() => setActiveSubTab('safety')}
              className={`pb-2 px-4 ${activeSubTab === 'safety' ? 'border-b-4 border-blue-600 text-blue-600' : 'text-gray-500 dark:text-gray-400'}`}
            >
              Safety
            </button>
            <button
              onClick={() => setActiveSubTab('knowledge')}
              className={`pb-2 px-4 ${activeSubTab === 'knowledge' ? 'border-b-4 border-blue-600 text-blue-600' : 'text-gray-500 dark:text-gray-400'}`}
            >
              Knowledge
            </button>
            <button
              onClick={() => setActiveSubTab('goals')}
              className={`pb-2 px-4 ${activeSubTab === 'goals' ? 'border-b-4 border-blue-600 text-blue-600' : 'text-gray-500 dark:text-gray-400'}`}
            >
              Goals
            </button>
            <button
              onClick={() => setActiveSubTab('scenes')}
              className={`pb-2 px-4 ${activeSubTab === 'scenes' ? 'border-b-4 border-blue-600 text-blue-600' : 'text-gray-500 dark:text-gray-400'}`}
            >
              Scenes
            </button>
          </div>

          {/* Content voor de actieve subtab */}
          <div>
            {activeSubTab === 'personality' && (
              <PersonalityTab
                personalityAdjectives={personalityAdjectives}
                handleAddPersonalityAdjective={handleAddPersonalityAdjective}
                handleRemovePersonalityAdjective={handleRemovePersonalityAdjective}
                initialMood={initialMood}
                handleMoodChange={handleMoodChange}
                personality={personality}
                handlePersonalityChange={handlePersonalityChange}
              />
            )}

            {activeSubTab === 'safety' && (
              <SafetyConfig
                safetyConfig={safetyConfig}
                handleSafetyChange={handleSafetyChange}
              />
            )}

            {activeSubTab === 'knowledge' && (
              <div>
                <h3 className="text-xl font-bold text-gray-900 dark:text-white">Knowledge & Cognition</h3>
                <Knowledge />
              </div>
            )}

            {activeSubTab === 'goals' && (
              <div>
                <h3 className="text-xl font-bold text-gray-900 dark:text-white">Goals</h3>
                {/* Voeg hier je content toe voor Goals */}
              </div>
            )}

            {activeSubTab === 'scenes' && (
              <div>
                <h3 className="text-xl font-bold text-gray-900 dark:text-white">Scenes</h3>
                {/* Voeg hier je content toe voor Scenes */}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CharacterDetail;
