import React from 'react';
import logo from '../assets/images/Courseta_Logo.png';

const TabNavigation = ({ tabs, activeTab, onTabChange, components, title }) => {
  return (
    <div className="p-4">
      <div className="flex flex-wrap items-center justify-center space-x-1 pt-2"> {/* justify-center added here */}
        <div className="text-gray-700 dark:text-gray-300 font-bold pr-10">
          {title}
        </div>
        {/* Tab navigation */}
        <div className="p-2 flex flex-wrap justify-center bg-white dark:bg-gray-800 rounded-full shadow-sm border border-gray-200 dark:border-gray-700 w-full md:w-auto">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => onTabChange(index)}
              className={`py-2 px-6 md:px-7 rounded-full focus:outline-none transition-colors duration-300 ${activeTab === index ? 'bg-blue-600 text-white' : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'} mr-2 text-base font-medium`} 
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
      <div className="mt-4 pt-10">
        {components[activeTab]}
      </div>
    </div>
  );
};

export default TabNavigation;
