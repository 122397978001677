import React, { useEffect, useState } from "react";
import { useAppData } from "../../contexts/AppDataContext";
import { useUser } from "../../contexts/UserContext";
import { usePopUp } from "../../contexts/PopUpContext";
import { firestore } from "../../firebase";
import { updateDoc, doc } from "firebase/firestore";
import { useNotification } from "../../contexts/NotificationContext";

const ShareScenarioPopup = ({ scenario, onClose }) => {
    const { users } = useAppData();
    const user = useUser();
    const [teachers, setTeachers] = useState([]);
    const [selectedTeachers, setSelectedTeachers] = useState([]);
    const { showNotification } = useNotification();

    useEffect(() => {
        if (users && user) {
            // Filter users to get only teachers from the same school, excluding the current user
            const filteredTeachers = users.filter(u => 
                u.roleName === "Teacher" && 
                u.schoolId === user.schoolId &&
                u.id !== user.id // Exclude the current user from the list
            );

            setTeachers(filteredTeachers);

            // Automatically select teachers who are already in the shared array
            const alreadyShared = scenario.shared || [];
            setSelectedTeachers(alreadyShared);
        }
    }, [users, user, scenario]);

    const handleTeacherSelect = (teacherId) => {
        setSelectedTeachers(prevSelected => 
            prevSelected.includes(teacherId)
                ? prevSelected.filter(id => id !== teacherId) // Deselect
                : [...prevSelected, teacherId] // Select
        );
    };

    const handleShare = async () => {
        const scenarioRef = doc(firestore, 'scenarios', scenario.id);

        try {
            // Add the selected teacher IDs to the scenario's `shared` array
            await updateDoc(scenarioRef, {
                shared: [...new Set([...(scenario.shared || []), ...selectedTeachers])] // Ensure no duplicates
            });
            showNotification('success', "Scenario Shared!");
            onClose(); // Close popup after sharing
        } catch (error) {
            showNotification('succes', "Error sharing scenario");
            console.error("Error sharing scenario: ", error);
        }
    };

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center">
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto bg-white rounded-lg shadow dark:bg-gray-800">
                <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                    <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Share Scenario</h3>
                    <button onClick={onClose} className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                        Close
                    </button>
                </div>
                <div className="mb-4">
                    <h4 className="text-md font-semibold text-gray-900 dark:text-gray-300">Select Teachers to Share</h4>
                    {teachers.length > 0 ? (
                        <div className="max-h-64 overflow-y-auto">
                            {teachers.map(teacher => (
                                <div key={teacher.id} className="flex items-center mb-2">
                                    <input
                                        type="checkbox"
                                        id={`teacher-${teacher.id}`}
                                        checked={selectedTeachers.includes(teacher.id)}
                                        onChange={() => handleTeacherSelect(teacher.id)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label htmlFor={`teacher-${teacher.id}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        {teacher.firstName + ' ' + teacher.lastName} ({teacher.email})
                                    </label>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-sm text-gray-500 dark:text-gray-400">No teachers found in your school.</p>
                    )}
                </div>
                <button
                    onClick={handleShare}
                    className="w-full flex items-center justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300 space-x-2"
                >
                    Share Template
                </button>
            </div>
        </div>
    );
};

export default ShareScenarioPopup;
