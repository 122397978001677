import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../contexts/UserContext'; // Update the import path as needed

const PrivateRoute = ({ roles }) => {
  const user = useUser();

  if (!user) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/signin" />;
  }

  if (roles && roles.length > 0 && !roles.includes(user.roleName)) {
    // Redirect to a "permission denied" page if the user doesn't have the required role
    return <Navigate to="/unauthorized" />;
  }

  // Render the component if the user is authenticated and has the required role
  return <Outlet />;

};

export default PrivateRoute;
