/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import logo from '../../assets/images/Courseta_Logo.png';
import { useNotification } from '../../contexts/NotificationContext';
import { sendPasswordResetEmail, auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      showNotification('success', 'Email was sent!');
    } catch (error) {
      setError(error);
      showNotification('danger', error.message);
    }
  };

  return (
    <section className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
      <div className="flex w-full h-screen max-w-none mx-auto bg-white dark:bg-gray-800">
        <div className="w-1/2 h-full flex items-center justify-center bg-blue-600 rounded-tr-3xl rounded-br-3xl">
          <img className="w-80 h-90" src={logo} alt="Courseta Logo" />
        </div>
        <div className="w-1/2 h-full flex items-center justify-center p-8">
          <div className="w-full max-w-md space-y-4 md:space-y-6">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Forgot your password?
            </h1>
            <p className="font-light text-gray-500 dark:text-gray-400">Don't fret! Just type in your email and we will send you a code to reset your password!</p>
            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold">Error! </strong>
                <span className="block sm:inline">{error}</span>
              </div>
            )}
            <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" onSubmit={handleFormSubmit}>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@youremail.com"
                  required=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Reset password
              </button>
            </form>
            <div className="text-sm font-light text-gray-500 dark:text-gray-400">
              Remember the password again?
              <a href="#" onClick={() => navigate("/signin")} className="font-medium text-blue-600 hover:underline dark:text-blue-500">Click here!</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
