import React from 'react';

const CustomDialogueStyle = ({
    adjectives,
    handleAddAdjective,
    handleRemoveAdjective,
    colloquialism,
    COLLOQUIALISM_OPTIONS,
    handleColloquialismChange,
    handleRemoveColloquialism,
    exampleDialog,
    handleExampleDialogChange,
    generateExampleSentence
}) => (
    <div>
        <h3 className="text-xl font-bold text-gray-900 dark:text-white">Custom Dialogue Style</h3>
        <div className="mb-4">
            <p className="text-gray-900 dark:text-white">{generateExampleSentence()}</p>
        </div>
        <div className="mt-4">
            <div className="flex flex-wrap gap-2 mb-4">
                {adjectives.map((adj, index) => (
                    <div key={index} className="bg-gray-300 dark:bg-gray-700 px-2 py-1 rounded-full text-sm text-gray-900 dark:text-white flex items-center">
                        {adj}
                        <button onClick={() => handleRemoveAdjective(adj)} className="ml-2 text-red-500">&times;</button>
                    </div>
                ))}
                {adjectives.length < 3 && (
                    <input
                        type="text"
                        onKeyDown={handleAddAdjective}
                        className="bg-gray-100 dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg p-2"
                        placeholder="Add adjective"
                    />
                )}
            </div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Colloquialism</label>
            <div className="flex items-center mt-2">
                <select
                    value={colloquialism}
                    onChange={handleColloquialismChange}
                    className="w-full p-3 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white"
                >
                    <option value="">Select colloquialism</option>
                    {COLLOQUIALISM_OPTIONS.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                {colloquialism && (
                    <button onClick={handleRemoveColloquialism} className="ml-2 text-red-500">&times;</button>
                )}
            </div>
            <label htmlFor="exampleDialogue" className="block mt-4 text-sm font-medium text-gray-700 dark:text-gray-300">Example Dialogue</label>
            <textarea
                id="exampleDialogue"
                className="w-full h-64 p-3 mt-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg dark:text-white"
                value={exampleDialog}
                onChange={handleExampleDialogChange}
                placeholder="Provide examples of the type of unique responses your character would give in conversation..."
            />
        </div>
    </div>
);

export default CustomDialogueStyle;
