/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import CreateClassroom from '../courses/CreateClassRooms';
import { usePopUp } from '../../contexts/PopUpContext';
import { useNavigate } from 'react-router';
import { useUser } from '../../contexts/UserContext';
import { Breadcrumb, Button, Dropdown, DropdownItem } from 'flowbite-react';
import {
  HiHome,
  HiOutlineEye,
  HiOutlineTrash,
  HiOutlineDotsVertical
} from "react-icons/hi";
import { useAppData } from '../../contexts/AppDataContext';
import { firestore } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useNotification } from '../../contexts/NotificationContext';
import Pagination from '../../components/Pagination';
import { useOverview } from '../../contexts/OverviewContext';
import SkeletonLoader from '../../components/SkeletonLoader';
import Accordion from '../../components/Accordion';
import { HiX, HiOutlineAcademicCap } from 'react-icons/hi';
import { updateDoc, doc } from 'firebase/firestore';


export default function ClassRoomOverview() {
  const user = useUser();

  return (
    <div>
      {user?.roleName === 'Admin' ? (
        <SchoolCoursesOverview user={user} />
      ) : (
        <CoursesTable user={user} />
      )}
    </div>
  );
}


function AddCourseModal() {
  const { showPopUp } = usePopUp();
  const { addCourse } = useAppData();

  const handleAddCourse = () => {
    showPopUp(<CreateClassroom onClose={() => showPopUp(null)} addCourse={addCourse} />);
  };

  return (
    <button onClick={handleAddCourse} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      Add course
      <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
      </svg>
    </button>
  );
}

const SchoolCoursesOverview = ({ user }) => {
  const [schools, setSchools] = useState([]);
  const [classRooms, setClassRooms] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState(null);
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const {
    searchQuery,
    currentPage,
    itemsPerPage,
    handleSearchChange,
    handlePageChange,
    handleNumItemsPerPageChange,
    setSearchQuery,
    setSortConfig,
    setCurrentPage,
    sortConfig,
    handleSort
  } = useOverview();

  useEffect(() => {
    setSearchQuery('');
    setSortConfig({ key: 'name', direction: 'ascending' });

    return () => {
      setSearchQuery('');
      setSortConfig({ key: 'name', direction: 'ascending' });
    };
  }, [setSearchQuery, setSortConfig, setCurrentPage]);

  const { isLoading, deleteCourse } = useAppData();

  useEffect(() => {
    const fetchSchools = async () => {
      const querySnapshot = await getDocs(collection(firestore, "schools"));
      setSchools(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchSchools();
  }, []);

  useEffect(() => {
    const fetchClassRooms = async () => {
      const querySnapshot = await getDocs(collection(firestore, "classRooms"));
      setClassRooms(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchClassRooms();
  }, []);

  useEffect(() => {
    if (selectedSchoolId) {
      const filtered = classRooms.filter(classRoom => classRoom.schoolID === selectedSchoolId);
      setFilteredCourses(filtered);
    } else {
      setFilteredCourses(classRooms);
    }
  }, [selectedSchoolId, classRooms]);

  useEffect(() => {
    const filtered = searchQuery
      ? classRooms.filter(course =>
        course.className.toLowerCase().includes(searchQuery.toLowerCase()))
      : classRooms;

    setFilteredCourses(filtered);
  }, [searchQuery, classRooms]);

  const handleSchoolSelect = (schoolId) => {
    setSelectedSchoolId(schoolId);
  };

  const handleDeleteCourse = async (course) => {
    try {
      await deleteCourse(course);
      setFilteredCourses(filteredCourses.filter(c => c.id !== course.id));
    } catch (e) {
      showNotification('danger', 'Failed to delete course');
    }
  };

  const sortCourses = (courses, key, direction) => {
    return courses.slice().sort((a, b) => {
      if (key === 'name') {
        if (a.className < b.className) return direction === 'ascending' ? -1 : 1;
        if (a.className > b.className) return direction === 'ascending' ? 1 : -1;
        return 0;
      }
      if (key === 'date') {
        const dateA = new Date(a.creationDate);
        const dateB = new Date(b.creationDate);
        return direction === 'ascending' ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });
  };

  const sortedCourses = sortCourses(filteredCourses, sortConfig.key, sortConfig.direction);

  const totalPages = Math.ceil(sortedCourses.length / itemsPerPage);
  const indexOfLastCourse = currentPage * itemsPerPage;
  const indexOfFirstCourse = indexOfLastCourse - itemsPerPage;
  const currentCourses = sortedCourses.slice(indexOfFirstCourse, indexOfLastCourse);

  const getSchoolName = (schoolID) => {
    const school = schools.find(school => school.id === schoolID);
    return school ? school.name : 'Unknown School';
  };

  return (
    <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
          <div className="flex items-center space-x-2">
            <HiHome className="text-4xl text-gray-700 dark:text-gray-300" />
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">Courses</h1>
          </div>
          <div className="flex space-x-2">
            <AddCourseModal />
            <form className="flex items-center" onSubmit={(e) => e.preventDefault()}>
              <input
                type="search"
                id="default-search"
                className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:text-white dark:border-gray-600 focus:ring-primary-500 focus:border-primary-500"
                placeholder="Search courses"
                onChange={handleSearchChange}
              />
            </form>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
          {isLoading ? (
            <SkeletonLoader type='row' />
          ) : (
            <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('name')}>Course Name</th>
                  <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('date')}>Creation Date</th>
                  <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('date')}>School</th>
                  <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('date')}>NumofMembers</th>
                  <th scope="col" className="px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                {currentCourses.length > 0 ? (
                  currentCourses.map((course) => (
                    <tr key={course.id} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                      <td className="px-6 py-4 text-gray-900 dark:text-white">{course.className}</td>
                      <td className="px-6 py-4 text-gray-900 dark:text-white">{course.creationDate ?? '09/01/2024'}</td>
                      <td className="px-6 py-4 text-gray-900 dark:text-white">{getSchoolName(course.schoolID)}</td>
                      <td className="px-6 py-4 text-gray-900 dark:text-white">{course.attendees ? course.attendees.length : 0}</td>

                      <td className="px-6 py-4 flex items-center space-x-2">
                        <HiOutlineEye
                          className="text-gray-500 dark:text-gray-300 cursor-pointer hover:text-blue-500 dark:hover:text-blue-400 h-6 w-6"
                          onClick={() => navigate(`/main/classroomdetails/${course.id}`)}
                        />
                        <HiOutlineTrash
                          className="text-gray-500 dark:text-gray-300 cursor-pointer hover:text-red-500 dark:hover:text-red-400 h-6 w-6"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (window.confirm(`Are you sure you want to delete "${course.className}"?`)) {
                              handleDeleteCourse(course);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center py-4">No courses available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div className="flex justify-center items-center mt-4 p-6">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            numItemsPerPage={itemsPerPage}
            onNumItemsPerPageChange={handleNumItemsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
};


function CoursesTable({ user }) {
  const { courses, introductionCourse, addCourse } = useAppData();
  const { showPopUp } = usePopUp();
  const [activeTab, setActiveTab] = useState('all'); // Default tab
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [searchQuery, setSearchQuery] = useState('');

  const INTRODUCTION_COURSE_ID = "66Fqzp90Cib8lmwCN3QZ";

  const coursesWithoutIntroduction = courses.filter(course => course?.id !== INTRODUCTION_COURSE_ID);
  const publishedCourses = coursesWithoutIntroduction.filter(course => course.published);
  const unpublishedCourses = coursesWithoutIntroduction.filter(course => !course.published);

  useEffect(() => {
    filterCourses();
  }, [activeTab, courses, searchQuery]);

  const filterCourses = () => {
    const searchFilter = course => course.className.toLowerCase().includes(searchQuery.toLowerCase());

    let filtered = coursesWithoutIntroduction.filter(searchFilter);

    if (activeTab === 'published') {
      filtered = filtered.filter(course => course.published);
    } else if (activeTab === 'unpublished' && user?.roleName !== 'Student') {
      filtered = filtered.filter(course => !course.published);
    } else if (activeTab === 'resources') {
      filtered = courses.filter(course => course.id === INTRODUCTION_COURSE_ID);
    }

    setFilteredCourses(filtered);
  };

  const getAllCoursesCount = () => {
    return user?.roleName === 'Student' ? publishedCourses.length : coursesWithoutIntroduction.length;
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
        <div className="flex items-center space-x-2">
          <HiOutlineAcademicCap className="text-4xl text-gray-700 dark:text-gray-300" />
          <h1 className="pl-5 text-2xl font-semibold text-gray-900 dark:text-white">Courses</h1>
        </div>
      </div>

      {/* Tabs with counts */}
      <div className="flex mb-4 space-x-8 border-b-2 border-gray-200">
        <button
          className={`px-4 pb-2 text-lg font-medium ${activeTab === 'all' ? 'text-blue-500 border-b-4 border-blue-500' : 'text-gray-600'} dark:text-white`}
          onClick={() => setActiveTab('all')}
        >
          All Courses ({getAllCoursesCount()})
        </button>
        {/* Only show the "Published" tab if the user is not a Student */}
        {user?.roleName !== 'Student' && (
          <button
            className={`px-4 pb-2 text-lg font-medium ${activeTab === 'published' ? 'text-blue-500 border-b-4 border-blue-500' : 'text-gray-600'} dark:text-white`}
            onClick={() => setActiveTab('published')}
          >
            Published ({publishedCourses.length})
          </button>
        )}
        {/* Only show the "Unpublished" tab if the user is not a Student */}
        {user?.roleName !== 'Student' && (
          <button
            className={`px-4 pb-2 text-lg font-medium ${activeTab === 'unpublished' ? 'text-blue-500 border-b-4 border-blue-500' : 'text-gray-600'} dark:text-white`}
            onClick={() => setActiveTab('unpublished')}
          >
            Unpublished ({unpublishedCourses.length})
          </button>
        )}
     
      </div>

      {/* Accordions for Published and Unpublished Courses */}
      {activeTab === 'all' && (
        <>
          {/* Published Courses Accordion */}
          <Accordion
            title={"Published Courses"}
            isOpenByDefault={true}
            content={
              <div className="my-10 p-5 bg-white dark:bg-gray-800 shadow rounded-lg">
                <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white mb-2">Public Courses</h1>
                <p className="text-lg text-gray-500 dark:text-gray-400 mb-4">
                  These courses are published, which means all participants can see and use them.
                </p>
                {publishedCourses.length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {publishedCourses.map(course => (
                      <CourseCard
                        key={course.id}
                        courseTitle={course.className}
                        course={course}
                        user={user}
                      />
                    ))}
                    <AddNewCourseCard showPopUp={showPopUp} addCourse={addCourse} user={user} />
                  </div>
                ) : (
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    <AddNewCourseCard showPopUp={showPopUp} addCourse={addCourse} user={user} />
                  </div>
                )}
              </div>
            }
          />
          {/* Unpublished Courses Accordion, only for non-Students */}
          {user?.roleName !== 'Student' && (
            <Accordion
              title={"Unpublished Courses"}
              isOpenByDefault={false}
              content={
                <div className="my-10 p-5 bg-white dark:bg-gray-800 shadow rounded-lg">
                  <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white mb-2">Drafted Courses</h1>
                  <p className="text-lg text-gray-500 dark:text-gray-400 mb-4">
                    These courses are drafts, which means they are not yet published.
                  </p>
                  {unpublishedCourses.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                      {unpublishedCourses.map(course => (
                        <CourseCard
                          key={course.id}
                          courseTitle={course.className}
                          course={course}
                          user={user}
                        />
                      ))}
                      <AddNewCourseCard showPopUp={showPopUp} addCourse={addCourse} user={user} />
                    </div>
                  ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                      <AddNewCourseCard showPopUp={showPopUp} addCourse={addCourse} user={user} />
                    </div>
                  )}
                </div>
              }
            />
          )}
        </>
      )}

      {/* Published Tab: Only show Published accordion */}
      {activeTab === 'published' && (
        <Accordion
          title={"Published Courses"}
          isOpenByDefault={true}
          content={
            <div className="my-10 p-5 bg-white dark:bg-gray-800 shadow rounded-lg">
              <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white mb-2">Public Courses</h1>
              <p className="text-lg text-gray-500 dark:text-gray-400 mb-4">
                These courses are published, which means all participants can see and use them.
              </p>
              {publishedCourses.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {publishedCourses.map(course => (
                    <CourseCard
                      key={course.id}
                      courseTitle={course.className}
                      course={course}
                      user={user}
                    />
                  ))}
                  <AddNewCourseCard showPopUp={showPopUp} addCourse={addCourse} user={user} />
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  <AddNewCourseCard showPopUp={showPopUp} addCourse={addCourse} user={user} />
                </div>
              )}
            </div>
          }
        />
      )}

      {/* Unpublished Tab: Only show Unpublished accordion, only for non-Students */}
      {activeTab === 'unpublished' && user?.roleName !== 'Student' && (
        <Accordion
          title={"Unpublished Courses"}
          isOpenByDefault={true}
          content={
            <div className="my-10 p-5 bg-white dark:bg-gray-800 shadow rounded-lg">
              <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white mb-2">Drafted Courses</h1>
              <p className="text-lg text-gray-500 dark:text-gray-400 mb-4">
                These courses are drafts, which means they are not yet published.
              </p>
              {unpublishedCourses.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {unpublishedCourses.map(course => (
                    <CourseCard
                      key={course.id}
                      courseTitle={course.className}
                      course={course}
                      user={user}
                    />
                  ))}
                  <AddNewCourseCard showPopUp={showPopUp} addCourse={addCourse} user={user} />
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  <AddNewCourseCard showPopUp={showPopUp} addCourse={addCourse} user={user} />
                </div>
              )}
            </div>
          }
        />
      )}
    </div>
  );
}



const AddNewCourseCard = ({ showPopUp, addCourse, user }) => {
  if (user?.roleName === 'Student') {
    return null;
  }

  return (
    <div
      onClick={() => showPopUp(<CreateClassroom onClose={() => showPopUp(null)} addCourse={addCourse} />)}
      className="cursor-pointer rounded-lg bg-white shadow dark:bg-gray-800 p-4 sm:p-6 xl:p-8 flex flex-col items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-700"
    >
      <div className="flex-grow flex items-center justify-center">
        <span className="text-blue-500 text-6xl">+</span>
      </div>
      <div className="mt-4 text-center">
        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Add New Course</h3>
      </div>
    </div>
  );
};

export const CourseCard = ({ course, courseTitle, user, isIntroCourse = false }) => {
  const navigate = useNavigate();
  const { deleteCourse, getUserData } = useAppData();
  const { showPopUp } = usePopUp();
  const [adminName, setAdminName] = useState("No Admin for this Course");

  useEffect(() => {
    if (!isIntroCourse && course?.admin) {
      let admin = getUserData(course?.admin);

      if (admin) {
        setAdminName(`${admin.firstName} ${admin.lastName}`);
      } else {
        setAdminName("No Admin Found");
      }
    } else if (isIntroCourse) {
      setAdminName("CoursetaVR");
    }
  }, [course, getUserData, isIntroCourse]);

  const isIntroductionCourse = course?.id === "66Fqzp90Cib8lmwCN3QZ";

  const handleEditThumbnail = () => {
    showPopUp(
      <CourseThumbnailSettingsPopup
        courseTitle={course.className}
        initialColor={course.thumbnailColor || '#808080'}
        courseId={course.id}
        onClose={() => showPopUp(null)}
      />
    );
  };

  return (
    <div className="rounded-lg bg-white shadow dark:bg-gray-800 p-4 sm:p-6 xl:p-8">
      <div className="flex-grow">
        <span className="text-2xl font-bold leading-none text-gray-900 dark:text-white sm:text-3xl">
          {course?.className || "Course Title"}
        </span>
        {isIntroductionCourse ? (
          <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
            Learn how you can use the Web Portal and VR Simulation!
          </h3>
        ) : null}
      </div>

      <div
        className="rounded-lg w-full h-48 mt-4"
        style={{ backgroundColor: course.thumbnailColor || '#808080' }}
      />

      <div className="flex justify-between items-center mt-4">
        <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
          {adminName}
        </p>

        {!isIntroCourse && (
          <div className="flex items-center space-x-4">
            <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
              {course?.attendees?.length || 0} Attendees
            </span>

            {user?.roleName !== 'Student' && (
              <Dropdown arrowIcon={false} label={<HiOutlineDotsVertical className="w-6 h-6 text-gray-600 dark:text-gray-300" />} inline>
                <DropdownItem onClick={() => navigate(`/main/classroomdetails/${course.id}`)}>View Course</DropdownItem>
                {(!isIntroductionCourse || user?.roleName === "Admin") && (
                  <DropdownItem onClick={handleEditThumbnail}>Edit Course Thumbnail</DropdownItem>
                )}
                {!isIntroductionCourse && (
                  <DropdownItem
                    onClick={() => {
                      if (window.confirm(`Are you sure you want to delete "${course.className}"?`)) {
                        deleteCourse(course);
                      }
                    }}
                  >
                    Delete
                  </DropdownItem>
                )}
              </Dropdown>
            )}
          </div>
        )}
      </div>

      <div className="mt-4">
        <button
          onClick={() => navigate(`/main/classroomdetails/${course.id}`)}
          className="w-full flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium mt-4"
        >
          View Course
        </button>
      </div>
    </div>
  );
};


const CourseThumbnailSettingsPopup = ({ onClose, courseTitle, initialColor, courseId }) => {
  const [title] = useState(courseTitle || '');
  const { updateCourseThumbnail } = useAppData();
  const { showNotification } = useNotification();
  const [selectedColor, setSelectedColor] = useState(initialColor || '#808080');

  const colorOptions = [
    '#FF0000', '#FF7F00', '#FFD700', '#808080', '#008000', '#0000FF',
    '#800080', '#FF1493', '#4B0082', '#FFDAB9', '#A9A9A9', '#FF4500',
  ];

  const handleSave = async () => {
    try {
      const courseRef = doc(firestore, 'classRooms', courseId);
      await updateDoc(courseRef, {
        thumbnailColor: selectedColor,
      });
      await updateCourseThumbnail(courseId, selectedColor);
      showNotification('success', 'Thumbnail updated successfully!');
      onClose();
    } catch (error) {
      console.error('Error updating course thumbnail:', error);
      showNotification('danger', 'Error updating course thumbnail.');
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="relative bg-white rounded-lg shadow-lg w-full max-w-md p-6">
        <div className="flex justify-between items-center border-b pb-3">
          <h2 className="text-lg font-semibold">Course Thumbnail Settings</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <HiX className="w-5 h-5" />
          </button>
        </div>

        <div className="mt-4 flex flex-col space-y-4">
          <div>
            <label htmlFor="colorPicker" className="block text-sm font-medium text-gray-700">
              Choose a Color
            </label>
            <div className="flex flex-wrap gap-2 mt-2">
              {colorOptions.map((color, index) => (
                <button
                  key={index}
                  className={`w-8 h-8 rounded-full ${selectedColor === color ? 'ring-4 ring-blue-500' : ''}`}
                  style={{ backgroundColor: color }}
                  onClick={() => setSelectedColor(color)}
                />
              ))}
            </div>
          </div>

          <div>
            <label htmlFor="courseTitle" className="block text-sm font-medium text-gray-700">
              Course Title
            </label>
            <input
              type="text"
              id="courseTitle"
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md bg-gray-100"
              value={title}
              readOnly
            />
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <button
            className="text-gray-600 px-4 py-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded-md"
            onClick={handleSave}
          >
            Save Details
          </button>
        </div>
      </div>
    </div>
  );
};
