import React from 'react';

const PersonalityTab = ({
    personalityAdjectives,
    handleAddPersonalityAdjective,
    handleRemovePersonalityAdjective,
    initialMood,
    handleMoodChange,
    personality,
    handlePersonalityChange,
}) => (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6 flex justify-center">
        <div className="max-w-4xl w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <h3 className="text-xl font-bold text-gray-900 dark:text-gray-50 mb-4">Personality</h3>

            {/* Character traits input */}
            <div className="mb-6">
                <label className="block text-lg font-semibold text-gray-900 dark:text-gray-50 mb-2">Character traits</label>
                <div className="relative">
                    <input
                        type="text"
                        onKeyDown={handleAddPersonalityAdjective}
                        className="w-full p-2 bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-gray-200"
                        placeholder="Enter a few adjectives describing the character: e.g., 'funny', 'arrogant', 'kind-hearted'"
                    />
                    <div className="flex flex-wrap gap-2 mt-2">
                        {personalityAdjectives.map((adj, index) => (
                            <div
                                key={index}
                                className="bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-gray-200 px-3 py-1 rounded-full flex items-center"
                            >
                                {adj}
                                <button onClick={() => handleRemovePersonalityAdjective(adj)} className="ml-2 text-red-500">&times;</button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Mood and Personality sliders */}
            <div className="grid grid-cols-2 gap-8 mb-8">
                <div>
                    <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-50 mb-2">Mood</h4>
                    {[
                        ['joy', 'Sadness', 'Joy'],
                        ['fear', 'Anger', 'Fear'],
                        ['trust', 'Disgust', 'Trust'],
                        ['surprise', 'Anticipation', 'Surprise'],
                    ].map(([moodKey, leftLabel, rightLabel]) => (
                        <div key={moodKey} className="mb-6">
                            <div className="flex justify-between mb-1">
                                <span className="text-sm text-gray-600 dark:text-gray-400">{leftLabel}</span>
                                <span className="text-sm text-gray-600 dark:text-gray-400">{rightLabel}</span>
                            </div>
                            <input
                                type="range"
                                min="-100"
                                max="100"
                                step="25"
                                value={initialMood[moodKey] || 0}
                                onChange={(e) => handleMoodChange(moodKey, e.target.value)}
                                className="w-full"
                            />
                        </div>
                    ))}
                </div>

                <div>
                    <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-50 mb-2">Personality Traits</h4>
                    {[
                        ['positive', 'Negative', 'Positive'],
                        ['peaceful', 'Aggressive', 'Peaceful'],
                        ['open', 'Cautious', 'Open'],
                        ['extravert', 'Introvert', 'Extravert'],
                    ].map(([traitKey, leftLabel, rightLabel]) => (
                        <div key={traitKey} className="mb-6">
                            <div className="flex justify-between mb-1">
                                <span className="text-sm text-gray-600 dark:text-gray-400">{leftLabel}</span>
                                <span className="text-sm text-gray-600 dark:text-gray-400">{rightLabel}</span>
                            </div>
                            <input
                                type="range"
                                min="-100"
                                max="100"
                                step="25"
                                value={personality[traitKey] || 0}
                                onChange={(e) => handlePersonalityChange(traitKey, e.target.value)}
                                className="w-full"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
);

export default PersonalityTab;
