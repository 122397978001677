// PopUpContext.js
import { createContext, useState, useContext } from 'react';

const PopUpContext = createContext();

export const usePopUp = () => useContext(PopUpContext);

export const PopUpProvider = ({ children }) => {
  const [popUpContent, setPopUpContent] = useState(null);
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);

  const showPopUp = (content) => {
    setPopUpContent(content);
    setIsPopUpVisible(true);
  };

  const hidePopUp = () => {
    setIsPopUpVisible(false);
  };

  return (
    <PopUpContext.Provider value={{ isPopUpVisible, showPopUp, hidePopUp, popUpContent }}>
      {children}
    </PopUpContext.Provider>
  );
};
