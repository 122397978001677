import React, { useEffect, useState } from 'react';
import Accordion from '../../components/Accordion';
import { useAppData } from '../../contexts/AppDataContext';
import { CourseCard } from '../courses/ClassRoomOverview';
import { useUser } from '../../contexts/UserContext';

export default function ResourcesPage() {
  const { courses, introductionCourse } = useAppData(); 
  const INTRODUCTION_COURSE_ID = "66Fqzp90Cib8lmwCN3QZ"; 
  const user = useUser();
  const [resources, setResources] = useState([]);

  useEffect(() => {
    const resourceCourses = courses.filter(course => course.id === INTRODUCTION_COURSE_ID);
    setResources(resourceCourses);
  }, [courses]);

  return (
    <div className="bg-white dark:bg-gray-900 min-h-screen p-6">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">Resources Library</h1>
        <p className="text-lg text-gray-600 dark:text-gray-400 mb-6">
          Here you can find important resources and learn how to use all the features Courseta VR has to offer. 
          Select a resource to view its content.
        </p>
        {/* <Accordion
          title="My Resource Library"
          isOpenByDefault={true}
          content={ */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {resources.map(resource => (
                <CourseCard
                  key={resource.id}
                  course={resource}
                  courseTitle={resource.className}
                  user={user}
                />
              ))}
            </div>
          {/* }
        /> */}
      </div>
    </div>
  );
}
