import React, { useEffect, useState } from 'react';
import defaultImage from '../../assets/images/22.png';
import { usePopUp } from '../../contexts/PopUpContext';
import InviteUser from '../creators/CreateUser';
import UserInfo from '../user_profile/UserInfo_Popup';
import { useUser } from '../../contexts/UserContext';
import { useAppData } from '../../contexts/AppDataContext';
import { HiUsers, HiPlus, HiOutlineTrash, HiOutlineEye } from 'react-icons/hi';
import { Table } from 'flowbite-react';
import Pagination from '../../components/Pagination';
import { useOverview } from '../../contexts/OverviewContext';
import { useAuthStatus, getUserData } from '../../services/GetUserData';
import SkeletonLoader from '../../components/SkeletonLoader';
import { useNotification } from '../../contexts/NotificationContext';
import MultiInviteUser from '../creators/CreateMultipleUsers';
import CourseSelectionPopup from '../creators/AddMultiplePeopleToClassroom';
import { doc, getFirestore, getDoc } from 'firebase/firestore';
import { addDocument } from '../../services/FirebaseService';
import { authMail } from '../../emails/Auth_mail';

export default function UsersOverview() {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredPendingUsers, setFilteredPendingUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]); // State to track selected users
  const [showPending, setShowPending] = useState(false); // State to toggle between users and pending registrations
  const { users, pendingUsers, setPendingUsers, deleteUser, isLoading, deletePendingUser } = useAppData();
  const { showPopUp } = usePopUp();
  const user = useUser();
  const { showNotification } = useNotification();
  const [inviteUrl, setInviteUrl] = useState('');
  const {
    searchQuery,
    currentPage,
    itemsPerPage,
    handleSearchChange,
    handlePageChange,
    handleNumItemsPerPageChange,
    setSearchQuery,
    setSortConfig,
    setCurrentPage
  } = useOverview();

  useEffect(() => {
    setSearchQuery('');
    setSortConfig({ key: 'name', direction: 'ascending' });

    return () => {
      setSearchQuery('');
      setSortConfig({ key: 'name', direction: 'ascending' });
    };
  }, [setSearchQuery, setSortConfig, setCurrentPage]);

  const [currentUserData, setCurrentUserData] = useState(null);
  const { currentUser, fetchingData } = useAuthStatus();

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        try {
          const data = await getUserData(currentUser.uid);
          setCurrentUserData(data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();
  }, [currentUser]);

  useEffect(() => {
    if (!currentUserData) return;

    if (!showPending) {
      // Filter confirmed users (from users list)
      const filters = searchQuery
        ? users.filter(
          (user) =>
            user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.lastName.toLowerCase().includes(searchQuery.toLowerCase())
        )
        : users;
      setFilteredUsers(filters);
    } else {
      // Filter pending users (from pendingUsers list)
      let filteredPending;
      if (currentUserData.roleName === 'Teacher') {
        filteredPending = pendingUsers.filter(
          (pendingUser) => pendingUser.schoolID === currentUserData.schoolID
        );
      } else {
        filteredPending = pendingUsers;
      }

      const filters = searchQuery
        ? filteredPending.filter(
          (pendingUser) =>
            pendingUser.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            pendingUser.lastName.toLowerCase().includes(searchQuery.toLowerCase())
        )
        : filteredPending;

      setFilteredPendingUsers(filters);
    }
  }, [users, pendingUsers, searchQuery, showPending, currentUserData]);

  const handleAddUserClick = () => {
    showPopUp(<InviteUser onClose={() => showPopUp(null)} currentUser={user} />);
  };

  const handleAddMultipleUserClick = () => {
    showPopUp(<MultiInviteUser onClose={() => showPopUp(null)} currentUser={user} />);
  };

  const handleShowUserClick = (userId) => {
    const userToShow = showPending
      ? pendingUsers.find((pendingUser) => pendingUser.id === userId)
      : users.find((user) => user.id === userId);

    if (userToShow) {
      showPopUp(<UserInfo user={userToShow} onClose={() => showPopUp(null)} currentUserRole={user.roleName} />);
    } else {
      showNotification('danger', 'User not found.');
    }
  };


  // Aangepaste handleDelete voor meerdere gebruikers
  const handleDelete = (userIds) => {
    const usersToDelete = displayUsers.filter(user => userIds.includes(user.id));

    usersToDelete.forEach(user => {
      if (!showPending && currentUserData.roleName === 'Teacher') {
        showNotification("danger", "You don't have permission for this action. Please contact the Admin.");
        return;
      }

      if (showPending) {
        showNotification("info", `Deleting pending user: ${user.firstName} ${user.lastName}`);
        setPendingUsers(prevPendingUsers => prevPendingUsers.filter(u => u.id !== user.id));
        deletePendingUser(user);
      } else {
        showNotification("success", `Deleting user: ${user.firstName} ${user.lastName}`);
        deleteUser(user);
      }
    });

    // Clear selected users after deletion
    setSelectedUsers([]);
  };

  // Determine which users to display based on the view
  const displayUsers = showPending ? filteredPendingUsers : filteredUsers;

  // Pagination logic
  const totalPages = Math.ceil(displayUsers.length / itemsPerPage);
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = displayUsers.slice(indexOfFirstUser, indexOfLastUser);

  // Handle checkbox selection
  const handleCheckboxChange = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allUserIds = currentUsers.map((user) => user.id);
      setSelectedUsers(allUserIds);
    } else {
      setSelectedUsers([]);
    }
  };

  const getGeneratedLink = async (user) => {
    try {
      // Directly generate the registration link without checking again
      const registrationLink = `http://coursetavr.com/#/register?token=${user.id}`;
      const actionCodeSettings = {
        url: registrationLink,
        handleCodeInApp: true,
      };

      // showNotification('success', 'Invitation link has been generated!');

      return registrationLink;
    } catch (error) {
      console.error('Error generating invitation link:', error);
      showNotification('danger', 'Error generating the invitation link.');
      return null;
    }
  };


  const getInviteUrl = async (user) => {
    const generatedLink = await getGeneratedLink(user); // Pass user to generate the link
    setInviteUrl(generatedLink);
    return generatedLink;
  };

  const handleResendInviteClick = async (event) => {
    event.preventDefault();

    if (selectedUsers.length === 0) {
      showNotification('danger', 'No users selected.');
      return;
    }

    if (!showPending) {
      showNotification('danger', 'This action is only available for pending users.');
      return;
    }

    //console.log("Selected Users:", selectedUsers); // Log selected users

    for (const userId of selectedUsers) {
      try {
        // Fetch the user from pendingUsers
        const user = pendingUsers.find((u) => u.id === userId);

        if (!user) {
          showNotification('danger', 'Pending user not found.');
          continue;
        }

        //console.log(`Fetching Firestore document for user ID: ${user.id}`); // Log the ID being fetched

        // Try fetching the pending registration from Firestore
        const userDocRef = doc(getFirestore(), 'pendingRegistrations', user.id);
        const userDocSnap = await getDoc(userDocRef);

        //console.log("Firestore Query Response:", userDocSnap); // Log the Firestore response

        if (!userDocSnap.exists()) {
          console.error(`No pending registration found for user: ${user.firstName} ${user.lastName}`);
          showNotification('danger', `No pending invitation found for ${user.firstName} ${user.lastName}`);
          continue;
        }

        //console.log("Firestore document found:", userDocSnap.data()); // Log the Firestore document if found

        const url = await getInviteUrl(user); // Pass the user object to generate the invite URL
        if (!url) {
          showNotification('danger', `Failed to generate the invitation link for ${user.firstName} ${user.lastName}.`);
          continue;
        }

        //console.log("Generated Invitation URL:", url); // Log the generated URL

        const htmlcontent = authMail(user.firstName, url);

        const emailData = {
          to: [{ email: user.email, name: user.firstName }],
          from: { email: 'info@coursetavr.com', name: 'CoursetaVR' },
          subject: 'Create your account',
          html: htmlcontent,
        };

        //console.log("Email data prepared:", emailData); // Log the email data

        const documentId = await addDocument(emailData, 'emails');
        //console.log(`Email scheduled for ${user.firstName} with document ID:`, documentId);
        showNotification('success', `Email sent successfully to ${user.firstName} ${user.lastName}!`);
      } catch (error) {
        console.error(`Error sending email to ${user.firstName} ${user.lastName}:`, error);
        showNotification('danger', `Failed to send email to ${user.firstName} ${user.lastName}.`);
      }
    }
  };



  const handleAddToCourseClick = () => {
    showPopUp(
      <CourseSelectionPopup
        selectedUsers={selectedUsers}
        onClose={() => showPopUp(null)} // Close the popup when done
      />
    );
  };

  return (
    <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
          <div className="flex items-center space-x-2">
            <HiUsers className="text-4xl text-gray-700 dark:text-gray-300" />
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
              {showPending ? 'Pending Registrations' : 'Users'}
            </h1>
          </div>

          <div className="flex space-x-2">
            <button
              onClick={() => setShowPending(false)}
              className={`px-4 py-2 rounded-lg font-medium shadow-sm ${!showPending ? 'bg-blue-600 text-white' : 'bg-white text-gray-700 dark:bg-gray-700 dark:text-white'}`}
            >
              Users
            </button>
            {currentUserData && currentUserData.roleName !== 'Student' && (
              <button
                onClick={() => setShowPending(true)}
                className={`px-4 py-2 rounded-lg font-medium shadow-sm ${showPending ? 'bg-blue-600 text-white' : 'bg-white text-gray-700 dark:bg-gray-700 dark:text-white'}`}
              >
                Pending Registrations
              </button>
            )}
          </div>
        </div>

        <div className="flex justify-between items-center mb-4">
          <form className="flex items-center" onSubmit={(e) => e.preventDefault()}>
            <input
              type="search"
              id="default-search"
              className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:text-white dark:border-gray-600 focus:ring-primary-500 focus:border-primary-500"
              placeholder="Search users"
              onChange={handleSearchChange}
            />
          </form>

          {selectedUsers.length === 0 && currentUserData && currentUserData.roleName !== 'Student' && (
            <div className="flex space-x-2"> {/* Adjusted to make buttons horizontal */}
              <button
                onClick={handleAddUserClick}
                className="flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
              >
                <HiPlus className="h-5 w-5 mr-2" />
                Invite User
              </button>
              <button
                onClick={handleAddMultipleUserClick}
                className="flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
              >
                <HiPlus className="h-5 w-5 mr-2" />
                Invite Multiple
              </button>
            </div>
          )}

          {selectedUsers.length > 0 && (
            <div className="flex space-x-2">
              {showPending && (
                <button
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg font-medium"
                  onClick={handleResendInviteClick}
                >
                  Resend Registration Invite
                </button>
              )}
              <br></br>
              <button
                className="bg-blue-600 text-white px-4 py-2 rounded-lg font-medium"
                onClick={handleAddToCourseClick}
              >
                Add to Course
              </button>

              <button
                className="bg-red-600 text-white px-4 py-2 rounded-lg font-medium"
                onClick={() => handleDelete(selectedUsers)}
              >
                Delete Selected
              </button>
            </div>
          )}
        </div>

        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
          {isLoading ? (
            <SkeletonLoader type="row" />
          ) : (
            <Table hoverable={true}>
              <Table.Head>
                {['Admin', 'Teacher'].includes(currentUserData?.roleName) && (
                  <Table.HeadCell>
                    <input type="checkbox" onChange={handleSelectAll} />
                  </Table.HeadCell>
                )}
                <Table.HeadCell className="text-gray-700 dark:text-gray-300">User</Table.HeadCell>
                <Table.HeadCell className="text-gray-700 dark:text-gray-300">School</Table.HeadCell>
                <Table.HeadCell className="text-gray-700 dark:text-gray-300">Role</Table.HeadCell>
                <Table.HeadCell className="text-gray-700 dark:text-gray-300">Date Joined</Table.HeadCell>
                <Table.HeadCell className="text-gray-700 dark:text-gray-300">Actions</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y divide-gray-200 dark:divide-gray-700">
                {currentUsers.map((user) => (
                  <Table.Row key={user.id} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                    {['Admin', 'Teacher'].includes(currentUserData?.roleName) && (
                      <Table.Cell>
                        <input
                          type="checkbox"
                          className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                          checked={selectedUsers.includes(user.id)}
                          onChange={() => handleCheckboxChange(user.id)}
                        />
                      </Table.Cell>
                    )}
                    <Table.Cell className="flex items-center space-x-4">
                      <img src={user.photo_url || defaultImage} alt={`${user.firstName} ${user.lastName}`} className="h-10 w-10 rounded-full object-cover" />
                      <div>
                        <div className="text-sm font-medium text-gray-900 dark:text-white">
                          {`${user.firstName} ${user.lastName}`}
                        </div>
                        <div className="text-sm text-gray-500 dark:text-gray-300">{user.email}</div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <span className="text-sm text-gray-900 dark:text-white">{user.schoolName || 'N/A'}</span>
                    </Table.Cell>
                    <Table.Cell>
                      {showPending ? (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300">
                          Pending
                        </span>
                      ) : (
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.roleName.trim() === 'Teacher'
                          ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                          : user.roleName.trim() === 'Student'
                            ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300'
                            : user.roleName.trim() === 'Admin'
                              ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300'
                              : 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300'
                          }`}>
                          {user.roleName.trim() || 'N/A'}
                        </span>
                      )}
                    </Table.Cell>
                    <Table.Cell className="text-sm text-gray-500 dark:text-gray-300">
                      {showPending ? 'In progress' : user.datejoined ?? '09/01/2024'}
                    </Table.Cell>
                    <Table.Cell className="flex items-center space-x-2">
                      <HiOutlineEye
                        className="text-gray-500 dark:text-gray-300 cursor-pointer hover:text-blue-500 dark:hover:text-blue-400 h-6 w-6"
                        onClick={() => handleShowUserClick(user.id)}
                      />
                      {currentUserData && currentUserData.roleName !== 'Student' && (
                        <HiOutlineTrash
                          className="text-gray-500 dark:text-gray-300 cursor-pointer hover:text-red-500 dark:hover:text-red-400 h-6 w-6"
                          onClick={() => {
                            if (window.confirm(`Are you sure you want to delete ${user.firstName} ${user.lastName}?`)) {
                              handleDelete([user.id]); // Delete single user from Actions column
                            }
                          }}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </div>

        <div className="flex justify-center items-center mt-4 p-6">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            numItemsPerPage={itemsPerPage}
            onNumItemsPerPageChange={handleNumItemsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
}
