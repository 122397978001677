import React, { useState, useCallback } from "react";
import { usePopUp } from "../../../contexts/PopUpContext";
import { useDropzone } from 'react-dropzone'

import { HiClipboard, HiDocumentText } from "react-icons/hi";

export default function UploadDocument({ handleUploadFiles }) {
    const { showPopUp } = usePopUp();

    const [files, setFiles] = useState([]);

    const onDrop = useCallback(acceptedFiles => {
        setFiles((prev) => [...prev, ...acceptedFiles]);
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    if (!module) {
        showPopUp(null);
    }

    return (
        <div className={`fixed inset-0 z-50 overflow-y-auto flex items-center justify-center`}>
            <div className="flex items-center justify-center min-h-screen relative p-4 w-full max-w-2xl h-full md:h-auto">
                <div className="relative p-4 w-full max-w-2xl h-full md:h-auto bg-white rounded-lg shadow dark:bg-gray-800">
                    <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                        <div>
                            <h3 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                                Upload Document
                            </h3>
                            <p class="mb-4 text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                Select or drag a file
                            </p>
                        </div>
                        <button onClick={() => showPopUp(null)} data-collapse-toggle="newsletter-pop-up" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" id="close-modal">
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </button>
                    </div>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            <div class="flex items-center justify-center w-full">
                                <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                        </svg>
                                        {!isDragActive ? <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                            : <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Drop here</span></p>
                                        }
                                        <p class="text-xs text-gray-500 dark:text-gray-400">PDF (Max. 150MB)</p>
                                    </div>
                                </label>
                            </div>
                        }
                    </div>
                    <div className="m-4">
                        {files?.map(
                            (file) => {
                                return (
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <HiDocumentText className="mr-2" />
                                            <p>{file.name}</p>
                                        </div>
                                        <button onClick={(e) => { setFiles(files.filter(f => f.name !== file.name)) }} >X</button>
                                    </div>
                                );
                            }
                        )}
                    </div>
                    <button
                        onClick={() => { showPopUp(null); handleUploadFiles(files); }}
                        className="p-5 mt-5 w-full justify-center text-white bg-blue-500 rounded-md hover:bg-blue-700"
                    >
                        Upload
                    </button>
                </div>
            </div>
        </div>
    );
}