import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { usePopUp } from '../contexts/PopUpContext';
import { Link } from 'react-router-dom';

const CharacterOverview = () => {
  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCharacters, setFilteredCharacters] = useState([]);
  const { showPopUp } = usePopUp(); // Use the popup context
  const [activeTab, setActiveTab] = useState('characters'); // Subtabs state

  const defaultCharacterImage = 'https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png';

  useEffect(() => {
    const fetchCharacters = async () => {
      try {
        const functions = getFunctions();
        const getCharacters = httpsCallable(functions, 'getCharacters');
        const result = await getCharacters();

        const characterData = result.data.map((character) => {
          const givenName = character?.defaultCharacterDescription?.givenName || 'Unnamed';
          const avatarImg = character?.defaultCharacterAssets?.avatarImg || defaultCharacterImage;
          const description = character?.defaultCharacterDescription?.description || 'No description provided';
          return { givenName, avatarImg, description, characterId: character.name };
        });

        setCharacters(characterData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCharacters();
  }, []);

  useEffect(() => {
    setFilteredCharacters(
      characters.filter(character =>
        character.givenName.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [characters, searchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCreateCharacterClick = () => {
    showPopUp(<CreateCharacterPopup onClose={() => showPopUp(null)} />);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'characters':
        return renderCharacterGrid();
      case 'knowledge':
        return <div>Knowledge content goes here...</div>;
      case 'scenes':
        return <div>Scenes content goes here...</div>;
      case 'voice':
        return <div>Voice content goes here...</div>;
      default:
        return null;
    }
  };

  const renderCharacterGrid = () => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
        {filteredCharacters.length > 0 ? (
          filteredCharacters.map((character, index) => {
            const characterId = character.characterId.split('/').pop(); // Get the last part of the 'name'
            return (
              <div key={index} className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between">
                <div className="w-full h-full bg-gray-300 rounded-lg mb-4">
                  <img src={character.avatarImg} alt={character.givenName} className="object-cover w-full h-full rounded-lg" />
                </div>
                <h3 className="text-lg font-bold mb-2 text-gray-900 dark:text-white">{character.givenName}</h3>
                <Link to={`/main/characterDetails/${characterId}`} className="mt-auto">
                  <button className="w-full flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium">
                    Edit Character
                  </button>
                </Link>
              </div>
            );
          })
        ) : (
          <div className="col-span-4 text-center text-gray-600 dark:text-white">
            No characters found.
          </div>
        )}
      </div>
    );
  };

  if (loading) return <div>Loading characters...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      {/* Header with Search Bar, Create Character Button and Tabs */}
      <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
        <div className="flex items-center space-x-2">
          <h1 className="pl-5 text-2xl font-semibold text-gray-900 dark:text-white">AI Characters</h1>
        </div>
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Search characters..."
            className="block p-2 text-sm border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button
            className="p-3 bg-blue-600 text-white rounded-lg"
            onClick={handleCreateCharacterClick}
          >
            Create New Character
          </button>
        </div>
      </div>

      {/* Tab Navigation */}
      <div className="flex space-x-4 mb-6">
        <button
          onClick={() => setActiveTab('characters')}
          className={`pb-2 px-4 ${activeTab === 'characters' ? 'border-b-4 border-blue-600 text-blue-600' : 'text-gray-500 dark:text-gray-400'}`}
        >
          Characters
        </button>
        <button
          onClick={() => setActiveTab('knowledge')}
          className={`pb-2 px-4 ${activeTab === 'knowledge' ? 'border-b-4 border-blue-600 text-blue-600' : 'text-gray-500 dark:text-gray-400'}`}
        >
          Knowledge
        </button>
        <button
          onClick={() => setActiveTab('scenes')}
          className={`pb-2 px-4 ${activeTab === 'scenes' ? 'border-b-4 border-blue-600 text-blue-600' : 'text-gray-500 dark:text-gray-400'}`}
        >
          Scenes
        </button>
        <button
          onClick={() => setActiveTab('voice')}
          className={`pb-2 px-4 ${activeTab === 'voice' ? 'border-b-4 border-blue-600 text-blue-600' : 'text-gray-500 dark:text-gray-400'}`}
        >
          Voice
        </button>
      </div>

      {/* Tab Content */}
      {renderTabContent()}
    </div>
  );
};

const CreateCharacterPopup = ({ onClose }) => {
  const [characterData, setCharacterData] = useState({
    defaultCharacterDescription: {
      givenName: '',
      description: '',
      motivation: '',
    }
  });
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);

  const handleCreateCharacter = async (event) => {
    event.preventDefault();

    if (!characterData.defaultCharacterDescription.givenName) {
      setError("Given name is required");
      return;
    }

    setCreating(true);
    try {
      const functions = getFunctions();
      const createCharacter = httpsCallable(functions, 'createCharacter');
      await createCharacter(characterData);
      setCreating(false);
      onClose();
    } catch (err) {
      setError(err.message || "An error occurred while creating the character");
      setCreating(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 dark:bg-gray-700 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full dark:bg-gray-800">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 dark:bg-gray-800">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white" id="modal-title">
                  Create New Character
                </h3>
                <div className="mt-2">
                  <form onSubmit={handleCreateCharacter} className="space-y-6">
                    <div>
                      <label htmlFor="givenName" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                        Given Name
                      </label>
                      <input
                        type="text"
                        id="givenName"
                        value={characterData.defaultCharacterDescription.givenName}
                        onChange={(e) => setCharacterData({
                          ...characterData,
                          defaultCharacterDescription: {
                            ...characterData.defaultCharacterDescription,
                            givenName: e.target.value,
                          }
                        })}
                        required
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-blue-500 block w-full rounded-md sm:text-sm focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-300"
                      />
                    </div>
                    <div>
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                        Description
                      </label>
                      <textarea
                        id="description"
                        value={characterData.defaultCharacterDescription.description}
                        onChange={(e) => setCharacterData({
                          ...characterData,
                          defaultCharacterDescription: {
                            ...characterData.defaultCharacterDescription,
                            description: e.target.value,
                          }
                        })}
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-blue-500 block w-full rounded-md sm:text-sm focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-300"
                      />
                    </div>
                    <button
                      type="submit"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
                    >
                      {creating ? 'Creating...' : 'Create Character'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse dark:bg-gray-700">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm dark:bg-gray-600 dark:text-gray-200 dark:border-gray-500 dark:hover:bg-gray-500"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharacterOverview;
