import React from 'react';

const CharacterTabs = ({ activeTab, setActiveTab }) => {
    return (
        <div className="mt-6 border-b border-gray-300 dark:border-gray-700">
            <button
                onClick={() => setActiveTab('basic')}
                className={`pb-2 px-4 ${activeTab === 'basic' ? 'border-b-4 border-blue-600 text-blue-600' : 'text-gray-500 dark:text-gray-400'}`}
            >
                Basic
            </button>
            <button
                onClick={() => setActiveTab('advanced')}
                className={`pb-2 px-4 ${activeTab === 'advanced' ? 'border-b-4 border-blue-600 text-blue-600' : 'text-gray-500 dark:text-gray-400'}`}
            >
                Advanced
            </button>
        </div>
    );
};

export default CharacterTabs;
