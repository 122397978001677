import React, { useEffect, useState } from "react";
import { useAppData } from "../../contexts/AppDataContext";
import { useUser } from "../../contexts/UserContext";
import { Dropdown, DropdownItem } from "flowbite-react";
import { useNavigate } from "react-router";
import { HiBookOpen } from "react-icons/hi";
import SkeletonLoader from "../../components/SkeletonLoader";
import { getFileUrl } from '../../services/FirebaseService';
import { usePopUp } from "../../contexts/PopUpContext";
import CreateAssignment from "../assesments/components/CreateAssignment";
import ProfilePicture from "../../components/ProfilePicture";
import { HiX, HiOutlineDotsVertical } from 'react-icons/hi';
import PDFViewer from "../../components/PDFViewer";
import CreateScenario from "../creators/CreateScenario";
import ShareScenarioPopup from "../creators/ShareTemplate";
import { faChevronDown, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LibraryOverview = () => {
    const { scenarios, isLoading } = useAppData(); // Get scenarios directly
    const navigate = useNavigate();
    const { showPopUp } = usePopUp();
    const user = useUser();

    const [activeTab, setActiveTab] = useState('courseta'); // Active tab state
    const [filteredScenarios, setFilteredScenarios] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query

    const [coursetaCount, setCoursetaCount] = useState(0);
    const [templatesCount, setTemplatesCount] = useState(0);
    const [sharedCount, setSharedCount] = useState(0);
    const [charactersCount, setCharactersCount] = useState(0);
    const [scenarioCount, setScenarioCount] = useState(0);

    useEffect(() => {
        filterScenarios();
    }, [activeTab, scenarios, searchQuery]); // Re-run when activeTab, scenarios, or searchQuery changes

    const filterScenarios = () => {
        // Globally filter scenarios based on search query
        const searchFilter = scenario => scenario.name.toLowerCase().includes(searchQuery.toLowerCase());

        let filtered = scenarios.filter(searchFilter); // Filter all scenarios by search query

        // Tab-based filtering with counts
        const coursetaScenarios = filtered.filter(scenario => scenario.fromCourseta);
        const templateScenarios = filtered.filter(scenario => !scenario.fromCourseta && scenario.creator === user?.id);
        const sharedScenarios = filtered.filter(scenario => scenario.shared && Array.isArray(scenario.shared) && scenario.shared.includes(user?.id));

        setCoursetaCount(coursetaScenarios.length);
        setTemplatesCount(templateScenarios.length);
        setSharedCount(sharedScenarios.length);
        setCharactersCount(0); // Placeholder since no characters yet

        switch (activeTab) {
            case 'courseta':
                setFilteredScenarios(coursetaScenarios);
                break;
            case 'templates':
                setFilteredScenarios(templateScenarios);
                break;
            case 'shared':
                setFilteredScenarios(sharedScenarios);
                break;
            case 'characters':
                setFilteredScenarios([]); // No characters yet, so no scenarios
                break;
            case 'scenarios':
                setFilteredScenarios([]); // No scenarios yet, so no data
                break;
            default:
                setFilteredScenarios([]);
        }
    };

    const handleScenarioClick = (scenarioId) => {
        navigate(`/main/scenarioDetails/${scenarioId}`);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value); // Update search query state
    };

    const handleCreateTemplate = (scenario) => {
        showPopUp(
            <CreateScenario
                onClose={() => showPopUp(null)}
                initialScenario={scenario}
            />
        );
    };

    const handleScenarioInfo = async (scenario) => {
        const scenarioInfoUrl = await getFileUrl(scenario.scenarioInfo);
        showPopUp(
            <ScenarioInfo onClose={() => showPopUp(null)} scenarioInfoUrl={scenarioInfoUrl} scenarioName={scenario.name} />
        );
    };

    const handleCreateAssignment = (scenario) => {
        showPopUp(
            <CreateAssignment
                onClose={() => showPopUp(null)}
                classroomId={null}
                preselectedScenarioId={scenario.id}
                onNavigateAfterCreate={null}
            />
        );
    };


    const handleShareWithOthers = (scenario) => {
        showPopUp(
            <ShareScenarioPopup
                scenario={scenario}
                onClose={() => showPopUp(null)}
            />
        );
    };


    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
                <div className="flex items-center space-x-2">
                    <HiBookOpen className="text-4xl text-gray-700 dark:text-gray-300" />
                    <h1 className="pl-5 text-2xl font-semibold text-gray-900 dark:text-white">Library</h1>
                </div>
                {/* Search bar */}
                <input
                    type="text"
                    className="w-1/2 p-2 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </div>

            {isLoading ? (
                <SkeletonLoader type='card' />
            ) : (
                <>
                    {/* Tabs with item counts */}
                    <div className="flex mb-4 space-x-8 border-b-2 border-gray-200">
                        <button
                            className={`px-4 pb-2 text-lg font-medium ${activeTab === 'courseta' ? 'text-blue-500 border-b-4 border-blue-500' : 'text-gray-600'} dark:text-white`}
                            onClick={() => setActiveTab('courseta')}
                        >
                            Courseta Scenarios ({coursetaCount})
                        </button>
                        <button
                            className={`px-4 pb-2 text-lg font-medium ${activeTab === 'templates' ? 'text-blue-500 border-b-4 border-blue-500' : 'text-gray-600'} dark:text-white`}
                            onClick={() => setActiveTab('templates')}
                        >
                            My Templates ({templatesCount})
                        </button>
                        <button
                            className={`px-4 pb-2 text-lg font-medium ${activeTab === 'shared' ? 'text-blue-500 border-b-4 border-blue-500' : 'text-gray-600'} dark:text-white`}
                            onClick={() => setActiveTab('shared')}
                        >
                            Templates Shared With You ({sharedCount})
                        </button>
                        <button
                            className={`px-4 pb-2 text-lg font-medium ${'characters' === activeTab ? 'text-blue-500 border-b-4 border-blue-500' : 'text-gray-600'} dark:text-white`}
                            onClick={() => setActiveTab('characters')}
                            disabled
                        >
                            AI Characters ({charactersCount})
                        </button>
                        <button
                            className={`px-4 pb-2 text-lg font-medium ${'characters' === activeTab ? 'text-blue-500 border-b-4 border-blue-500' : 'text-gray-600'} dark:text-white`}
                            onClick={() => setActiveTab('characters')}
                            disabled
                        >
                            My Custom Scenarios ({scenarioCount})
                        </button>
                    </div>

                    {/* Scenarios Display */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {/* AddNewCourseCard for Courseta and Templates */}
                        {activeTab === 'courseta' && user?.roleName === 'Admin' && (
                            <AddNewCourseCard showPopUp={showPopUp} user={user} />
                        )}
                        {activeTab === 'templates' && (
                            <AddNewCourseCard showPopUp={showPopUp} user={user} />
                        )}

                        {filteredScenarios.length > 0 ? (
                            filteredScenarios.map(scenario => (
                                <LibraryCard
                                    key={scenario.id}
                                    scenario={scenario}
                                    currentUser={user}
                                    onScenarioClick={handleScenarioClick}
                                    onCreateTemplate={handleCreateTemplate}
                                    onScenarioInfo={handleScenarioInfo}
                                    onCreateAssignment={handleCreateAssignment}
                                    onShareWithOthers={handleShareWithOthers}
                                />
                            ))
                        ) : (
                            <div className="col-span-4 text-center text-gray-600 dark:text-white">
                                No scenarios found.
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};


const LibraryCard = ({ scenario, currentUser, onScenarioClick, onCreateTemplate, onScenarioInfo, onCreateAssignment, onShareWithOthers }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const { showPopUp } = usePopUp();

    useEffect(() => {
        const fetchImage = async () => {
            if (scenario && scenario.scenarioPicture) {
                const url = await getFileUrl(scenario.scenarioPicture);
                setImageUrl(url);
            }
        };
        fetchImage();
    }, [scenario.scenarioPicture]);

    return (
        <div className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between h-full">
            {/* Scenario Image */}
            {imageUrl ? (
                <div className="w-full h-72">
                    <img className="object-cover w-full h-full" src={imageUrl} alt={scenario?.name ?? "Scenario Image"} />
                </div>
            ) : (
                <div className="w-full h-56 bg-gray-300 flex items-center justify-center">
                    <span>Loading Image...</span>
                </div>
            )}

            {/* Card content */}
            <div className="flex-grow p-4 flex flex-col justify-between">
                <div>
                    <h4 className="text-md font-semibold text-gray-800 dark:text-white truncate">
                        {scenario?.name ?? "Untitled Scenario"}
                    </h4>
                    <p className="text-gray-600 dark:text-gray-400 mb-4">{scenario?.description}</p>
                </div>

                <div className="justify content-center">
                    <Dropdown
                        label={
                            <button className="flex items-center justify-center w-full border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium mt-4">
                                See Options
                                <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
                            </button>
                        }
                        inline={true}
                        arrowIcon={false}
                    >
                        <DropdownItem onClick={() => onScenarioInfo(scenario)}>
                            <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> See Info
                        </DropdownItem>


                        {(currentUser.roleName === "Admin" ||
                            (currentUser.roleName === "Teacher" && !scenario.fromCourseta && !scenario.shared?.includes(currentUser.id))) && (
                                <DropdownItem onClick={() => onScenarioClick(scenario.id)}>
                                    <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> Edit
                                </DropdownItem>
                            )}

                        {currentUser.roleName === "Teacher" && scenario.fromCourseta && (
                            <DropdownItem onClick={() => onCreateTemplate(scenario)}>
                                <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> Create Template
                            </DropdownItem>
                        )}


                        <DropdownItem onClick={() => onCreateAssignment(scenario)}>
                            <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> Create Assignment
                        </DropdownItem>

                        {scenario.fromCourseta === false && scenario.creator === currentUser.id && (
                            <DropdownItem onClick={() => onShareWithOthers(scenario)}>
                                <FontAwesomeIcon icon={faEllipsisV} className="mr-2" /> Share with others
                            </DropdownItem>
                        )}
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};



const AddNewCourseCard = ({ showPopUp, user }) => {
    return (
        <div
            onClick={() => showPopUp(<CreateScenario onClose={() => showPopUp(null)} />)}
            className="cursor-pointer p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between h-full hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-200 ease-in-out"
        >
            {/* Placeholder Image/Graphic */}
            <div className="flex-grow flex items-center justify-center">
                <span className="text-blue-500 text-6xl">+</span>
            </div>

            {/* Card content */}
            <div className="flex-grow p-4 flex flex-col justify-between text-center">
                <div>
                    <h4 className="text-md font-semibold text-gray-800 dark:text-white">
                        Create New Custom Scenario
                    </h4>
                    <p className="text-gray-600 dark:text-gray-400 mt-2">
                        Click here to create a new custom scenario.
                    </p>
                </div>
            </div>
        </div>
    );
};


const ScenarioInfo = ({ onClose, scenarioInfoUrl, scenarioName }) => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = scenarioInfoUrl;
        link.setAttribute('target', '_blank'); // Opens the PDF in a new tab
        link.setAttribute('rel', 'noopener noreferrer'); // Adds security measures
        link.setAttribute('download', `${scenarioName}.pdf`); // Sets the default download name for the PDF
        link.click(); // Triggers the download
    };

    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center p-8 z-50">
            <div className="relative bg-gray-800 rounded-lg shadow-lg w-full max-w-6xl p-6">
                <div className="flex justify-between items-center bg-black text-white p-4 rounded-t-lg">
                    <h2 className="text-xl font-semibold">Scenario Info {scenarioName}</h2>
                    <div className="flex space-x-4">
                        {/* Download Button */}
                        <button
                            onClick={handleDownload}
                            className="text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg flex items-center"
                        >
                            See PDF
                        </button>
                        <button onClick={onClose} className="text-white hover:text-gray-300">
                            <HiX className="h-6 w-6" />
                        </button>
                    </div>
                </div>
                <div className="p-6 bg-white max-h-[80vh] overflow-y-auto">
                    {/* Assuming PDFViewer is another component */}
                    <PDFViewer pdfUrl={scenarioInfoUrl} />
                </div>
            </div>
        </div>
    );
};

export default LibraryOverview;
