import React, { } from 'react';
import { Handle } from 'reactflow';
import NodeParent from './NodeParent';
import { FaClock } from 'react-icons/fa';
import { ScriptingIntegerInput } from '../library/ScriptingComponentLibrary';

const DelayNode = ({ id, data, selected }) => {
  const createMetaData = () => {
    return (
      <div className="mb-4" >
        <label className="block text-sm mb-1">Duration</label>
        <ScriptingIntegerInput id={id} data={data} label="duration" />
      </div >)
  };

  return (
    <NodeParent title="Delay" icon={<FaClock />} selected={selected} description="Adds a delay">
      {createMetaData()}
      <Handle type="source" position="right" style={{ width: '12px', height: '12px' }} className="w-4 h-4 bg-gray-700" />
      <Handle type="target" position="left" style={{ width: '12px', height: '12px' }} className="w-4 h-4 bg-gray-700" />
    </NodeParent>
  );
};

export default DelayNode;
