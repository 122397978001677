import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, where, addDoc, Timestamp } from 'firebase/firestore';
import { firestore } from '../firebase';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

// Registreer de benodigde Chart.js elementen
Chart.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export default function ProgressionGraph() {
  const [analyticsData, setAnalyticsData] = useState([]);

  useEffect(() => {
    const checkAndCreateSnapshot = async () => {
      const today = new Date();
      const todayStr = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

      // Check of er een snapshot voor vandaag bestaat
      const snapshotQuery = query(
        collection(firestore, 'analytics'),
        where('date', '==', todayStr)
      );
      const snapshotDocs = await getDocs(snapshotQuery);

      if (snapshotDocs.empty) {
        // Als er geen snapshot bestaat, maak dan een nieuwe
        const newSnapshot = {
          date: todayStr,
          totalUsers: await getTotal('users'),
          //totalPendingRegistrations: await getTotal('pendingRegistrations'),
          totalGrades: await getTotal('grades'),
          totalSchools: await getTotal('schools'),
          //  totalAnomUsers: await getTotal('anonymous_users'),
          totalCourses: await getTotal('classRooms'),
          totalAssessments: await getTotal('assessments'),
          //  totalModules: await getTotal('modules'),
          totalScenarios: await getTotal('scenarios'),
          createdAt: Timestamp.now(),
        };

        await addDoc(collection(firestore, 'analytics'), newSnapshot);
      } else {
        //console.log('Snapshot for today already exists');
      }
    };

    const fetchAnalytics = async () => {
      const q = query(collection(firestore, 'analytics'), orderBy('createdAt', 'asc'));
      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map(doc => doc.data());
      setAnalyticsData(data);
    };

    const getTotal = async (collectionName) => {
      const q = query(collection(firestore, collectionName));
      const querySnapshot = await getDocs(q);
      return querySnapshot.size;
    };

    // Controleer en maak een snapshot indien nodig
    checkAndCreateSnapshot().catch(console.error);

    // Haal alle bestaande snapshots op voor de grafiek
    fetchAnalytics().catch(console.error);
  }, []);

  const prepareChartData = () => {
    const dates = analyticsData.map(data => data.date);
    const totalUsers = analyticsData.map(data => data.totalUsers);
    const totalSchools = analyticsData.map(data => data.totalSchools);
    const totalCourses = analyticsData.map(data => data.totalCourses);
    const totalAssessments = analyticsData.map(data => data.totalAssessments);
    //const totalModules = analyticsData.map(data => data.totalModules);
    const totalScenarios = analyticsData.map(data => data.totalScenarios);
    //const totalAnomUsers = analyticsData.map(data => data.totalAnomUsers);
    const totalGrades = analyticsData.map(data => data.totalGrades);
    //const totalPendingRegistrations = analyticsData.map(data => data.totalPendingRegistrations);

    return {
      labels: dates,
      datasets: [
        {
          label: 'Total Users',
          data: totalUsers,
          borderColor: 'rgba(75, 192, 192, 1)',
          fill: false,
          tension: 0.3,
        },
        {
          label: 'Total Schools',
          data: totalSchools,
          borderColor: 'rgba(153, 102, 255, 1)',
          fill: false,
          tension: 0.3,
        },
        {
          label: 'Total Courses',
          data: totalCourses,
          borderColor: 'rgba(255, 159, 64, 1)',
          fill: false,
          tension: 0.3,
        },
        {
          label: 'Total Assessments',
          data: totalAssessments,
          borderColor: 'rgba(0, 204, 102, 1)',
          fill: false,
          tension: 0.3,
        },
        // {
        //   label: 'Total Modules',
        //   data: totalModules,
        //   borderColor: 'rgba(255, 99, 132, 1)',
        //   fill: false,
        //   tension: 0.3,
        // },
        {
          label: 'Total Scenarios',
          data: totalScenarios,
          borderColor: 'rgba(54, 162, 235, 1)',
          fill: false,
          tension: 0.3,
        },
        // {
        //   label: 'Total Anom Users',
        //   data: totalAnomUsers,
        //   borderColor: 'rgba(255, 206, 86, 1)',
        //   fill: false,
        //   tension: 0.3,
        // },
        {
          label: 'Total Grades',
          data: totalGrades,
          borderColor: 'rgba(75, 192, 192, 0.6)',
          fill: false,
          tension: 0.3,
        },
        // {
        //   label: 'Total Pending Registrations',
        //   data: totalPendingRegistrations,
        //   borderColor: 'rgba(153, 102, 255, 0.6)',
        //   fill: false,
        //   tension: 0.3,
        // },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Zorgt ervoor dat de grafiek goed wordt geschaald
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
        grid: {
          display: false, // Verwijdert gridlijnen van de x-as
        },
      },
      y: {
        title: {
          display: true,
          text: 'Total Counts',
        },
        beginAtZero: true, // Y-as begint bij 0 en groeit dynamisch
        ticks: {
          stepSize: 1000, // Optioneel: Bepaal de stapgrootte voor de y-as
        },
        grid: {
          borderDash: [5, 5], // Stippellijnen voor de y-as gridlijnen
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.parsed.y}`,
        },
      },
    },
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">App Progress Analytics</h3>
        {/* <button className="text-blue-500 dark:text-blue-400 hover:underline" onClick={() => window.location.reload()}>
          Refresh Data
        </button> */}
      </div>
      {analyticsData.length > 0 ? (
        <div style={{ height: '400px' }}>
          <Line data={prepareChartData()} options={chartOptions} />
        </div>
      ) : (
        <p className="text-gray-600 dark:text-gray-300">No analytics data available.</p>
      )}
    </div>
  );

}
