const SafetyConfig = ({ safetyConfig, handleSafetyChange }) => {
  const topics = [
    { label: 'Profanity', key: 'TOPIC_PROFANITY', description: 'Swear words used either as adjectives or nouns' },
    { label: 'Violence', key: 'TOPIC_VIOLENCE', description: 'Any mentioning of physical violence, threads of inducing harm on another person' },
    { label: 'Adult topics', key: 'TOPIC_ADULT_TOPICS', description: 'Any topics related to sexual acts, prostitution' },
    { label: 'Alcohol', key: 'TOPIC_ALCOHOL', description: 'Any beverages that contain alcohol, including but not limited to beer, wine, spirits, cocktails' },
    { label: 'Substance use', key: 'TOPIC_SUBSTANCE_USE', description: 'Drugs, cannabis, tobacco' },
    { label: 'Politics', key: 'TOPIC_POLITICS', description: 'Any commentary on political affiliations' },
    { label: 'Religion', key: 'TOPIC_RELIGION', description: 'Any commentary on religious beliefs. E.g.: Pope,' },
  ];

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6 flex justify-center">
      <div className="max-w-4xl w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">Safety Configurations</h2>

        <div className="mb-6">
          <p className="bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-300 p-4 rounded">
            Modifying safety configurations may impact dialogue quality. Safety controls affect runtime dialogue generation only. Studio fields are still subject to safety validation.
          </p>
        </div>

        {topics.map(({ label, key, description }) => {
          // Haal de waarde rechtstreeks uit het safetyConfig object
          const currentValue = safetyConfig[key] || "SAFETY_LEVEL_STRICT_CONTROL";

          // Zet de waarde naar een nummer dat de radio buttons begrijpen
          const mappedValue = currentValue === "SAFETY_LEVEL_STRICT_CONTROL" ? 1 :
            currentValue === "SAFETY_LEVEL_MILD_CONTROL" ? 2 :
              3;  // Voor "SAFETY_LEVEL_NO_CONTROL"

          return (
            <div key={key} className="grid grid-cols-12 gap-4 items-center mb-6">
              {/* Left side: description */}
              <div className="col-span-8">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">{label}</h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">{description}</p>
              </div>

              {/* Right side: radio options */}
              <div className="col-span-4 flex justify-between">
                {['Strict', 'Mild', 'Loose'].map((option, index) => (
                  <label key={option} className="inline-flex items-center">
                    <input
                      type="radio"
                      name={key}
                      value={index + 1}
                      checked={mappedValue === index + 1}
                      onChange={(e) => handleSafetyChange(key, index + 1)}
                      className="form-radio h-4 w-4 text-blue-600"
                    />
                    <span className="ml-2">{option}</span>
                  </label>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SafetyConfig;
