// src/components/Sidebar.jsx
import React, { useState } from 'react';

const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
};

const Sidebar = () => {
    const [activeTab, setActiveTab] = useState('nodes');
    const [searchTerm, setSearchTerm] = useState('');
    const [isCollapsed, setIsCollapsed] = useState(false);

    const nodes = [
        { label: 'Event Node', type: 'event', color: 'bg-blue-600', description: 'Triggers an event' },
        { label: 'Check Node', type: 'check', color: 'bg-green-600', description: 'Checks a condition' },
        { label: 'Delay Node', type: 'delay', color: 'bg-yellow-600', description: 'Adds a delay' },
        { label: 'Effect Node', type: 'effect', color: 'bg-cyan-600', description: 'Changes a value' }
    ];

    const filteredNodes = nodes.filter(node =>
        node.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <aside className={`p-4 bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-gray-200 ${isCollapsed ? 'w-16' : 'w-64'} flex-shrink-0 transition-all duration-300`}>
            <button
                onClick={() => setIsCollapsed(!isCollapsed)}
                className="mb-4 px-2 py-1 bg-gray-400 dark:bg-gray-600 rounded text-white"
            >
                {isCollapsed ? '>' : '<'}
            </button>
            {!isCollapsed && (
                <>

                    <div>
                        {activeTab === 'nodes' && filteredNodes.map(node => (
                            <div key={node.type} className="mb-4">
                                <div
                                    className={`${node.color} text-white px-2 py-1 rounded mb-1 cursor-pointer text-sm`}
                                    onDragStart={(event) => onDragStart(event, node.type)}
                                    draggable
                                >
                                    {node.label}
                                </div>
                                <div className="text-xs text-gray-700 dark:text-gray-400">{node.description}</div>
                            </div>
                        ))}
                        {activeTab === 'conditions' && (
                            <div className="text-gray-700 dark:text-gray-300 text-sm">
                                {/* Voeg hier condition nodes toe */}
                                Condition nodes will be listed here.
                            </div>
                        )}
                        {activeTab === 'medications' && (
                            <div className="text-gray-700 dark:text-gray-300 text-sm">
                                {/* Voeg hier medication nodes toe */}
                                Medication nodes will be listed here.
                            </div>
                        )}
                    </div>
                </>
            )}
        </aside>
    );
};

export default Sidebar;
