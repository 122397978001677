import React, { useEffect } from 'react';
import { useScenario } from '../contexts/ScenarioContext';
import { useAppData } from '../../../contexts/AppDataContext';
import { useUser } from '../../../contexts/UserContext';

const QuestionnaireSelection = () => {
  const { scenario, setField } = useScenario();
  const { questionnaires } = useAppData();
  const currentUser = useUser();

  useEffect(() => { }, [scenario]);

  return (
    <div className="container mx-auto my-8 p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <div className="my-4">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Select Questionnaire:</h2>

        {/* Conditie voor niet-admins */}
        {currentUser?.roleName !== 'Admin' && (
          <p className="text-sm text-red-500 dark:text-red-400 mb-4">
            You cannot modify this selection as you do not have the necessary permissions.
          </p>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {questionnaires?.map((questionnaire) => (
            <label key={questionnaire.id} className="inline-flex items-center mt-3">
              <input
                type="radio"
                name="questionnaire"
                checked={scenario?.chosenQuestionnaireId === questionnaire.id}
                onChange={() => setField(questionnaire.id, 'chosenQuestionnaireId')}
                disabled={currentUser?.roleName !== 'Admin'} // Disable for non-admin users
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                {questionnaire.Title}
              </span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireSelection;
