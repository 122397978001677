import React, { useState, useEffect } from 'react';
import { auth, firestore } from '../../firebase';
import { useNotification } from '../../contexts/NotificationContext';
import { collection, addDoc, getDoc, doc } from '@firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { getAuth } from '@firebase/auth';
import { useUser } from '../../contexts/UserContext';


export default function CreateClassroom({ onClose, addCourse }) {
    const { showNotification } = useNotification();
    const [className, setClassName] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!className) {
            showNotification('danger', 'Please enter a course name.');
            return;
        }

        addCourse(className);
        showNotification('success', 'Course added');
        setClassName('');
        onClose();
    };

    return (
        <div
            id="createClassroomModal"
            tabIndex="-1"
            aria-hidden="true"
            className={`fixed inset-0 z-50 overflow-y-auto flex items-center justify-center ${onClose ? '' : 'hidden'}`}
        >
            <div className="flex items-center justify-center min-h-screen relative p-4 w-full max-w-2xl h-full md:h-auto">
                <div className="relative p-4 w-full max-w-2xl h-full md:h-auto bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                    <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Add course
                        </h3>
                        <button onClick={onClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="createClassroomModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="className" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Classroom Name</label>
                            <input
                                type="text"
                                name="className"
                                id="className"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Enter course name"
                                required
                                value={className}
                                onChange={(e) => setClassName(e.target.value)}
                            />
                        </div>
                        <div className="flex items-center space-x-4">
                            <button type="submit" className="w-full text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                Add course
                            </button>
                            <button onClick={onClose} type="button" className="w-full text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                Discard
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
