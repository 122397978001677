import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiHome, HiPlus } from "react-icons/hi";
import { useAppData } from '../../contexts/AppDataContext';
import { usePopUp } from '../../contexts/PopUpContext';
import CreateScenario from '../creators/CreateScenario';
import SkeletonLoader from '../../components/SkeletonLoader';
import { getFileUrl } from '../../services/FirebaseService'; // Import getFileUrl

export default function ScenariosOverview() {
  const { scenarios, isLoading } = useAppData();
  const { showPopUp } = usePopUp();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('courseta'); // State to control the active tab
  const [imageUrls, setImageUrls] = useState({}); // State to store the image URLs
  const navigate = useNavigate();

  useEffect(() => {
    // Function to load images from Firebase for each scenario
    const loadImages = async () => {
      if (!scenarios || scenarios.length === 0) return;

      const newImageUrls = {};
      // Fetch all images in parallel
      const promises = scenarios.map(async (scenario) => {
        if (scenario.scenarioPicture) {
          const url = await getFileUrl(scenario.scenarioPicture);
          newImageUrls[scenario.id] = url;
        }
      });

      // Wait until all promises are resolved
      await Promise.all(promises);
      setImageUrls(newImageUrls);
    };
    console.log(scenarios);

    loadImages();
  }, [scenarios]);

  const handleAddScenarioClick = () => {
    showPopUp(<CreateScenario onClose={() => showPopUp(null)} />);
  }

  const handleScenarioClick = (scenarioId) => {
    navigate(`/main/scenarioDetails/${scenarioId}`);
  };

  // Filter the scenarios based on the search term
  const filteredScenarios = scenarios?.filter((s) =>
    s.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Split the scenarios into Courseta scenarios and user-made scenarios
  const allScenarios = filteredScenarios;
  const coursetaScenarios = filteredScenarios?.filter(s => s.fromCourseta === true);
  const userScenarios = filteredScenarios?.filter(s => s.fromCourseta === false);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
        <div className="flex items-center space-x-2">
          <HiHome className="text-4xl text-gray-700 dark:text-gray-300" />
          <h1 className="pl-5 text-2xl font-semibold text-gray-900 dark:text-white">Scenarios</h1>
        </div>
        <div className="flex space-x-4">
          <button
            type="button"
            onClick={handleAddScenarioClick}
            className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          >
            <HiPlus className="mr-2 -ml-1" />
            Add new Scenario
          </button>
          <input
            type="text"
            placeholder="Search Scenarios"
            className="block p-2 text-sm border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {/* Tab Selector */}
      <div className="flex border-b border-gray-200 dark:border-gray-700 mb-4">
        <button
          onClick={() => setActiveTab('courseta')}
          className={`px-4 pb-2 text-lg font-medium ${activeTab === 'courseta' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'
            } hover:text-blue-600 dark:text-white dark:hover:text-blue-400`}
        >
          Courseta Scenarios ({coursetaScenarios?.length || 0})
        </button>
        <button
          onClick={() => setActiveTab('user')}
          className={`px-4 pb-2 text-lg font-medium ${activeTab === 'user' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'
            } hover:text-blue-600 dark:text-white dark:hover:text-blue-400`}
        >
          UserMade Scenarios ({userScenarios?.length || 0})
        </button>
      </div>


      {/* Content Based on Active Tab */}
      {isLoading ? (
        <SkeletonLoader type='card' />
      ) : (
        <div>
          {/* Courseta Scenarios Tab Content */}
          {activeTab === 'courseta' && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                {coursetaScenarios?.map((s) => (
                  <div key={s.id} className="bg-white dark:bg-gray-800 border dark:border-gray-700 rounded-lg shadow-md overflow-hidden">
                    {/* Display scenario picture */}
                    {imageUrls[s.id] ? (
                      <img
                        src={imageUrls[s.id]}
                        alt={`${s.name} Scenario`}
                        className="w-full h-40 object-cover"
                      />
                    ) : (
                      <div className="w-full h-40 bg-gray-300 flex items-center justify-center">
                        Loading Image...
                      </div>
                    )}
                    <div className="p-5">
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{s.name}</h5>
                      <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Questionnaire ID: {s.chosenQuestionnaireId}</p>
                      <button
                        onClick={() => handleScenarioClick(s.id)}
                        className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        See more
                        <svg className="w-3.5 h-3.5 ml-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* UserMade Scenarios Tab Content */}
          {activeTab === 'user' && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                {userScenarios?.map((s) => (
                  <div key={s.id} className="bg-white dark:bg-gray-800 border dark:border-gray-700 rounded-lg shadow-md overflow-hidden">
                    {/* Display scenario picture */}
                    {imageUrls[s.id] ? (
                      <img
                        src={imageUrls[s.id]}
                        alt={`${s.name} Scenario`}
                        className="w-full h-40 object-cover"
                      />
                    ) : (
                      <div className="w-full h-40 bg-gray-300 flex items-center justify-center">
                        Loading Image...
                      </div>
                    )}
                    <div className="p-5">
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{s.name}</h5>
                      <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Questionnaire ID: {s.chosenQuestionnaireId}</p>
                      <button
                        onClick={() => handleScenarioClick(s.id)}
                        className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        See more
                        <svg className="w-3.5 h-3.5 ml-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
