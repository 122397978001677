import logo from '../../assets/images/Courseta_Logo.png';
import React, { useRef, useState, useEffect } from 'react';
import { useNotification } from '../../contexts/NotificationContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { updatePassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, firestore } from '../../firebase';
import { doc, setDoc, getDoc, deleteDoc, arrayUnion, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { useAppData } from '../../contexts/AppDataContext';

export default function Registration() {
  const { register } = useAuth();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();
  const { setPendingUsers, setUsers } = useAppData();
  const [userData, setUserData] = useState({ token: null });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    if (token) {
      setUserData({ ...userData, token: token });
      fetchUserData(token);
    }
  }, [location.search]); // Depends on your router setup; might need window.location instead

  const fetchUserData = async (token) => {
    try {
      const docRef = doc(firestore, 'pendingRegistrations', token);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserData({ ...docSnap.data(), token }); // Update the state with fetched data
        emailRef.current.value = docSnap.data().email; // Pre-fill the email
      } else {
        setError('Invalid or expired invitation link.');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('An error occurred while fetching user data.');
    }
  };

  function formatDate(date) {
    const d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1; // Maanden zijn van 0-11 in JavaScript
    const year = d.getFullYear();

    if (day < 10) {
      day = '0' + day;
    }

    if (month < 10) {
      month = '0' + month;
    }

    return `${day}/${month}/${year}`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;
  
    // Controleer op missende email of wachtwoord
    if (!email || !password) {
      setError('Email or password is missing.');
      setLoading(false);
      return;
    }
  
    const { token } = userData;
  
    // Controleer op ontbrekende token
    if (!token) {
      setError('No registration token provided.');
      setLoading(false);
      return;
    }
  
    try {
      //console.log('Fetching pending registration for token:', token);
      const docRef = doc(firestore, 'pendingRegistrations', token);
      const docSnap = await getDoc(docRef);
  
      if (!docSnap.exists()) {
        throw new Error('No pending registration found for this token.');
      }
  
      const userDetails = docSnap.data();
      //console.log('User details from pending registration:', userDetails);
  
      // Maak gebruiker aan in Firebase Auth
      const userCredential = await register(email, password);
      //console.log('User registered in Firebase Auth:', userCredential.user);
  
      // Voeg de nieuwe gebruiker toe aan de 'users' collectie in Firestore
      const newUser = {
        ...userDetails,
        id: userCredential.user.uid,
        datejoined: formatDate(Date.now())
      };
  
      await setDoc(doc(firestore, 'users', userCredential.user.uid), newUser);
      //console.log('User added to Firestore:', newUser);
  
      // Voeg de gebruiker toe aan de deelnemerslijst van hun schooldocument
      await updateDoc(doc(firestore, 'schools', userDetails.schoolID), {
        attendees: arrayUnion(userCredential.user.uid)
      });
      //console.log(`User added to school (${userDetails.schoolID}) attendees list`);
  
      // Voeg de gebruiker toe aan elke geselecteerde classRoom in de attendees-array
      if (userDetails.attendingClasses && userDetails.attendingClasses.length > 0) {
        for (const classId of userDetails.attendingClasses) {
          try {
            await updateDoc(doc(firestore, 'classRooms', classId), {
              attendees: arrayUnion(userCredential.user.uid)
            });
            //console.log(`User added to classRoom ${classId} attendees list`);
          } catch (error) {
            console.error(`Error adding user to classRoom ${classId}:`, error);
            showNotification('danger', `Error adding user to classRoom ${classId}.`);
          }
        }
      } else {
        //console.log('No attending classes selected for this user.');
      }
  
      // Verwijder de gebruiker uit de pendingRegistrations collectie
      await deleteDoc(docRef);
      //console.log('User removed from pending registrations');
  
      // Werk de pendingUsers state bij (verwijder de geregistreerde gebruiker)
      setPendingUsers(prevPendingUsers => prevPendingUsers.filter(u => u.id !== token));
      //console.log('Pending users state updated');
  
      // Werk de users state bij (voeg de geregistreerde gebruiker toe)
      setUsers(prevUsers => [...prevUsers, newUser]);
      //console.log('Users state updated');
  
      // Wis de token en lokale opslag
      window.localStorage.removeItem('emailForSignIn');
      //console.log('Local storage cleared for emailForSignIn');
  
      // Toon een succesmelding en navigeer naar het dashboard
      showNotification('success', 'Registration completed successfully!');
      navigate('/#/dashboard'); // Navigatie naar het dashboard
  
    } catch (error) {
      console.error('Error during registration:', error);
  
      // Specifieke foutafhandeling voor Firebase Auth
      if (error.code === 'auth/email-already-in-use') {
        setError('This email is already registered. Please log in instead.');
      } else {
        setError('Registration failed. Please try again.');
      }
  
      showNotification('danger', `Error during registration: ${error.message}`);
    } finally {
      setLoading(false); // Zorg ervoor dat de loading state altijd wordt uitgeschakeld
    }
  };
  

  return (
    <section className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
      <div className="flex w-full h-screen max-w-none mx-auto bg-white dark:bg-gray-800">
        <div className="w-1/2 h-full flex items-center justify-center p-8">
          <div className="w-full max-w-md space-y-4 md:space-y-6">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Finish Registration
            </h1>
            <p className="text-sm text-gray-700 dark:text-gray-400">
              Please complete your registration by choosing a password below. Your email has already been filled in for you.
            </p>
            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold">Error! </strong>
                <span className="block sm:inline">{error}</span>
              </div>
            )}
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                <input
                  ref={emailRef}
                  type="email"
                  id="email"
                  required
                  disabled
                  className="bg-gray-200 text-gray-500 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-400 cursor-not-allowed"
                  title="Email is filled in for you. Finish registration by choosing a password."
                />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                <input
                  ref={passwordRef}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  placeholder="••••••••"
                />
              </div>

              <button
                type="submit"
                disabled={loading}
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                {loading ? 'Processing...' : 'Finish Registration'}
              </button>
            </form>
            <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                            Already have an account?
                            <a href="/forgotpassword" onClick={(e) => { e.preventDefault(); navigate("/signin"); }} className="font-medium text-blue-600 hover:underline dark:text-blue-500">Click here!</a>
                        </div>
          </div>
        </div>
        <div className="w-1/2 h-full flex items-center justify-center bg-blue-600 rounded-tl-3xl rounded-bl-3xl">
          <img className="w-80 h-90" src={logo} alt="Courseta Logo" />
        </div>
      </div>
    </section>
  );
}
