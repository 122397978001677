import React, { useContext, useState, useEffect } from 'react';
import { auth, signInWithEmailAndPassword, onAuthStateChanged, createUserWithEmailAndPassword } from '../firebase'; // adjust the path as necessary
import { signOut } from 'firebase/auth';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {
        // This function logs the current auth state whenever the currentUser changes
        const logAuthState = () => {
            //console.log(currentUser ? 'User is logged in' : 'User is not logged in');
        };
        logAuthState();
    }, [currentUser]); // Dependency array with currentUser will trigger this effect on change

    async function login(email, password) {
        return await signInWithEmailAndPassword(auth, email, password);
    }

    async function logout() {
        return await signOut(auth);
    }

    async function register(email, password){
        return await createUserWithEmailAndPassword(auth, email, password)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            setCurrentUser(user);
            //console.log(user ? 'User has logged in' : 'User has logged out');
        });
        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        login,
        logout,
        register
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}
