import React from 'react';

const SignInCodePopup = ({ code, onClose, hideDefaultCloseButton }) => {
    return (
        <div id="deleteAlertModal" tabIndex="-1" aria-hidden="true" className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center w-full h-modal md:h-full">
         <div className="relative p-4 w-full max-w-md h-full md:h-auto">
      <div className="bg-white dark:bg-gray-900 p-6 rounded-lg shadow-xl text-center transform transition-all duration-500 ease-in-out">
        <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white mb-6">
          VR Sign-in Code
        </h2>
        <div className="bg-blue-50 dark:bg-blue-900 p-4 rounded-lg shadow-inner mb-8">
          <p className="text-5xl font-bold text-blue-600 dark:text-blue-400 tracking-wider">
            {code}
          </p>
          <p className="text-gray-500 dark:text-gray-300 mt-2">This code expires in 5 minutes.</p>
        </div>
        {!hideDefaultCloseButton && (
          <button
            onClick={onClose}
            className="w-full py-3 px-6 font-semibold text-white bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full shadow-lg hover:from-blue-600 hover:to-indigo-700 focus:ring-4 focus:ring-indigo-500 dark:focus:ring-indigo-800 transition-all duration-300 ease-in-out transform hover:scale-105"
          >
            Close
          </button>
        )}
      </div>
      </div>
      </div>
    );
  };
  

export default SignInCodePopup;
