import React, { useState } from 'react';
import { useScenario } from '../contexts/ScenarioContext';
import { getFileUrl, uploadFile } from '../../../services/FirebaseService'; // Zorg ervoor dat uploadFile hier is geïmporteerd

const ScenarioInfo = () => {
  const { scenario, setFile } = useScenario();
  const [url, setUrl] = useState("");

  // Functie om het scenarioInfo bestand te uploaden
  const uploadScenarioInfo = async (file) => {
    if (!scenario?.id) {
      console.error("Scenario ID is missing. Cannot upload file.");
      return;
    }

    try {
      const path = `scenarios/${scenario.id}`; // Pad zoals jij het wilt
      console.log(`Uploading file ${file.name} to path: ${path}`);

      // Upload het bestand naar Firebase Storage
      await uploadFile(file, path);

      // Update scenarioInfo pad na upload
      setFile(file, 'scenarioInfo'); // Gebruik het bestand zelf, niet het pad

      // Haal de URL op van het geüploade bestand
      const downloadUrl = await getFileUrl(`${path}/${file.name}`);
      setUrl(downloadUrl);

      console.log("File uploaded and URL fetched:", downloadUrl);
    } catch (error) {
      console.error("Error uploading scenario info:", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadScenarioInfo(file); // Upload het bestand zodra een bestand wordt gekozen
    }
  };

  return (
    <div className="container mx-auto my-8 p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Scenario Information</h2>
      <div className="flex items-center">
        <label className="block">
          <span className="sr-only">Choose Info file</span>
          <input
            id="fileInput"
            type="file"
            accept=".pdf"
            onChange={handleFileChange} // Upload bestand bij verandering
            className="block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-blue-50 file:text-blue-700
                    hover:file:bg-blue-100
                    dark:file:bg-gray-600 dark:file:text-gray-200
                    cursor-pointer"
            style={{ display: 'none' }}
          />
        </label>
        <button
          onClick={() => document.getElementById('fileInput').click()}
          className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {scenario?.scenarioInfo ? 'Edit Scenario Info' : 'Choose File'}
        </button>
        <a href={url} target="_blank" rel="noopener noreferrer" className="ml-3 text-sm font-medium text-blue-500 hover:underline">
          {scenario?.scenarioInfo ? scenario.scenarioInfo.split('/').pop() : 'No file chosen'}
        </a>
      </div>
    </div>
  );
};

export default ScenarioInfo;
