import React from "react";
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';

const MultipleChoiceCard = ({ data }) => {
  const { question, choices, correctAnswer, userAnswer } = data;
  const Icon = correctAnswer == userAnswer ? CheckCircleIcon : XCircleIcon;

  const getBackgroundColor = (index) => {
    if (correctAnswer == index) {
      return 'bg-green-500 text-white';
    }
    else if (userAnswer == index) {
      return 'bg-red-500 text-white';
    } else {
      return 'bg-gray-100 hover:bg-gray-200';
    }
  };

  return (
    <div className="mb-8 mt-8 p-6 bg-white shadow-lg rounded-md flex items-center">
      <Icon className={`h-16 w-16 ${userAnswer == correctAnswer ? 'text-green-500' : 'text-red-500'} mr-4 flex-shrink-0`} aria-hidden="true" />
      <div className="flex-1 mr-8">
        <h2 className="mt-4 text-2xl font-bold text-gray-800 mb-4">{question}</h2>
        <div className="grid gap-4">
          {choices.map((choice, index) => (
            <label
              key={index}
              htmlFor={`choice${index}`}
              className={`cursor-pointer block p-4 rounded-md transition duration-300 ${getBackgroundColor(index)}`}
            >
              <span className="text-gray-800">{choice}</span>
            </label>
          ))}
        </div>
        <div className="mt-8 text-l font-bold text-gray-800">
          {userAnswer == correctAnswer ? 1 : 0} / 1 Points Earned
        </div>
      </div>
    </div>
  );
}

