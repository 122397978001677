import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewer({ pdfUrl }) {
    const [numPages, setNumPages] = useState(null);

    const handleLoadError = (error) => {
        console.error("Error loading PDF: " + error);
    }

    return (
        <div className="bg-gray-100 p-4 mt-12">
            <Document file={pdfUrl} className="border rounded-md" onLoadError={handleLoadError} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} className="p-4" scale={1.8} />
                ))}
            </Document>
        </div>
    );
}

export default PDFViewer;