import React from 'react';

const CharacterHeader = ({ characterData, defaultCharacterImage, handleSave, updating, handleDeleteCharacter, deleting }) => {
    const { defaultCharacterDescription, defaultCharacterAssets } = characterData;

    return (
        <div className="flex justify-between items-center">
            <div className="flex items-center">
                <img
                    src={defaultCharacterAssets?.avatarImg || defaultCharacterImage}
                    alt="Avatar"
                    className="w-max h-32 rounded-lg mr-6"
                />
                <div>
                    <h1 className="text-3xl font-bold text-gray-900 dark:text-white">{defaultCharacterDescription?.givenName}</h1>
                    <button className="mt-2 bg-blue-600 text-white px-4 py-2 rounded-lg">Edit Details</button>
                </div>
            </div>
            <div className="mt-6 flex space-x-4">
                <button
                    onClick={handleSave}
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg"
                    disabled={updating}
                >
                    {updating ? 'Saving...' : 'Save'}
                </button>

                <button onClick={handleDeleteCharacter} className="bg-red-600 text-white px-4 py-2 rounded-lg" disabled={deleting}>
                    {deleting ? 'Deleting...' : 'Delete Character'}
                </button>
            </div>
        </div>
    );
};

export default CharacterHeader;
