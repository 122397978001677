import React, { useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';

export default function TokenDetails() {
    const { tokenId } = useParams();


  return (
    <div>TokenDetails{tokenId}</div>
  )
}
