import React from 'react';
import Check from "@mui/icons-material/Check"; // Voor het checkmark-icoon van voltooide stappen
import styled from "@emotion/styled"; // Voor custom styling van de stap-icoontjes

// Custom StepIcon component for active step
const ActiveStepIcon = styled("div")({
  border: "1.67px solid #0348B7",
  backgroundColor: "#FFFFFF",
  color: "#0348B7",
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Inter, sans-serif",
  fontSize: "13px",
  fontWeight: 500,
  lineHeight: "15.73px",
  textAlign: "center",
});

// Custom StepIcon component for completed steps
const CompletedStepIcon = styled("div")({
  backgroundColor: "#0348B7",
  color: "#fff",
  width: 24,
  height: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
});

// Custom StepIcon component for upcoming steps
const InactiveStepIcon = styled("div")({
  border: "1.67px solid #D0D5DD",
  backgroundColor: "#FFFFFF",
  borderRadius: "50%",
  width: 24,
  height: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#475467",
  fontFamily: "Inter, sans-serif",
  fontSize: "13px",
  fontWeight: 500,
  lineHeight: "15.73px",
  textAlign: "center",
});

const SideSteps = ({ steps, activeStep, setActiveStep }) => {
  return (
    <div className="flex flex-col space-y-6">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <button
            onClick={() => setActiveStep(index)}
            className={`flex items-center space-x-2 ${
              activeStep === index ? 'text-blue-600' : 'text-gray-500 dark:text-gray-400'
            }`}
          >
            {/* Custom StepIcon logic */}
            <div className="h-8 w-8 flex items-center justify-center rounded-full">
              {index < activeStep ? (
                <CompletedStepIcon>
                  <Check fontSize="small" />
                </CompletedStepIcon>
              ) : index === activeStep ? (
                <ActiveStepIcon>{index + 1}</ActiveStepIcon>
              ) : (
                <InactiveStepIcon>{index + 1}</InactiveStepIcon>
              )}
            </div>
            <span className="flex-1">{step}</span>
          </button>
          {index < steps.length - 1 && (
            <div className="flex items-center">
              <div className="h-12 border-l border-gray-300 dark:border-gray-600 ml-4"></div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SideSteps;
