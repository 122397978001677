import React, { useState, useEffect } from 'react';
import { useNotification } from '../../contexts/NotificationContext';
import { useAppData } from '../../contexts/AppDataContext';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth } from '../../firebase';
import { authMail } from '../../emails/Auth_mail';
import { addDocument } from '../../services/FirebaseService';
import { usersCollection, pendingUsersCollection } from '../../firebase';
import { query, where, getDocs, collection } from 'firebase/firestore';
import { firestore } from '../../firebase';


export default function InviteUser({ onClose, currentUser }) {
    const { showNotification } = useNotification();
    const roles = ['Admin', 'Teacher', 'Student'];
    const roleIDs = {
        'Admin': '1212',
        'Teacher': '33',
        'Student': '22',
    };

    const { schools, courses, setCourses, addUser, fetchDataList } = useAppData();
    const [newUser, setNewUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        roleName: '',
        roleID: '',
        schoolName: '',
        schoolID: '',
        attendingClasses: [] // Attending classes array for adding courses
    });
    const [schoolCourses, setSchoolCourses] = useState([]);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

    const availableRoles = currentUser.roleName === 'Admin' ? roles : roles.filter(role => role !== 'Admin');

    useEffect(() => {
        const isRoleSelected = newUser.roleName && newUser.roleID;
        const isSchoolSelected = newUser.schoolName && newUser.schoolID;
        setIsSubmitEnabled(isRoleSelected && isSchoolSelected);
    }, [newUser.roleName, newUser.roleID, newUser.schoolName, newUser.schoolID]);


    const pendingUsersColRef = collection(firestore, pendingUsersCollection);
    const usersColRef = collection(firestore, usersCollection);

    const checkIfEmailExists = async (email) => {
        try {
            const lowerCaseEmail = email.toLowerCase();

            // Controleer in usersCollection
            const qUsers = query(usersColRef, where('email', '==', lowerCaseEmail));
            const usersSnapshot = await getDocs(qUsers);

            // Controleer in pendingUsersCollection
            const qPending = query(pendingUsersColRef, where('email', '==', lowerCaseEmail));
            const pendingSnapshot = await getDocs(qPending);

            // Als er een match is in een van beide collecties, retourneer true
            return !usersSnapshot.empty || !pendingSnapshot.empty;
        } catch (error) {
            console.error("Error checking if email exists: ", error);
            throw error;
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            //console.log("Starting user creation...");
            //console.log("User data before adding:", newUser);
            const lowerCaseEmail = newUser.email.toLowerCase();

            // Controleer eerst of de e-mail al bestaat
            const emailExists = await checkIfEmailExists(lowerCaseEmail);
            if (emailExists) {
                showNotification('danger', 'This email is already registered.');
                return;  // Stop de functie als de e-mail al bestaat
            }
            // 1. Voeg gebruiker toe en ontvang de ID van het aangemaakte document
            const userId = await addUser(newUser);
            //console.log("Pending registration added with ID:", userId);

            if (!userId) {
                throw new Error('User ID is missing after adding user.');
            }

            // Update de gebruiker met de nieuwe ID
            const updatedUser = { ...newUser, id: userId };

            showNotification('success', 'User added successfully and pending registration created!');

            // 2. Verstuur direct de uitnodigingslink
            //console.log("Sending invite for user:", updatedUser);
            await sendInvite(updatedUser);

            // Reset de state na succesvolle toevoeging en uitnodiging
            setNewUser({
                firstName: '',
                lastName: '',
                email: '',
                roleName: '',
                roleID: '',
                schoolName: '',
                schoolID: '',
                attendingClasses: [] // Reset the attending classes
            });
            setSchoolCourses([]); // Clear the course selection

        } catch (error) {
            console.error("Error adding pending registration: ", error);
            showNotification('danger', 'Failed to add pending registration.');
        }
    };

    const sendInvite = async (user) => {
        try {
            if (!user.id) {
                throw new Error('User ID is missing.');
            }

            //console.log("Sending invite for user:", user);

            const registrationLink =
                `http://coursetavr.com/#/register?token=${user.id}&email=${encodeURIComponent(user.email)}`;
            //`http://localhost:3000/#/register?token=${user.id}&email=${encodeURIComponent(user.email)}`;
            const actionCodeSettings = {
                url: registrationLink,
                handleCodeInApp: true,
            };

            //console.log("Generated registration link:", registrationLink);

            // Stuur de Firebase sign-in link
            // await sendSignInLinkToEmail(auth, user.email, actionCodeSettings);

            // Stuur de gepersonaliseerde e-mail via jouw email service
            const htmlcontent = authMail(user.firstName, registrationLink);
            const emailData = {
                to: [{ email: user.email, name: user.firstName }],
                from: { email: 'info@coursetavr.com', name: 'CoursetaVR' },
                subject: 'Create your account',
                html: htmlcontent,
            };

            await addDocument(emailData, 'emails');  // Voeg de e-mail toe aan je email-verzendlijst

            showNotification('success', 'Invitation link has been sent and email has been scheduled!');
            //console.log("Invitation sent to:", user.email);
        } catch (error) {
            console.error('Error sending invitation link:', error);
            showNotification('danger', 'Error sending the invitation link.');
        }
    };

    const fetchCoursesForSchool = async (schoolID) => {
        try {
            const filteredCourses = courses.filter(course => course.schoolID === schoolID);
            setSchoolCourses(filteredCourses);
            //console.log('Fetched courses for school: ', filteredCourses);
        } catch (error) {
            console.error('Error fetching courses:', error);
            showNotification('danger', 'Error fetching courses for the selected school.');
        }
    };

    useEffect(() => {
        if (newUser.schoolID) {
            fetchCoursesForSchool(newUser.schoolID);
        }
    }, [newUser.schoolID]);

    return (
        <div
            id="createUserdefaultModal"
            tabIndex="-1"
            aria-hidden="true"
            className={`fixed inset-0 z-50 overflow-y-auto flex items-center justify-center ${onClose ? '' : 'hidden'}`}
        >
            <div className="flex items-center justify-center min-h-screen relative p-4 w-full max-w-2xl h-full md:h-auto">
                <div className="relative p-4 w-full max-w-2xl h-full md:h-auto bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                    <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Add user
                        </h3>
                        <button onClick={onClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="createUserdefaultModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="grid gap-4 mb-4 sm:grid-cols-2">
                            <div>
                                <label htmlFor="firstname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Firstname</label>
                                <input type="text" name="firstname" id="firstname" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="John" required value={newUser.firstName ?? ''} onChange={(e) => { setNewUser({ ...newUser, firstName: e.target.value }) }} />
                            </div>
                            <div>
                                <label htmlFor="lastname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Lastname</label>
                                <input type="text" name="lastname" id="lastname" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Doe" required value={newUser.lastName ?? ''} onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })} />
                            </div>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required value={newUser.email ?? ''} onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} />
                            </div>
                            <div className="sm:col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="role">Assign Role</label>
                                <div className="flex flex-wrap gap-2">
                                    {availableRoles.map((roleName, index) => (
                                        <div key={index} className="flex items-center mr-4">
                                            <input
                                                id={`role-${roleName}`}
                                                type="radio"
                                                value={roleName}
                                                name="role"
                                                className="w-4 h-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                                onChange={() => setNewUser({ ...newUser, roleName: roleName, roleID: roleIDs[roleName] })}
                                                checked={newUser.roleName === roleName}
                                            />
                                            <label htmlFor={`role-${roleName}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{roleName}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="school">Assign School</label>
                                <div className="flex flex-wrap gap-2">
                                    {currentUser?.roleName === 'Admin'
                                        ? schools.map((school) => (
                                            <div key={school.id} className="flex items-center mr-4">
                                                <input
                                                    id={`school-${school.id}`}
                                                    type="radio"
                                                    value={school.id}
                                                    name="schoolId"
                                                    className="w-4 h-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                                    onChange={() => setNewUser({ ...newUser, schoolName: school.name, schoolID: school.id })}
                                                    checked={newUser.schoolID === school.id}
                                                />
                                                <label htmlFor={`school-${school.id}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{school.name}</label>
                                            </div>
                                        ))
                                        : schools
                                            .filter(school => school.name === currentUser?.schoolName)
                                            .map((school) => (
                                                <div key={school.id} className="flex items-center mr-4">
                                                    <input
                                                        id={`school-${school.id}`}
                                                        type="radio"
                                                        value={school.id}
                                                        name="schoolId"
                                                        className="w-4 h-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                                        onChange={() => setNewUser({ ...newUser, schoolName: school.name, schoolID: school.id })}
                                                        checked={newUser.schoolID === school.id}
                                                    />
                                                    <label htmlFor={`school-${school.id}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{school.name}</label>
                                                </div>
                                            ))
                                    }
                                </div>

                                {/* Render available courses for the selected school */}
                                {schoolCourses.length > 0 && (
                                    <div className="mt-4">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Available Courses</label>
                                        <ul className="space-y-2">
                                            {schoolCourses.map(course => (
                                                <li key={course.id}>
                                                    <label className="inline-flex items-center">
                                                        <input
                                                            type="checkbox"
                                                            className="form-checkbox"
                                                            value={course.id}
                                                            onChange={(e) => {
                                                                const { checked, value } = e.target;
                                                                setNewUser((prevUser) => ({
                                                                    ...prevUser,
                                                                    attendingClasses: checked
                                                                        ? [...prevUser.attendingClasses, value]
                                                                        : prevUser.attendingClasses.filter(id => id !== value),
                                                                }));
                                                            }}
                                                        />
                                                        <span className="ml-2 text-sm text-gray-900 dark:text-white">{course.className}</span>
                                                    </label>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center space-x-4">
                            <button
                                type="submit"
                                disabled={!isSubmitEnabled}
                                className="w-full text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                            >
                                Add user and Send Invite
                            </button>
                            <button onClick={onClose} type="button" className="w-full inline-flex justify-center text-gray-500 items-center bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                Discard
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
