import React, { useEffect, useState } from 'react';
import { useScenario } from '../contexts/ScenarioContext';
import { TrashIcon } from '@heroicons/react/outline';
import { PlusIcon } from '@heroicons/react/solid';

const Debriefing = () => {
  const { scenario, setField } = useScenario();
  const [question, setQuestion] = useState("");

  const handleDelete = (index) => {
    const updated = scenario?.debriefing?.filter((_, i) => i !== index);
    setField(updated, 'debriefing');
  };

  const handleAdd = () => {
    if (question !== "") {
      const updated = [...(scenario?.debriefing || []), question];
      setField(updated, 'debriefing');
      setQuestion("");
    }
  };

  const handleEdit = (e) => {
    let updated = [...scenario?.debriefing];
    updated[e.target.id] = e.target.value;
    setField(updated, 'debriefing');
  };

  useEffect(() => {}, [scenario?.debriefing]);

  return (
    <div className="container mx-auto my-8 p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Debriefing</h2>
      {scenario?.debriefing?.map((field, index) => (
        <div key={index} className="flex items-center mb-2">
          <input
            type="text"
            id={index}
            value={field}
            onChange={(e) => handleEdit(e)}
            className="p-2 border rounded flex-grow"
          />
          <button onClick={() => handleDelete(index)} className="ml-2 p-2 bg-red-500 text-white rounded">
            <TrashIcon className="h-5 w-5" />
          </button>
        </div>
      ))}
      <div className="flex items-center gap-4 mt-4">
        <input
          type="text"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Enter new question"
          className="p-2 border border-gray-300 rounded flex-grow focus:border-blue-600 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
        />
        <button onClick={handleAdd} className="flex items-center px-6 py-1.5 border border-gray-400 mt-4 hover:text-white text-gray-600 dark:text-gray-300 rounded text-sm hover:bg-blue-600 dark:hover:bg-blue-700 mx-auto">
          <PlusIcon className="w-6 h-6 mr-2" />
          Add Field
        </button>
      </div>
    </div>
  );
};

export default Debriefing;
