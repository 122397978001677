import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useUser } from '../contexts/UserContext';
import { assignmentCollection, coursesCollection, firestore, pendingUsersCollection, templatesCollection, usersCollection, scenariosCollection } from '../firebase';
import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import { storage } from '../firebase';


//////////////////////////////
// RECOVERY CODE Sendgrid
//93X4VNDJQJSDG28FMD3LSEMS
//////////////////////////////


export const getScenarioById = async (scenarioId) => {
    if (!scenarioId) {
        throw new Error("Scenario ID is empty or undefined.");
    }
    try {
        const docRef = doc(firestore, scenariosCollection.path, scenarioId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return { id: docSnap.id, ...docSnap.data() };
        } else {
            console.error("No scenario found with the given ID.");
            return null;
        }
    } catch (error) {
        console.error("Error fetching scenario:", error);
        throw error;
    }
};

export const getCourseById = async (courseId) => {
    if (!courseId) {
        throw new Error("Course ID is empty or undefined.");
    }
    try {
        const docRef = doc(firestore, coursesCollection.path, courseId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return { id: docSnap.id, ...docSnap.data() };
        } else {
            console.error("No course found with the given ID.");
            return null;
        }
    } catch (error) {
        console.error("Error fetching course:", error);
        throw error;
    }
};


export const getUsers = async () => {
    const usersSnapshot = await getDocs(collection(firestore, usersCollection));
    const users = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), isPending: false }));

    const pendingRegistrationsSnapshot = await getDocs(collection(firestore, pendingUsersCollection));
    const pendingRegistrations = pendingRegistrationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), isPending: true }));

    return [...users, ...pendingRegistrations];
}

export const addCourseAttendees = async (users, courseId) => {
    const courseDoc = doc(firestore, coursesCollection, courseId);

    await updateDoc(courseDoc, {
        attendees: arrayUnion(...users)
    });

    for (const user of users) {
        const userDoc = doc(firestore, usersCollection, user);
        await updateDoc(userDoc, {
            attendingClasses: arrayUnion(courseId)
        });
    }
}

export const removeCourseAttendees = async (users, courseId) => {
    const courseDoc = doc(firestore, coursesCollection, courseId);

    await updateDoc(courseDoc, {
        attendees: arrayRemove(...users)
    });

    for (const user of users) {
        const userDoc = doc(firestore, usersCollection, user);
        await updateDoc(userDoc, {
            attendingClasses: arrayRemove(courseId)
        });
    }
}

export const addAssignmentFirebase = async (courseId, assignment) => {
    const collectionRefRef = collection(firestore, assignmentCollection);
    const id = await addDoc(collectionRefRef, assignment);

    const docRef = doc(firestore, coursesCollection, courseId);
    await updateDoc(docRef, { assessments: arrayUnion(id) });

    return id;
}

export const deleteAssignmentFirebase = async (courseId, assignmentId) => {
    // Verwijder het assignmentId uit de assessments array van de course in Firestore
    const courseDocRef = doc(firestore, coursesCollection, courseId);

    // Update de assessments array om de assignment te verwijderen
    await updateDoc(courseDocRef, { assessments: arrayRemove(assignmentId) });

    // In plaats van de assignment echt te verwijderen, zetten we de isActive op false
    const assignmentDocRef = doc(firestore, assignmentCollection, assignmentId);

    await updateDoc(assignmentDocRef, { isActive: false });

    console.log(`Assignment ${assignmentId} in course ${courseId} marked as inactive.`);
};

export const getFileUrl = async (path) => {
    const storageRef = ref(storage, path);

    try {
        const url = await getDownloadURL(storageRef);
        return url;
    } catch (error) {
        // console.error(error);
        return null;
    }
}

export const uploadFile = async (file, path) => {
    const storageRef = ref(storage, `${path}/${file.name}`);
    try {
        // Upload file to the reference location
        await uploadBytes(storageRef, file);
    } catch (e) {
        console.error("Error uploading file to firebase: " + e);
    }
}

export const deleteFile = async (file, path) => {
    // Create a reference to the storage object
    const fileRef = ref(storage, `${path}/${file}`);

    try {
        // Delete the file from storage
        await deleteObject(fileRef);

        return true;

    } catch (error) {
        console.error('Error deleting file', error);
        return false;
    }
}

export const addScenariosToCourse = async (scenarios, courseId) => {
    const docRef = doc(firestore, coursesCollection, courseId);
    return await updateDoc(docRef, {
        availableScenarios: arrayUnion(...scenarios)
    });
}

export const removeScenariosFromCourse = async (scenarios, courseId) => {
    const docRef = doc(firestore, coursesCollection, courseId);
    return await updateDoc(docRef, {
        availableScenarios: arrayRemove(...scenarios)
    });
}

export const saveTemplateFirebase = async (assignment, userId) => {
    const col = collection(firestore, templatesCollection);
    const copy = { ...assignment, userId: userId, assignmentId: assignment.id };
    delete (copy['id']);
    const template = await addDoc(col, copy);

    const userDoc = doc(firestore, usersCollection, userId);
    await updateDoc(userDoc, {
        templates: arrayUnion(template.id)
    });

    return template.id;
}

export const startAssignmentForUser = async (userId, assignmentId) => {
    const docRef = doc(firestore, usersCollection, userId);
    await updateDoc(docRef, {
        startedAssignment: assignmentId
    });
}

export const removeStartedAssignmentForUser = async (userId) => {
    const docRef = doc(firestore, usersCollection, userId);
    await updateDoc(docRef, {
        startedAssignment: ""
    });
}

export const addCourseFirebase = async (userId, course) => {
    //Add the course to firebase
    const courseDoc = await addDoc(collection(firestore, coursesCollection), course);

    //Update the user
    const docRef = doc(firestore, usersCollection, userId);
    await updateDoc(docRef, {
        attendingClasses: arrayUnion(courseDoc.id)
    });

    return courseDoc.id;
}

//Fetches a single data object
export const fetchDataObject = async (id, collectionName) => {
    if (!id || id === "") {
        console.error("Fetch data object called with empty id: " + collectionName);
        return null;
    }

    //console.log(id);

    const docRef = doc(firestore, collectionName, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return {
            id: id,
            ...docSnap.data()
        };
    }
    else {
        //console.error("Doc was empty!");
        return null;
    }
}

//Fetches a list of data objects given a list of ID's and a collection name
export const fetchDataList = async (ids, collectionName) => {
    if (!ids || ids.length <= 0) {
        return [];
    }

    const data = await Promise.all(ids.map(async (id) => {
        const dataObject = await fetchDataObject(id, collectionName);
        return dataObject;
    }));

    return data.filter((d) => d !== null);
}

export const fetchDocumentIds = async (collectionName) => {
    try {
        const querySnapshot = await getDocs(collection(firestore, collectionName));
        const documentIds = querySnapshot.docs.map(doc => doc.id);
        return documentIds;
    } catch (error) {
        console.error('Error getting document IDs: ', error);
        return [];
    }
}

export const addDocument = async (document, collectionName, customID) => {
    //Add the document to firebase
    const d = { ...document };
    delete d.id;
    if (!customID) {
        const doc = await addDoc(collection(firestore, collectionName), d);
        return doc.id;
    }
    else {
        const docRef = doc(collection(firestore, collectionName), customID);
        await setDoc(docRef, d);
        return customID;
    }
}

export const updateDocument = async (documentId, collectionName, data) => {
    //Update the document in firebase
    const d = { ...data };
    delete d.id;
    const docRef = doc(firestore, collectionName, documentId);
    await updateDoc(docRef, d);
}

export const deleteDocument = async (documentId, collectionName) => {
    const docRef = doc(firestore, collectionName, documentId);
    await deleteDoc(docRef);
}

export const existsDocument = async (documentId, collectionName) => {
    const docRef = doc(firestore, collectionName, documentId); // Replace 'yourCollection' with your actual collection name
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log('Document exists!', docSnap.data());
        return true;
    } else {
        //console.log('No such document!');
        return false;
    }
}

export const saveNodes = async (nodes) => {
    const nodesCollectionRef = collection(firestore, 'nodes');
    for (const node of nodes) {
        await addDoc(nodesCollectionRef, node);
    }
};

export const loadNodes = async () => {
    const nodesCollectionRef = collection(firestore, 'nodes');
    const nodesSnapshot = await getDocs(nodesCollectionRef);
    const nodes = nodesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return nodes;
};

export const updateNode = async (nodeId, data) => {
    const nodeDocRef = doc(firestore, 'nodes', nodeId);
    await updateDoc(nodeDocRef, data);
};

//NOTE: What is even this????? this is NOT how this service should be used!!! It should be abstract, not case specfic

//scripting methods
// const scriptCollectionRef = collection(firestore, 'scriptData');

// export const createScriptData = async () => {
//     const docRef = await addDoc(scriptCollectionRef, { nodes: [], edges: [] });
//     return docRef.id;
// };

// export const saveScriptData = async (scriptId, data) => {
//     const scriptDocRef = doc(firestore, 'scriptData', scriptId);
//     await setDoc(scriptDocRef, data, { merge: true });
// };

// export const loadScriptData = async (scriptId) => {
//     const scriptDocRef = doc(firestore, 'scriptData', scriptId);
//     const docSnapshot = await getDoc(scriptDocRef);
//     return docSnapshot.exists() ? docSnapshot.data() : { nodes: [], edges: [] };
// };

// export const saveScriptIdToScenario = async (scenarioId, scriptId) => {
//     const scenarioDocRef = doc(firestore, 'scenarios', scenarioId);
//     await updateDoc(scenarioDocRef, { scriptId });
// };

// export const loadScriptIdFromScenario = async (scenarioId) => {
//     const scenarioDocRef = doc(firestore, 'scenarios', scenarioId);
//     const docSnapshot = await getDoc(scenarioDocRef);
//     return docSnapshot.exists() ? docSnapshot.data().scriptId : null;
// };