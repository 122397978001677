import React from 'react';

const NodeParent = ({ title, icon, children, selected, description, metadata }) => {
    return (
        <div className={`bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-white rounded-xl p-4 shadow-lg border-2 ${selected ? 'border-blue-500 ring-4 ring-blue-300 animate-pulse' : 'border-gray-300 dark:border-gray-700'}`}>
            <div className="flex items-center mb-2">
                {icon && <span className="mr-2">{icon}</span>}
                <div className="text-lg font-semibold">{title}</div>
            </div>
            {description && <div className="text-xs text-gray-600 dark:text-gray-400 mb-2">{description}</div>}
            <hr className="border-gray-300 dark:border-gray-700 mb-4" />
            <div>{children}</div>
        </div>
    );
};

export default NodeParent;



// twee dingen aanpassen:
// data structuur opdelen in node rendering, en node data voor tim

// Event chain is een element in de node array
// do is wat de children zijn.doen na een event
// effect en delay is then
// check is yes en no
// event_id / effect_id etc is dus het element in de enums, dus elemt 1 of 2 etc
// id: is de id van de node zelf, pak maar de id die al gemaakt word
// type is de type node, dus check, delay, effect of event. ook deze kan je in de enums vinden.

// elke node map, heeft dan een kopje metadata, en dit is node specifieke data, voor nu moet die alleen aangemaakt worden nog niet gevuld
//


