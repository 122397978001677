import React, { useState } from 'react';
import { HiChevronRight } from 'react-icons/hi';

function AccordionItem({ title, content, isOpenByDefault, onViewAll, itemCount, showViewAll = false }) {
    const [isOpen, setIsOpen] = useState(isOpenByDefault);
    
    

    return (
        <div className="border-b border-gray-200 dark:border-gray-600 rounded-lg shadow-md mb-2">
            <button
                type="button"
                className={`flex justify-between w-full p-6 font-medium text-left ${isOpen ? 'bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-300' : 'bg-white dark:bg-gray-800 text-gray-900 dark:text-white'
                    } hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 rounded-t-lg`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="flex items-center space-x-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`${isOpen ? 'transform rotate-180' : ''} w-6 h-6 text-gray-500 dark:text-gray-400`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>
                    <span>{title}</span>
                </div>
                {showViewAll && itemCount > 3 && (
                    <button
                        className="flex items-center space-x-2 text-blue-600 dark:text-blue-400 hover:underline"
                        onClick={onViewAll}
                    >
                        <span>View All</span>
                        <HiChevronRight className="w-5 h-5" />
                    </button>
                )}
            </button>
            {isOpen && (
                <div className="p-5 bg-white dark:bg-gray-700 rounded-b-lg">
                    <div className="mb-4">
                        {content}
                    </div>
                </div>
            )}
        </div>
    );
}

export default function Accordion({ title, content, isOpenByDefault = false, onViewAll, itemCount, showViewAll = false }) {
    return (
        <AccordionItem
            title={title}
            content={content}
            isOpenByDefault={isOpenByDefault}
            onViewAll={onViewAll}
            itemCount={itemCount}
            showViewAll={showViewAll} // Pas dit aan om de View All knop te tonen of niet
        />
    );
}
