import React, { useState, useEffect } from 'react';
import { useScenario } from '../contexts/ScenarioContext';
import { getFileUrl, uploadFile } from '../../../services/FirebaseService';

const ScenarioPicture = () => {
  const { scenario, setFile } = useScenario();
  const [url, setUrl] = useState("");

  // UseEffect to load the picture if it already exists
  useEffect(() => {
    const loadScenarioPicture = async () => {
      if (scenario?.scenarioPicture) {
        try {
          // Fetch the URL of the existing scenario picture
          const pictureUrl = await getFileUrl(scenario.scenarioPicture);
          setUrl(pictureUrl);
        } catch (error) {
          console.error("Error fetching existing scenario picture:", error);
        }
      }
    };
    loadScenarioPicture();
  }, [scenario?.scenarioPicture]);

  const uploadScenarioPicture = async (file) => {
    if (!scenario?.id) {
      console.error("Scenario ID is missing. Cannot upload file.");
      return;
    }
  
    try {
      const path = `scenarios/${scenario.id}`;
      console.log(`Uploading file ${file.name} to path: ${path}`);
  
      await uploadFile(file, path);
  
      if (file.name) {
        setFile(`${path}/${file.name}`, 'scenarioPicture');
      } else {
        console.error("File name is undefined");
      }
  
      const downloadUrl = await getFileUrl(`${path}/${file.name}`);
      setUrl(downloadUrl);
  
      console.log("File uploaded and URL fetched:", downloadUrl);
    } catch (error) {
      console.error("Error uploading scenario picture:", error);
    }
  };
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File selected in component:", file.name);
      setFile(file, 'scenarioPicture');
      uploadScenarioPicture(file);
    }
  };

  return (
    <div className="container mx-auto my-8 p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Scenario Picture</h2>
      <div className="flex items-center">
        <label className="block">
          <span className="sr-only">Choose scenario picture file</span>
          <input
            id="pictureInput"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-blue-50 file:text-blue-700
                    hover:file:bg-blue-100
                    dark:file:bg-gray-600 dark:file:text-gray-200
                    cursor-pointer"
            style={{ display: 'none' }}
          />
        </label>
        <button
          onClick={() => document.getElementById('pictureInput').click()}
          className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {scenario?.scenarioPicture ? 'Edit Picture' : 'Choose Picture'}
        </button>
        {url && (
          <img src={url} alt="Scenario" className="ml-3 h-20 w-20 object-cover rounded-lg" />
        )}
      </div>
    </div>
  );
};

export default ScenarioPicture;
